import { appConfig } from "config";
import { formatPropertyName } from "helpers";
import L, { ControlPosition } from "leaflet";
import { ComparableBaseType } from "state/proformas/comparables";
import "@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";

const makeCompMapIcon = (status: string, idx: number) => {
  const green = "#70a08e",
    orange = "#F7931E",
    lightgray = "#eeeeee",
    lightblue = "#2196f3";

  let icon_color;

  switch (status) {
    case "highlighted":
      icon_color = lightblue;
      break;
    case "excluded":
      icon_color = lightgray;
      break;
    case "included":
      icon_color = green;
      break;
    case "subject":
      icon_color = orange;
      break;
    case "suggested":
      icon_color = green;
      break;
    default:
      icon_color = lightgray;
  }

  const svgIcon = L.divIcon({
    html: `<svg fill=${icon_color} width="32px" height="32px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke="#000" stroke-width="3"><path d="M25.015 2.4c-7.8 0-14.121 6.204-14.121 13.854 0 7.652 14.121 32.746 14.121 32.746s14.122-25.094 14.122-32.746c0-7.65-6.325-13.854-14.122-13.854z"></path></g><g><path d="M25.015 2.4c-7.8 0-14.121 6.204-14.121 13.854 0 7.652 14.121 32.746 14.121 32.746s14.122-25.094 14.122-32.746c0-7.65-6.325-13.854-14.122-13.854z"></path></g></svg>
          <span class=${status}>${
            status === "subject" ? "•" : String.fromCharCode("A".charCodeAt(0) + idx)
          }</span>`,
    className: "comp-icon",
    iconAnchor: [14, 30],
  });
  return svgIcon;
};

export class LeafletCompMapService {
  comps: ComparableBaseType[];
  subjectProp: any;
  map: L.Map | null = null;
  markers: L.Marker[] = [];
  options: {
    satellite?: boolean;
    hideLayerControls?: boolean;
    zoomControl?: boolean;
    zoomPosition?: ControlPosition;
    zoom?: number;
    maxZoom?: number;
    scrollWheelZoom?: boolean;
    attributionControl?: boolean;
  };

  constructor(
    comparables: ComparableBaseType[],
    subjectProp: any,
    options?: Partial<{
      satellite: boolean;
      hideLayerControls: boolean;
      zoomControl: boolean;
      zoomPosition: ControlPosition;
      zoom: number;
      maxZoom: number;
      scrollWheelZoom: boolean;
      attributionControl: boolean;
    }>,
  ) {
    this.comps = comparables;
    this.subjectProp = subjectProp;
    this.options = {
      satellite: options?.satellite ?? false,
      hideLayerControls: options?.hideLayerControls ?? true,
      zoomControl: options?.zoomControl ?? false,
      zoomPosition: options?.zoomPosition ?? "bottomright",
      zoom: options?.zoom ?? 12,
      maxZoom: options?.maxZoom ?? 18,
      scrollWheelZoom: options?.scrollWheelZoom ?? false,
      attributionControl: options?.attributionControl ?? false,
    };
  }

  createMap(domRef: HTMLDivElement) {
    let subjectPos = new L.LatLng(this.subjectProp.parcel.latitude, this.subjectProp.parcel.longitude);

    const streetMap = L.maplibreGL({
      style: `https://api.maptiler.com/maps/2eadbf66-831a-4e49-9604-48d6515c3a10/style.json?key=${appConfig.mapTilerApiKey}`,
    });

    const satelliteMap = L.tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
      maxZoom: 20,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    });

    this.map = L.map(domRef, {
      zoom: this.options.zoom,
      maxZoom: this.options.maxZoom,
      attributionControl: this.options.attributionControl,
      zoomControl: this.options.zoomControl,
      scrollWheelZoom: this.options.scrollWheelZoom,
      layers: [this.options.satellite ? satelliteMap : streetMap],
      center: subjectPos,
    });

    const baseMaps = {
      Street: streetMap,
      Satellite: satelliteMap,
    };

    const overlays = {
      //add any overlays here
    };

    if (!this.options.hideLayerControls) {
      L.control.layers(baseMaps, overlays, { position: "bottomleft" }).addTo(this.map);
    }

    L.control.zoom({ position: this.options.zoomPosition }).addTo(this.map);

    const subjectMarkerOptions = {
      title: formatPropertyName(this.subjectProp.parcel.street_address),
      icon: makeCompMapIcon("subject", 0),
      riseOnHover: true,
      zIndexOffset: 1100,
    };

    if (this.subjectProp.parcel.most_recent_listing?.dont_show_map_link) return;

    const bounds = new L.LatLngBounds([]);

    this.makeMarkers(this.comps, bounds);

    const subjectMarker = new L.Marker(subjectPos, subjectMarkerOptions);
    this.markers.push(subjectMarker);
    subjectMarker.addTo(this.map);
    bounds.extend(subjectPos);

    this.map.fitBounds(bounds.pad(0.08));
  }

  makeMarker(comp: ComparableBaseType, bounds: L.LatLngBounds, idx: number) {
    const position = new L.LatLng(comp.parcel.latitude, comp.parcel.longitude);
    bounds.extend(position);

    const status = comp.status;
    const markerOptions = {
      title: formatPropertyName(comp.parcel.street_address),
      idx: comp._idx,
      parcel_id: comp.parcel._id,
      status: status,
      icon: makeCompMapIcon(status, comp._idx),
      riseOnHover: true,
      zIndexOffset: 1000,
    };
    const marker = new L.Marker(position, markerOptions);
    return marker;
  }

  makeMarkers(comparables: ComparableBaseType[], bounds: L.LatLngBounds) {
    comparables.forEach((comp, idx) => {
      const marker = this.makeMarker(comp, bounds, idx);
      marker.addTo(this.map!);
      this.markers.push(marker);
    });
  }

  highlightHandler(idx: number, highlighted: boolean) {
    this.markers[idx].setIcon(makeCompMapIcon(highlighted ? "highlighted" : this.comps[idx].status, idx));
    this.markers.forEach((marker, index) => {
      marker.setZIndexOffset(idx === index ? 2000 : 1000 + index);
    });
  }
}
