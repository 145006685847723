import { ChevronLeftIcon, ChevronRightIcon, XIcon } from "@heroicons/react/solid";
import { ListedBy, ModalWrapper, ShowMore } from "components";
import { ParcelHistory } from "components/proformas";
import { AdjustedValues, LeafletCompMapService } from "components/proformas/comparables/";
import { formatDateShort, hasValue } from "helpers";
import { Fragment, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";
import { Link, useLocation } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { useUserSession } from "state";
import { CustomStructureType, useCustomStructure, useParcelHistory, useProforma } from "state/proformas";
import { CompStatsType, SalesComparableType } from "state/proformas/comparables";

export const compModalShowIdxAtom = atom<number | null>({
  key: "compModalShowIdxAtom",
  default: null,
});

type Props = {
  isOpen: boolean;
  comparables: SalesComparableType[];
  compStats: CompStatsType[];
  totalScore: number;
  toggleComparableStatus: CallableFunction;
};

export const CompModal = ({ isOpen, comparables, compStats, totalScore, toggleComparableStatus }: Props) => {
  const location = useLocation();
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<number | null>(null);

  const handleScroll = () => {
    setIsScrolling(true);
    if (scrollTimeoutRef.current !== null) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, 300);
  };

  const slideLeft = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft -= 480;
  };
  const slideRight = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft += 480;
  };

  const { proforma } = useProforma();
  const isMissingThumbnail =
    !proforma.thumbnail_url ||
    proforma.thumbnail_url === "https://cdn.davinci.pellego.com/static/images/core/missing.png";

  const [compModalShowIdx, setCompModalShowIdx] = useRecoilState(compModalShowIdxAtom);
  const compIdx = compModalShowIdx || 0;

  useEffect(() => {
    if (isOpen) {
      window.location.hash = "comp_" + String.fromCharCode("a".charCodeAt(0) + compIdx);
    }
    return () => {
      window.location.hash = "";
    };
  }, [compIdx, isOpen]);

  const comparable = comparables[comparables.findIndex((comp) => comp._idx === compIdx)];
  const parcelHistory = useParcelHistory(comparable.parcel._id);
  const { customStructure } = useCustomStructure(proforma);
  const currentUser = useUserSession();
  const isAgent = currentUser.isAgent;
  const isClient = currentUser.isClient;

  var listingEvent = comparable.parcel._characteristics.listing_status;
  var price = comparable.parcel._characteristics.last_price;
  if (comparable.parcel._characteristics.listing_status === "Active") {
    listingEvent = "Listed";
  }
  if (
    comparable.parcel &&
    comparable.parcel.most_recent_listing &&
    ["Austin Board of Realtors"].includes(comparable.parcel.most_recent_listing?.listing_source) &&
    currentUser.hasAdvancedRole === false
  ) {
    listingEvent = "Last Price";
    price = comparable.parcel._characteristics.last_list_price;
  }
  const showFlipPhotos = comparable.parcel.flip_listing?.photos?.length > 0;
  const oldPhotos = comparable.parcel.flip_listing?.photos;
  const actualPhotos = comparable.parcel.most_recent_listing?.photos;

  const photosLength = actualPhotos?.length;
  const photosCount = comparable.photo_count || 0;

  const showPhotosCount = !(isAgent || isClient) && photosCount > photosLength;

  const [photos, setPhotos] = useState(actualPhotos);
  const closeModal = () => setCompModalShowIdx(null);

  const ref = useRef<HTMLDivElement>(null);
  const showCompAddress = !(comparable.parcel.most_recent_listing?.dont_show_address === true);

  useEffect(() => {
    let compMapService: LeafletCompMapService | null = null;
    if (ref.current) {
      compMapService = new LeafletCompMapService([comparable], proforma);
      compMapService.createMap(ref.current);
    }
    return () => {
      if (compMapService) {
        compMapService.map?.off();
        compMapService.map?.remove();
      }
    };
  }, [comparable, proforma]);

  useEffect(() => {
    setPhotos(actualPhotos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compIdx]);

  return (
    <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block size-full max-w-5xl transform rounded-md bg-white px-0 text-left align-middle transition-all sm:mt-16">
        <div className="flex size-full justify-center">
          <div className="size-full max-w-5xl rounded-md bg-gray-50 shadow-lg">
            <div className="sticky top-0 z-[10000] flex w-full flex-row items-center justify-items-end border-b border-gray-200 bg-white p-2 px-4 align-top font-medium sm:p-4 sm:align-middle">
              {isMobile && (
                <div className="absolute left-3">
                  <input
                    type="checkbox"
                    className="size-5 border outline-0 indeterminate:bg-gray-300 focus:ring-0"
                    onChange={() => toggleComparableStatus(comparable._idx)}
                    checked={comparable.is_included}
                  />
                </div>
              )}
              {/* Navigation */}
              <div className="flex w-full flex-row items-center justify-center gap-6 sm:gap-20">
                <button
                  type="button"
                  className="size-8 cursor-pointer text-st-lightest"
                  onClick={() => setCompModalShowIdx(compIdx > 0 ? compIdx - 1 : comparables.length - 1)}
                >
                  <ChevronLeftIcon />
                </button>
                <div>Comp {String.fromCharCode("A".charCodeAt(0) + comparable._idx)}</div>
                <button
                  type="button"
                  className="size-8 cursor-pointer text-st-lightest"
                  onClick={() => setCompModalShowIdx((compIdx + 1) % comparables.length)}
                >
                  <ChevronRightIcon />
                </button>
              </div>
              {/* Close button */}
              <div className="absolute right-3 mt-2">
                <button
                  type="button"
                  className="rounded-md text-sm font-medium text-st-lightest hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                  onClick={closeModal}
                >
                  <XIcon className="w-7" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mb-0 flex h-full flex-col">
              <div className="flex w-full flex-col px-0 pb-4 sm:flex-row">
                <div className="group relative w-full overflow-auto" onScrollCapture={handleScroll}>
                  {!isScrolling && photos?.length > 1 && (
                    <>
                      {scrollContainerRef.current && scrollContainerRef.current.scrollLeft > 0 && (
                        <ChevronLeftIcon
                          className="invisible absolute left-8 top-[calc(50%-1.25rem)] z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60 group-hover:visible"
                          onClick={slideLeft}
                        />
                      )}
                      {scrollContainerRef.current &&
                        scrollContainerRef.current.scrollLeft <=
                          scrollContainerRef.current.scrollWidth -
                            scrollContainerRef.current.clientWidth -
                            1 && (
                          <ChevronRightIcon
                            className="invisible absolute right-8 top-[calc(50%-1.25rem)] z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60 group-hover:visible"
                            onClick={slideRight}
                          />
                        )}
                    </>
                  )}
                  {comparable.is_flip && !showFlipPhotos && (
                    <div className="absolute left-2 top-2 z-10 size-fit whitespace-nowrap rounded-md bg-black bg-opacity-50 p-1 px-2 text-center text-sm text-white">
                      This is a Flip
                    </div>
                  )}
                  <div
                    ref={scrollContainerRef}
                    className="relative flex w-full snap-mandatory gap-4 overflow-x-auto scroll-smooth"
                  >
                    {photos.map((photo: any, idx: any) => (
                      <Fragment key={photo._id}>
                        <div className="shrink-0 snap-center snap-normal">
                          <img
                            className="w-100 sm:w-160 rounded-xs h-60 shrink-0 bg-white object-cover shadow-xl sm:h-96"
                            src={photo.photo_url}
                            alt="Property Img"
                          />
                        </div>
                        {idx === photosLength - 1 && showPhotosCount && (
                          <div className="relative shrink-0 snap-start snap-normal px-2">
                            <img
                              className="w-100 sm:w-160 rounded-xs h-60 shrink-0 bg-white object-cover shadow-xl sm:h-96"
                              src={photo.photo_url}
                              alt={`${photosCount - photosLength} photos are restricted from public view due to state or MLS regulations. Login with your agent account or client account.`}
                            />
                            {/* Photo Count Info */}
                            <div className="w-100 sm:w-160 rounded-xs absolute inset-0 z-10 mx-2 flex h-60 shrink-0 place-items-center bg-black bg-opacity-85 shadow-xl sm:h-96">
                              <div className="mx-auto mt-[1px] flex flex-col rounded-sm bg-white p-5 py-6 text-center text-[12px]">
                                <div>
                                  <span className="font-bold">{photosCount - photosLength} photos</span> are
                                  restricted
                                </div>
                                <div>from public view due to</div>
                                <div>state or MLS regulations</div>
                                <div>&nbsp;</div>
                                <div>
                                  <Link to="/login" state={{ from: location }} className="text-sl-normal">
                                    Login
                                  </Link>
                                  <span> with your agent</span>
                                </div>
                                <div>account or client account</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <ListedBy propertyParcel={comparable.parcel} />

              {/* This is a Flip with photos */}
              {comparable.is_flip && showFlipPhotos && (
                <div className="flex w-full flex-col items-center px-4 pb-3 sm:flex-row sm:justify-end">
                  <>
                    <div className="w-fit basis-full text-end text-lg font-medium sm:basis-1/2 sm:px-4">
                      This is a Flip
                    </div>
                    <div className="flex flex-col sm:flex-row">
                      <button
                        type="button"
                        className={
                          photos === oldPhotos
                            ? "w-60 rounded-l-sm border border-[#38455b] bg-[#38455b] px-4 py-2.5 text-sm font-medium uppercase leading-5 text-white sm:border-r-0"
                            : "w-60 rounded-l-sm border border-[#38455b] px-4 py-2.5 text-sm font-medium uppercase leading-5 text-st-light focus:ring-white sm:border-r-0"
                        }
                        onClick={() => {
                          setPhotos(oldPhotos);
                        }}
                      >
                        View photos before flip
                      </button>
                      <button
                        type="button"
                        className={
                          photos === actualPhotos
                            ? "w-60 rounded-r-sm border border-[#38455b] bg-[#38455b] px-4 py-2.5 text-sm font-medium uppercase leading-5 text-white sm:border-l-0"
                            : "w-60 rounded-r-sm border border-[#38455b] px-4 py-2.5 text-sm font-medium uppercase leading-5 text-st-light focus:ring-white sm:border-l-0"
                        }
                        onClick={() => {
                          setPhotos(actualPhotos);
                        }}
                      >
                        View photos after flip
                      </button>
                    </div>
                  </>
                </div>
              )}

              {/* Property Address for Desktop view */}
              <div
                className={`flex w-full flex-col px-4 ${comparable.is_flip && !showFlipPhotos ? "pt-3" : ""}`}
              >
                <div className="flex w-full flex-row font-medium sm:px-4">
                  {showCompAddress && (
                    <div className="whitespace-nowrap text-lg">
                      {comparable.parcel._characteristics.address}
                    </div>
                  )}
                  <span className="pl-2 text-lg font-light text-st-normal">
                    (<NumberFormat value={comparable.distance} displayType="text" decimalScale={2} /> miles)
                  </span>
                </div>
              </div>

              <div className="flex w-full flex-col px-4 pb-4 sm:flex-row">
                <div className="basis-full text-lg font-medium sm:basis-1/2 sm:px-4">
                  {!isMobile && (
                    <div className="mb-4 font-light leading-3">
                      <input
                        type="checkbox"
                        className="border outline-0 indeterminate:bg-gray-300 focus:ring-0"
                        onChange={() => toggleComparableStatus(comparable._idx)}
                        checked={comparable.is_included}
                      />
                      <span className="px-2 text-sm font-light text-st-normal">
                        Include Comp {String.fromCharCode("A".charCodeAt(0) + comparable._idx)} in ARV
                        Analysis
                      </span>
                    </div>
                  )}
                  {isMissingThumbnail ? null : (
                    <>
                      <div className="flex gap-4 pb-2">
                        <div className="basis-1/2">
                          <div className="flex items-center pt-4 text-xs font-bold uppercase text-green-900">
                            Location
                          </div>
                        </div>
                        <div className="basis-1/2">
                          <div className="flex items-center pt-4 text-xs font-bold uppercase text-green-900">
                            Subject Property
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 flex gap-4">
                        <div className="w-full basis-1/2 rounded-lg border">
                          <div ref={ref} className="relative flex h-36 w-full items-end rounded-md" />
                        </div>
                        <div className="basis-1/2 rounded-lg border">
                          <div className="shrink-0 snap-center snap-normal">
                            <div
                              className="bg-[background-size: 100% 100%;] relative flex h-36 w-full items-end rounded-md bg-cover bg-center bg-no-repeat"
                              style={{ backgroundImage: `url('${proforma.thumbnail_url}')` }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {!isMobile && comparable.parcel._characteristics?.listing_remarks && (
                    <>
                      <div className="flex items-center pt-4 text-xs font-bold uppercase text-green-900">
                        Listing remarks
                      </div>
                      <ShowMore clampLines={4} className="mt-2 text-left text-sm font-light">
                        {comparable.parcel._characteristics.listing_remarks}
                      </ShowMore>
                    </>
                  )}

                  {!isMobile &&
                    !parcelHistory.loading &&
                    !parcelHistory.error &&
                    parcelHistory.data &&
                    parcelHistory.data.length > 0 && (
                      <div className="pt-4">
                        <ParcelHistory history={parcelHistory.data} sinceListed disablePhotosModal />
                      </div>
                    )}
                </div>
                <div className="justify-right basis-full sm:basis-1/2 sm:px-4 sm:pt-8">
                  <div className="flex w-full flex-col pt-4 text-xs leading-6">
                    {/* price */}
                    <div className="flex flex-row justify-items-stretch">
                      {comparable.is_flip && comparable.parcel.flip_listing && (
                        <div className="w-full text-left text-sm font-medium leading-4 text-st-lightest">
                          <NumberFormat
                            value={comparable.parcel.flip_listing.sale_price}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="$ "
                            decimalScale={0}
                          />
                        </div>
                      )}
                      <div className="w-full text-right text-sm font-medium leading-4">
                        <NumberFormat
                          value={price}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$ "
                          decimalScale={0}
                        />
                      </div>
                    </div>
                    {/* status */}
                    <div className="flex w-full text-xs font-normal leading-4 text-st-lightest">
                      {comparable.is_flip && comparable.parcel.flip_listing && (
                        <div className="flex w-full basis-1/2 leading-4">
                          <span className="hidden sm:inline">Bought&nbsp;</span>
                          {formatDateShort(comparable.parcel.flip_listing.sale_date, false)}
                        </div>
                      )}
                      <span className="flex w-full justify-end whitespace-normal text-right leading-4">
                        {listingEvent}&nbsp;
                        {formatDateShort(comparable.parcel._characteristics.last_date, false)}
                      </span>
                    </div>

                    {/* Appreciation */}
                    <div className="mt-2 text-right text-xs font-medium text-st-normal">Appreciation</div>
                    <div className="flex flex-row justify-items-stretch">
                      <div className="w-full text-right text-xs font-medium leading-7">
                        {comparable.cost_diffs.market_change ? (
                          <NumberFormat
                            value={comparable.cost_diffs.market_change}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="$ "
                            decimalScale={0}
                          />
                        ) : (
                          <>-</>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full flex-row text-xs leading-6">
                    <div className="mt-2 basis-2/5 text-left text-xs font-bold text-st-normal">
                      Characteristics
                    </div>
                    <div className="mt-2 basis-1/5 pr-2 text-left text-xs font-bold text-st-normal">
                      Subject
                    </div>
                    <div className="mt-2 basis-1/5 pr-2 text-left text-xs font-bold text-st-normal">Comp</div>
                    <div className="mt-2 basis-1/5 text-right text-xs font-bold text-st-normal">
                      Adjustments
                    </div>
                  </div>

                  <div className="flex w-full flex-row text-xs leading-6">
                    <div className="flex basis-2/5 flex-col pt-1 text-xs leading-6">
                      {compStats
                        .filter((stat) => stat.key !== "view_type")
                        .map((stat) => (
                          <div
                            key={stat.key}
                            className="border-b border-gray-200 py-1 text-left text-xs font-bold text-st-normal"
                          >
                            {stat.name}
                          </div>
                        ))}
                    </div>

                    <div className="flex basis-1/5 flex-col pt-1 text-xs leading-6">
                      {compStats
                        .filter((stat) => stat.key !== "view_type")
                        .map((stat) => (
                          <div
                            key={stat.key}
                            className="whitespace-nowrap border-b border-gray-200 py-1 pr-2 text-left text-xs text-st-normal"
                          >
                            {stat.subjectValue}

                            {customStructure[stat.key as keyof CustomStructureType] && (
                              <>
                                {stat.key === "view_type" ? (
                                  <>
                                    {customStructure.view_type && customStructure.view_type?.length > 0 && (
                                      <> → {customStructure.view_type?.join(", ")}</>
                                    )}
                                  </>
                                ) : (
                                  <> → {customStructure[stat.key as keyof CustomStructureType]}</>
                                )}
                              </>
                            )}
                          </div>
                        ))}
                    </div>

                    <div className="flex basis-1/5 flex-col pt-1 text-xs leading-6">
                      {compStats
                        .filter((stat) => stat.key !== "view_type")
                        .map((stat) => (
                          <div
                            key={stat.key}
                            className={`w-full overflow-hidden text-ellipsis whitespace-nowrap border-b border-gray-200 ${
                              !hasValue(comparable.parcel?._characteristics[stat.key])
                                ? "whitespace-nowrap border-b border-gray-200 text-[0.6rem] italic text-st-soft"
                                : ""
                            }`}
                          >
                            {hasValue(comparable.parcel._characteristics[stat.key]) ? (
                              <>
                                {stat.key === "floors" ? (
                                  <>
                                    {stat.subjectValue &&
                                      (stat.subjectValue > comparable.parcel?._characteristics[stat.key]
                                        ? "+ More"
                                        : stat.subjectValue < comparable.parcel?._characteristics[stat.key]
                                          ? "- Fewer"
                                          : "Same")}
                                  </>
                                ) : (
                                  <>
                                    {stat.format === "currency" && (
                                      <NumberFormat
                                        value={comparable.parcel?._characteristics[stat.key]}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix="$ "
                                        decimalScale={0}
                                      />
                                    )}
                                    {stat.format === "number" && (
                                      <NumberFormat
                                        value={comparable.parcel?._characteristics[stat.key]}
                                        displayType="text"
                                        thousandSeparator={true}
                                        decimalScale={
                                          comparable.parcel?._characteristics[stat.key] % 1 !== 0 ? 2 : 0
                                        }
                                      />
                                    )}
                                    {stat.format !== "number" && stat.format !== "currency" && (
                                      <>{comparable.parcel?._characteristics[stat.key]}</>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>{stat.fallback ? stat.fallback : <span>&nbsp;</span>}</>
                            )}
                          </div>
                        ))}
                    </div>

                    <div className="flex basis-1/5 flex-col pt-1 text-right text-xs leading-6 text-st-normal">
                      {compStats
                        .filter((stat) => stat.key !== "view_type")
                        .map((stat) => (
                          <div
                            key={stat.key}
                            className="whitespace-nowrap border-b border-gray-200 text-right"
                          >
                            {comparable.cost_diffs[stat.compsKey ? stat.compsKey : stat.key] ? (
                              <NumberFormat
                                className="font-bold"
                                value={comparable.cost_diffs[stat.compsKey ? stat.compsKey : stat.key]}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$ "
                                decimalScale={0}
                              />
                            ) : (
                              <>-</>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>

                  <AdjustedValues
                    costDiffs={comparable.cost_diffs}
                    compChars={comparable.parcel._characteristics}
                    subjectChars={proforma.parcel._characteristics}
                  />

                  <div className="flex flex-col pt-3 text-xs leading-6">
                    <div className="mt-2 text-right text-xs font-medium text-st-normal">Adjusted Value</div>
                    <div className="flex flex-row justify-items-stretch">
                      <div className="w-full text-right text-sm font-medium leading-4">
                        <NumberFormat
                          className="font-bold"
                          value={comparable.projected_arv}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$ "
                          decimalScale={0}
                        />
                      </div>
                    </div>
                  </div>
                  {isMobile &&
                    !parcelHistory.loading &&
                    !parcelHistory.error &&
                    parcelHistory.data &&
                    parcelHistory.data.length > 0 && (
                      <div className="pt-4">
                        <ParcelHistory history={parcelHistory.data} sinceListed disablePhotosModal />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
