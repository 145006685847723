import {
  Footer,
  ListedBy,
  LoadingLogo,
  Nav,
  NotificationBar,
  NWMLSNotice,
  SignInSignUpDialog,
} from "components";
import { AskAboutNotice } from "components/browse";
import {
  AskAbout,
  AskAboutWithAgent,
  Comparables,
  Financials,
  Markets,
  Photos,
  PropertyData,
  PropertyDetails,
  PropertyNotFound,
  PropertyRemarks,
  Zoning,
} from "components/proformas";
import { CompSkeleton } from "components/proformas/comparables/";
import { Header, HeaderSticky } from "components/proformas/header/";
import { OREGON_STATE_ID } from "config";
import {
  buildProformasAddressUrl,
  buildProformasListingNumberUrl,
  cn,
  formatPropertyName,
  titleCase,
} from "helpers";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useCurrentStates,
  useRecentSearches,
  useSignInSignUpModal,
  useSiteConfig,
  useUserFromShareToken,
  useUserPropertiesData,
  useUserSession,
} from "state";
import {
  ParcelAgentType,
  useParcelAgent,
  useProforma,
  useProformasByData,
  useUpdateDate,
} from "state/proformas";

type Props = {
  proforma_type: "flip_rental" | "new_construction";
};

export const Proformas = ({ proforma_type }: Props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const currentUser = useUserSession();
  const { openNonMLSSignUpModal } = useSignInSignUpModal();
  const navigate = useNavigate();
  const { userPropertiesData, saveUserPropertiesData } = useUserPropertiesData();

  let address = params.address?.replace(/ /g, "-");
  let listingNumber = params.listing_number;

  const data = address ? address : listingNumber;
  const dataType = address ? "address" : "listingNumber";

  const { isBasicLoading, isRestLoading, isBasicError, isRestError } = useProformasByData(
    data,
    proforma_type,
    dataType,
    searchParams.get("share"),
  );
  const { proforma } = useProforma();

  useEffect(() => {
    if (proforma?.parcel_id) {
      const viewedProperties = {
        ...userPropertiesData?.viewed_properties,
        [proforma.parcel_id]: true,
      };

      saveUserPropertiesData({
        ...userPropertiesData,
        viewed_properties: viewedProperties,
      });
    }
  }, [proforma?.parcel_id, saveUserPropertiesData, userPropertiesData]);

  const dontShowAddress = proforma?.parcel?.most_recent_listing?.dont_show_address || false;

  if (!isBasicLoading && address && dontShowAddress) {
    listingNumber = proforma.listing_number || undefined;
    navigate(buildProformasListingNumberUrl(listingNumber), {
      replace: true,
    });
  }
  if (!isRestLoading && listingNumber && !dontShowAddress) {
    address = proforma.parcel?.full_address || undefined;
    navigate(buildProformasAddressUrl(address), { replace: true });
  }

  useRecentSearches({ loading: isBasicLoading, error: isBasicError });

  const { sharingUser, sharedByAgent } = useUserFromShareToken(proforma);
  let parcelAgent = useParcelAgent(proforma.parcel_id);
  if (currentUser.isAgent) parcelAgent = currentUser.session as ParcelAgentType;

  const hasMLS = !(proforma.has_mls === false);
  const isNWMLS = proforma.listing?.listing_source === "NWMLS";
  const updateDate = useUpdateDate(proforma?.parcel?.state_id);

  const [showSmallHeader, setShowSmallHeader] = useState(false);

  const siteConfig = useSiteConfig();
  const { currentStates } = useCurrentStates();
  const isPartnerState = currentStates?.some((state) => siteConfig.partner_states?.includes(state));
  const isBrokerageState = currentStates?.some((state) => siteConfig.brokerage_states?.includes(state));

  useEffect(() => {
    if (!isBasicLoading || !isRestLoading) window.Pellego.proforma = proforma;
  }, [isBasicLoading, isRestLoading, proforma]);

  const handleScroll = (e: any) => {
    const scrollTop = e.target.scrollTop;
    if (scrollTop < (hasMLS ? 90 : 138) && showSmallHeader) {
      setShowSmallHeader(false);
    }
    if (scrollTop > (hasMLS ? 90 : 138) && !showSmallHeader) {
      setShowSmallHeader(true);
    }
  };

  if (address && (isBasicLoading || !proforma.parcel_id) && !isBasicError) {
    return <LoadingLogo />;
  }
  if (listingNumber && (isRestLoading || !proforma.parcel_id) && !isRestError) {
    return <LoadingLogo />;
  }

  return (
    <div className="h-screen w-full font-sans">
      <div id="main-wrapper" className="fixed bottom-0 left-0 right-0 top-0 w-full">
        <div className="relative flex h-full flex-wrap">
          {(address && isBasicError) || (listingNumber && isRestError) ? (
            <PropertyNotFound />
          ) : (
            <div
              id="main-content"
              className="relative h-full flex-1 overflow-y-scroll"
              onScroll={handleScroll}
            >
              <div className="flex w-full flex-col items-center">
                <Helmet>
                  {dontShowAddress ? (
                    <title>List. #{listingNumber}</title>
                  ) : (
                    <title>
                      {formatPropertyName(titleCase(proforma.full_address || proforma.parcel.full_address))}
                    </title>
                  )}
                </Helmet>
                <div id="Top"></div>
                <Nav notFixed={true} />
                {!hasMLS && (
                  <NotificationBar type="warning" sticky={showSmallHeader}>
                    <div className="flex flex-col sm:flex-row sm:gap-2">
                      We don't have MLS data for this region yet.
                      <div>
                        <span
                          className="cursor-pointer text-sl-normal underline"
                          onClick={openNonMLSSignUpModal}
                        >
                          Check here
                        </span>{" "}
                        to be updated when we do.
                      </div>
                    </div>
                  </NotificationBar>
                )}
                {!isMobile && showSmallHeader ? <HeaderSticky /> : <Header />}
                <div id="Remarks"></div>
                <Photos />
                <ListedBy
                  propertyParcel={proforma.parcel}
                  className={cn("bg-[#f7faff] pt-1", isMobile ? "pb-1" : "pb-4")}
                />
                <div
                  className="align-center flex w-full flex-col items-center justify-center bg-[#f7faff] pb-10 pt-4 sm:pb-[5.5rem]"
                  id="PropertyData"
                >
                  <div className="flex w-full max-w-7xl flex-row items-start align-top xl:gap-10">
                    <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-3 xl:gap-10">
                      <div className="cols-span-3 grid grid-cols-1 gap-4 sm:col-span-3 sm:grid-cols-2 md:col-span-2 md:grid-cols-1 lg:grid-cols-2 xl:gap-10">
                        <div className={cn(isMobile ? "order-1" : "order-2 w-full md:order-2 lg:order-1")}>
                          <PropertyData />
                        </div>
                        <div className={cn(isMobile ? "order-2" : "order-1 w-full md:order-1 lg:order-2")}>
                          <PropertyRemarks withPrivateRemarks={isPartnerState || isBrokerageState} />
                          <PropertyDetails isNWMLS={isNWMLS} />
                          <Zoning />
                        </div>
                      </div>
                      <div className="hidden md:block">
                        <div className="sticky top-20 justify-center pr-4 xl:px-0">
                          {parcelAgent ? (
                            <AskAboutWithAgent
                              title="Ask about this property"
                              agent={sharedByAgent ? sharingUser : parcelAgent}
                              currentUserIsAgent={currentUser.isAgent}
                            />
                          ) : (
                            <AskAbout title="Ask about this property" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {proforma.isDataComplete ? (
                  <>
                    <Comparables />
                    <Financials />
                    <Markets agent={sharedByAgent ? sharingUser : parcelAgent} />
                  </>
                ) : (
                  !isRestError && <CompSkeleton />
                )}

                <div
                  className={cn(
                    "pb-10 text-sm leading-7 sm:pb-[5.5rem]",
                    !proforma.isDataComplete && "pt-10",
                    isNWMLS && "pb-4",
                  )}
                >
                  Content last updated on {updateDate}.
                </div>

                {isNWMLS && <NWMLSNotice />}

                {proforma.parcel.state_id === OREGON_STATE_ID && (
                  <img src={`https://cdn.davinci.pellego.com/static/images/rmls_logo.jpg`} alt="" />
                )}
              </div>
              <Footer stateId={proforma.parcel.state_id} />
            </div>
          )}
          <SignInSignUpDialog />
        </div>
        <div className="fixed bottom-0 z-20 w-full md:hidden">
          <AskAboutNotice agent={sharedByAgent ? sharingUser : parcelAgent} />
        </div>
      </div>
    </div>
  );
};
