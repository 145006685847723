import { highlightHandlerAtom, PropertyPhotos, Restricted } from "components/browse";
import { isPellego } from "config";
import { buildProformasAddressUrl, buildProformasListingNumberUrl } from "helpers";
import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";
import { usePopperTooltip } from "react-popper-tooltip";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useUserSession } from "state";
import { SearchResultType } from "state/browse";

type Props = {
  property: SearchResultType;
  showCosts: boolean;
  selected?: boolean;
};

export const PropertyDesktop = ({ property, showCosts, selected = false }: Props) => {
  const selectedPropertyRef = useRef<HTMLDivElement>(null);
  const highlightHandler = useRecoilValue(highlightHandlerAtom);
  const showAddress = !(property.dont_show_address === true);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible: toolTipVisible,
  } = usePopperTooltip({ placement: "auto" });
  const currentUser = useUserSession();
  const notClientNorAgent = !currentUser.isClient && !currentUser.isAgent;

  const onMouseHover = (propertyId: number | null) => {
    // Highlight property on Map
    if (highlightHandler) {
      highlightHandler(propertyId);
    }
  };

  useEffect(() => {
    if (selectedPropertyRef.current && selected) {
      selectedPropertyRef.current.scrollIntoView({
        behavior: isMobile ? "auto" : "smooth", // Instant scroll on mobile
        block: "center",
      });
    }
  }, [selected]);

  // buildProformasListingNumberUrl;

  return (
    <Link
      to={
        showAddress
          ? buildProformasAddressUrl(property.fullAddress)
          : buildProformasListingNumberUrl(property.listingNumber)
      }
      target="_blank"
    >
      <div
        ref={selectedPropertyRef}
        className={`${
          isMobile ? "min-w-full" : "h-76 w-80 shrink-0"
        } flex flex-col whitespace-nowrap rounded-lg border bg-gray-50 ${
          selected ? "border-2 border-blue-500" : "hover:border-blue-500"
        }`}
        onMouseEnter={() => onMouseHover(property.parcel_id)}
        onMouseLeave={() => onMouseHover(null)}
      >
        <div className="relative aspect-video w-full flex-shrink-0">
          <PropertyPhotos property={property} />
        </div>

        <div className="w-full bg-white p-3 text-st-normal">
          <div className="text-md flex items-end overflow-hidden pb-1 font-bold leading-none">
            <img
              src={`https://cdn.davinci.pellego.com/static/images/black/${property.structureType}.svg`}
              className="mr-2 h-4"
              alt={property.structureType}
            />

            {property.location}
          </div>

          <div className="text-xs">
            {property.beds}bd&nbsp;
            {property.baths}ba&nbsp;
            <NumberFormat value={property.sqft} displayType="text" thousandSeparator={true} />
            sqft&nbsp;
            {property.lot_square_feet > 0 && (
              <>
                <NumberFormat
                  value={property.lot_square_feet}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={2}
                />
                lot
              </>
            )}
            {" · "}
            {property.equity === null
              ? "Unknown"
              : Math.round(
                  (property.equity /
                    (property.pellegoArv < property.listingPrice
                      ? property.pellegoArv
                      : property.listingPrice)) *
                    100,
                ) + "%"}{" "}
            equity
          </div>
        </div>

        <div className="rounded-b-lg bg-white text-center text-[10px]">
          <div ref={setTriggerRef}>
            {toolTipVisible && property.listing_source === "RMLS" && (
              <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
                <div {...getArrowProps({ className: "tooltip-arrow" })}></div>
                ARV, Rent, and profit metrics are estimated by {isPellego ? "Pellego" : "Lotside"}'s
                Algorithms.
              </div>
            )}
            <div className="flex flex-row gap-1 pb-3 text-left">
              <div className="flex w-full flex-col px-2 text-sm font-light xl:px-3">
                <div className="flex flex-row whitespace-nowrap">
                  <div className="">ARV</div>
                  <div className="w-full text-right">
                    {property.no_avm_display && notClientNorAgent ? (
                      <Restricted />
                    ) : (
                      <NumberFormat
                        value={property.pellegoArv}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$ "
                        decimalScale={0}
                      />
                    )}
                  </div>
                </div>

                <div className="flex flex-row whitespace-nowrap">
                  <div className="">Price</div>
                  <div className="w-full text-right">
                    <NumberFormat
                      value={property.listingPrice}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-0.5 max-w-0.5 flex-col border-l"></div>
              <div className="flex w-full flex-col px-2 text-sm font-light xl:px-3">
                <div className="flex flex-row whitespace-nowrap">
                  <div className="">Rent</div>
                  <div className="w-full text-right">
                    <NumberFormat
                      value={property.monthly_rent_hold}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                    /mo
                  </div>
                </div>

                <div className="flex flex-row whitespace-nowrap">
                  {/* <div className="">{property.hoa_dues > 0 ? "PITIH" : "PITI"}</div> */}
                  <div className="">PITI</div>
                  <div className="w-full text-right">
                    <NumberFormat
                      // value={property.hoa_dues > 0 ? property.piti + property.hoa_dues : property.piti}
                      value={property.piti}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                    /mo
                  </div>
                </div>
              </div>
            </div>
            {showCosts && (
              <div className="mx-4 mb-4 line-clamp-6 h-28 border-t py-4 text-left text-xs">
                {property.listing_remarks}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
