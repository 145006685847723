import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { atom, useRecoilState } from "recoil";
import { useFinancials } from "state/proformas";

export const ShowCustomizeArvModalAtom = atom<boolean>({
  key: "ShowCustomizeArvModalAtom",
  default: false,
});

export const CustomizeArvModal = () => {
  const { setResaleValue } = useFinancials();
  const [showCustomizeArvModal, setShowCustomizeArvModal] = useRecoilState(ShowCustomizeArvModalAtom);
  const [customArvValue, setCustomArvValue] = useState<undefined | number>(undefined);

  const onCustomArvValueChange = (values: any, evInfo: any) => {
    if (evInfo.source === "event" && (values.floatValue || values.floatValue === 0)) {
      setCustomArvValue(values.floatValue);
    }
  };

  const closeModal = () => {
    setCustomArvValue(undefined);
    setShowCustomizeArvModal(false);
  };

  const closeSaveArv = () => {
    if (customArvValue) {
      setResaleValue(customArvValue);
    }
    closeModal();
  };

  return (
    <ModalWrapper isOpen={!!showCustomizeArvModal} closeModal={closeModal}>
      <div className="inline-block w-full max-w-sm transform overflow-hidden rounded-lg border border-gray-200 bg-white p-2 text-left align-middle shadow-xl transition-all">
        <button className="float-right outline-none" onClick={closeModal}>
          <XIcon className="mr-1 mt-1 w-5" aria-hidden="true" />
        </button>

        <div className="mt-4 p-3">
          <div className="mb-4 w-full text-center text-xl font-medium">Customize ARV</div>

          <div className="mb-4 flex w-full text-sm">
            <div className="w-1/2">Enter Custom ARV:</div>
            <div className="w-1/2">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                value={customArvValue}
                thousandSeparator={true}
                decimalScale={0}
                prefix="$ "
                onValueChange={onCustomArvValueChange}
              />
            </div>
          </div>
        </div>
        <hr className="mb-2" />
        <div className="text-center">
          <button className="m-1 px-2 py-1 text-st-lightest hover:text-green-700" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="hidden items-center rounded-sm bg-[#61a28d] px-4 py-2 text-sm font-normal text-white shadow-sm transition duration-150 ease-in-out hover:bg-[#5bbc9c] sm:inline-flex sm:h-10 sm:px-8"
            onClick={closeSaveArv}
          >
            Save
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};
