import { yupResolver } from "@hookform/resolvers/yup";
import { Spinner, WithSignIn } from "components";
import {
  AllFiltersSavedSearches,
  FILTER_OPTIONS,
  getNewUrlParamsStrings,
  HideUnknownEquityFilter,
  ListingRemarkItemType,
  ListingRemarksSearchBox,
  LoanFilter,
  MinMaxFilterInputs,
  MinMaxHybridDropdowns,
  StructureFilters,
} from "components/browse";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import { useUserSession } from "state";
import {
  listingEventOptions,
  listingTypeOptions,
  locationSearchItemsAtom,
  resetedFilterValues,
  useFilters,
} from "state/browse";
import { NewSaveSearchType, useSavedSearchList } from "state/proformas";
import * as Yup from "yup";

const numberNullable = Yup.number()
  .nullable(true)
  .transform((val) => (val === Number(val) ? val : null));

// Filters fields transformation rules
const validationSchema = Yup.object().shape({
  assumable_loan: Yup.boolean(),
  hide_unknown_equity: Yup.boolean(),
  listing_event: Yup.array().of(Yup.string()),
  listing_type: Yup.array().of(Yup.string()),
  max_bathrooms: numberNullable,
  max_bedrooms: numberNullable,
  max_days_on_market: numberNullable,
  max_equity: numberNullable,
  max_gross_gain: numberNullable,
  max_gross_income: numberNullable,
  max_listing_price: numberNullable,
  max_lot_square_feet: numberNullable,
  max_square_feet_finished: numberNullable,
  max_unfinished_basement: numberNullable,
  max_updated: numberNullable,
  max_year_built: numberNullable,
  min_bathrooms: numberNullable,
  min_bedrooms: numberNullable,
  min_days_on_market: numberNullable,
  min_equity: numberNullable,
  min_gross_gain: numberNullable,
  min_gross_income: numberNullable,
  min_listing_price: numberNullable,
  min_lot_square_feet: numberNullable,
  min_square_feet_finished: numberNullable,
  min_unfinished_basement: numberNullable,
  min_updated: numberNullable,
  min_year_built: numberNullable,
  structure_type: Yup.array().of(Yup.string()),
});

type Props = {
  setFiltersPanelOpen: (open: boolean) => void;
};

export const FiltersMobile = ({ setFiltersPanelOpen }: Props) => {
  const { filters, setFilters, numActiveFilters } = useFilters();
  const [listingRemarks, setListingRemarks] = useState<string[] | null>(filters.listing_remarks);

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: filters,
  };
  const { register, getValues, reset, setValue, control } = useForm(formOptions);

  const locationSearchItems = useRecoilValue(locationSearchItemsAtom);
  const location = useLocation();
  const currentUser = useUserSession();
  const [saving, setSaving] = useState<boolean>(false);
  const [newName, setNewName] = useState<string | null>(null);
  const { createSavedSearch } = useSavedSearchList(currentUser?.session?.user_id);

  const handleChange = useCallback(() => {
    const newFilters = validationSchema.cast(getValues());
    setFilters({ ...newFilters, listing_remarks: listingRemarks });
  }, [setFilters, getValues, listingRemarks]);

  register("structure_type", { onChange: handleChange });
  const structureTypeValues = getValues("structure_type");

  // See: https://dmitripavlutin.com/react-throttle-debounce/
  const debHandleChange = useMemo(() => debounce(handleChange, 600), [handleChange]);
  useEffect(() => {
    return () => {
      debHandleChange.cancel();
    };
  }, [debHandleChange]);

  useEffect(() => {
    reset(filters);
  }, [filters, reset]);

  useEffect(() => {
    setListingRemarks(filters.listing_remarks);
  }, [filters.listing_remarks, setListingRemarks]);

  const onListingRemarksChange = (items: ListingRemarkItemType[]) => {
    const filters = validationSchema.cast(getValues());
    setListingRemarks(items.map((item) => item.value));
    setFilters({ ...filters, listing_remarks: items.map((item) => item.value) });
  };

  const onStructureTypeClick = (value: string, isActive: boolean) => {
    let currentValues = getValues("structure_type");
    if (isActive) {
      currentValues = [...currentValues, value];
    } else {
      currentValues = currentValues.filter((v: string) => v !== value);
    }
    setValue("structure_type", currentValues);
    handleChange();
  };

  const onResetAllFiltersClick = () => {
    setFilters(resetedFilterValues());
  };

  const assumableLoadRegistration = register("assumable_loan", { onChange: handleChange });
  const hideUnknownEquityRegistration = register("hide_unknown_equity", { onChange: handleChange });

  const buildSavedFiltesParameters = () => {
    return getNewUrlParamsStrings(locationSearchItems, filters);
  };

  const onCreateNewSavedSearch = () => {
    const name = (newName || "").trim();
    if (name.length === 0) return;

    const newFilter: NewSaveSearchType = {
      name: name,
      active: false,
      parameters: {
        texts: buildSavedFiltesParameters(),
        url: location.pathname + location.search,
      },
    };

    setSaving(true);

    createSavedSearch(newFilter).finally(() => {
      setNewName(null);
      setSaving(false);
    });

    ReactGA.event({
      category: "Search",
      action: "Save from Browse",
      label: name,
    });
  };

  return (
    <div className="flex h-fit w-full flex-col overflow-y-auto bg-white">
      <div className="mb-24 flex grow flex-col pb-2">
        <div className="text-md w-full overflow-y-scroll bg-white text-st-lighter">
          <div className="w-full px-4">
            <div className="p-2">
              <div className="border-b">
                <AllFiltersSavedSearches />
              </div>
              <div className="mt-8 pb-4">
                <MinMaxHybridDropdowns
                  label="Price"
                  variable="listing_price"
                  unit="$"
                  filters={filters}
                  control={control}
                  handleChange={debHandleChange}
                  setValue={setValue}
                  options={FILTER_OPTIONS.price}
                />

                <MinMaxHybridDropdowns
                  label="Gross Gain"
                  variable="gross_gain"
                  unit="%"
                  filters={filters}
                  control={control}
                  handleChange={debHandleChange}
                  setValue={setValue}
                  options={FILTER_OPTIONS.gross_gain}
                />

                <MinMaxHybridDropdowns
                  label="Income"
                  variable="gross_income"
                  unit="%"
                  filters={filters}
                  control={control}
                  handleChange={debHandleChange}
                  setValue={setValue}
                  options={FILTER_OPTIONS.gross_income}
                />

                <LoanFilter registration={assumableLoadRegistration} />

                <MinMaxHybridDropdowns
                  label="Estimated Equity"
                  variable="equity"
                  unit="%"
                  withBadge={true}
                  filters={filters}
                  control={control}
                  handleChange={debHandleChange}
                  setValue={setValue}
                  options={FILTER_OPTIONS.equity}
                />

                <HideUnknownEquityFilter registration={hideUnknownEquityRegistration} />
              </div>

              <div className="mt-4 border-t pt-6">
                <StructureFilters
                  structureTypeValues={structureTypeValues}
                  handleClick={onStructureTypeClick}
                />
              </div>

              <div className="mt-2 gap-4">
                <div className="text-md">
                  <div className="flex w-full flex-col gap-2 py-4">
                    <MinMaxFilterInputs
                      label="Year Built"
                      variable="year_built"
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                    />

                    <MinMaxHybridDropdowns
                      label="Finished Sqft"
                      variable="square_feet_finished"
                      unit=" sqft"
                      filters={filters}
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                      options={FILTER_OPTIONS.square_feet_finished}
                    />

                    <MinMaxHybridDropdowns
                      label="Unfinished Sqft"
                      variable="unfinished_basement"
                      unit=" sqft"
                      filters={filters}
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                      options={FILTER_OPTIONS.unfinished_basement}
                    />

                    <MinMaxHybridDropdowns
                      label="Bedrooms"
                      variable="bedrooms"
                      unit=" beds"
                      filters={filters}
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                      options={FILTER_OPTIONS.bedrooms}
                    />

                    <MinMaxHybridDropdowns
                      label="Bathrooms"
                      variable="bathrooms"
                      unit=" baths"
                      filters={filters}
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                      options={FILTER_OPTIONS.bathrooms}
                    />

                    <div className="mt-4 border-t pt-4">
                      <MinMaxHybridDropdowns
                        label="Lot Sqft"
                        variable="lot_square_feet"
                        unit=" sqft"
                        filters={filters}
                        control={control}
                        handleChange={debHandleChange}
                        setValue={setValue}
                        options={FILTER_OPTIONS.lot_square_feet}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Property filters */}
              <div className="mt-4 border-t border-gray-200 pt-6">
                <div className="mb-2 text-sm font-bold text-st-normal">Last status update</div>
                <div className="flex flex-col flex-wrap gap-2">
                  {listingEventOptions.map((listingEventOption) => (
                    <label key={`lt-${listingEventOption}`} className="mr-4">
                      <input
                        type="checkbox"
                        value={listingEventOption}
                        className="mr-2 border outline-0 indeterminate:bg-gray-300 focus:ring-0"
                        {...register("listing_event", { onChange: handleChange })}
                      />
                      {listingEventOption}
                    </label>
                  ))}
                </div>
                <div className="flex w-full flex-col gap-2 py-4">
                  <MinMaxFilterInputs
                    label="Days since Upd"
                    variable="updated"
                    control={control}
                    handleChange={debHandleChange}
                    setValue={setValue}
                  />

                  <MinMaxFilterInputs
                    label="Days on Market"
                    variable="days_on_market"
                    control={control}
                    handleChange={debHandleChange}
                    setValue={setValue}
                  />
                </div>
              </div>

              <div className="mt-6 border-t border-gray-200 pt-5">
                <div className="mb-2 text-sm font-bold text-st-normal">Listing Type</div>
                <div className="flex flex-col flex-wrap gap-2">
                  {listingTypeOptions.map((listingTypeOption) => (
                    <label key={`lt-${listingTypeOption}`} className="mr-4">
                      <input
                        type="checkbox"
                        value={listingTypeOption}
                        className="mr-2 border outline-0 indeterminate:bg-gray-300 focus:ring-0"
                        {...register("listing_type", { onChange: handleChange })}
                      />
                      {listingTypeOption}
                    </label>
                  ))}
                </div>
              </div>

              {/* Listing remarks search box */}
              <div className="mt-6 border-t border-gray-200 pt-5">
                <div className="mb-2 text-sm font-bold text-st-normal">Listing Remarks</div>
                <ListingRemarksSearchBox value={listingRemarks} onChange={onListingRemarksChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 z-10 flex min-h-fit w-full shrink-0 flex-row items-center overflow-hidden border-t bg-white px-4 py-3 text-center">
        <div className="flex w-full flex-row justify-between">
          <WithSignIn asButton={false} text="Login to save your searches">
            <input
              type="text"
              className="mr-2 w-full grow border-x-0 border-b-2 border-t-0 border-b-black pb-1 pl-1 pr-0 text-sm focus:border-x-0 focus:border-t-0 focus:border-b-black"
              placeholder="Name Your Search"
              value={newName || ""}
              onChange={(e) => setNewName(e.target.value)}
            />
            <button
              className="flex min-h-8 min-w-[60px] items-center justify-center whitespace-nowrap rounded-sm border border-black bg-white text-sm text-st-darkest"
              onClick={() => {
                onCreateNewSavedSearch();
              }}
              disabled={!(newName || "").trim()}
            >
              {saving ? <Spinner className="size-5 text-st-darkest" /> : <>Save</>}
            </button>
          </WithSignIn>
          <button
            className="ml-2 min-h-8 min-w-[60px] items-center justify-center whitespace-nowrap rounded-sm border border-black bg-black text-sm text-white"
            onClick={() => setFiltersPanelOpen(false)}
          >
            Search
          </button>
        </div>
        <div>
          {numActiveFilters > 0 && (
            <button
              className="ml-4 whitespace-nowrap text-sm text-[#6389c4] hover:text-st-normal hover:underline"
              onClick={onResetAllFiltersClick}
            >
              Reset
              <br />
              filters
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
