import { appConfig, stateAbbreviationToStateId, stateNameToStateId } from "config";
import { fetcherWithCredentials } from "helpers";
import { useCallback, useRef } from "react";
import useSWR from "swr";

export type TypeAheadTypeType =
  | "city"
  | "neighborhood"
  | "state"
  | "county"
  | "zip_code"
  | "metro_area"
  | "parcel";

export type TypeAheadItemType = {
  type: TypeAheadTypeType;
  abbreviation?: string; // city | neighborhood | state | county
  address_for_url?: string; //                                                 | parcel
  city_id?: number; //      | neighborhood
  city_name?: string; //      | neighborhood                             | parcel
  city?: string; //      | neighborhood
  county_id?: number; // city | neighborhood
  county_name?: string; //                                                 | parcel
  excise_tax?: null; //                     | state | county
  fips?: string; //                     | state | county
  full_address?: string; //                                                 | parcel
  id?: number | string; // city | neighborhood | state | county | zip_code
  latitude?: number; //                                                 | parcel
  listing_number?: number; //                                                 | parcel
  longitude?: number; //                                                 | parcel
  metro_area_id?: number;
  metro_area_name?: string;
  name: string; // city | neighborhood | state | county | zip_code
  parcel_photo_url_prefix?: null; //                             | county
  property_tax_rate?: null; //                             | county
  parcel_id?: number; //                                                 | parcel
  parcel_number?: string; //                                                 | parcel
  provider_id?: number; //      | neighborhood
  sales_tax?: null; //                     | state | county
  state_id?: number; // city | neighborhood |       | county
  state_name?: string; // city | neighborhood |       | county | zip_code | parcel
  state_abbreviations?: string; //                                                           | metro_area
  state?: string; //      | neighborhood
  street_address?: string; //                                                 | parcel
  unit?: string; //                                                 | parcel
  user_id?: null;
  zip_code?: string; //                                                 | parcel
};

const normalize = (response: TypeAheadItemType[]) => {
  return response
    .map((item) => {
      if (item.type === "state") {
        item.state_id = Number(item.id);
      }
      if (item.type === "zip_code") {
        item.id = Number(item.name);
      }
      if (item.type === "parcel" || item.type === "zip_code") {
        item.state_id = stateNameToStateId[item.state_name as keyof typeof stateNameToStateId];
      }
      if (item.type === "metro_area") {
        if (item.state_abbreviations?.length === 2) {
          item.state_id =
            stateAbbreviationToStateId[
              item.state_abbreviations.toUpperCase() as keyof typeof stateAbbreviationToStateId
            ];
        }
      }
      return item;
    })
    .filter((item) => item.type !== "state");
};

export const useTypeAhead = (term: string, types: TypeAheadTypeType[] | undefined = undefined) => {
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetcher = useCallback(async (url: string) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    try {
      const response = await fetcherWithCredentials(url, "GET", undefined, abortControllerRef.current.signal);
      return normalize(response);
    } catch (error) {
      if (error instanceof Error && error.name === "AbortError") {
        // Ignore abort errors
        return [];
      }
    }
  }, []);

  const urlParams = new URLSearchParams({ term });
  if (types) {
    types.forEach((type) => urlParams.append("type", type));
  }

  const shouldFetch = term.length > 1;
  const url = shouldFetch ? `${appConfig.apiEndpoint.proformaTypeahead}?${urlParams.toString()}` : null;

  const { data, error, isValidating } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    dedupingInterval: 300,
  });

  return {
    results: data,
    isLoading: shouldFetch && isValidating,
    isError: error,
  };
};
