import { Popover, Transition } from "@headlessui/react";
import { DotsVerticalIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { Spinner, SpinnerWithArrows } from "components";
import { NotificationsActive, NotificationsOff } from "components/icons";
import { cn, compareUrlLocations } from "helpers";
import { useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

type Props = {
  filter: any;
  onDeleteSavedSearch: CallableFunction;
  onUpdateSavedSearch: CallableFunction;
  buildSavedFiltesParameters: CallableFunction;
};

export const AllFiltersSavedSearch = ({
  filter,
  onDeleteSavedSearch,
  onUpdateSavedSearch,
  buildSavedFiltesParameters,
}: Props) => {
  const location = useLocation();
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [alertChanging, setAlertChanging] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [editName, setEditName] = useState<string | null>(null);

  const isSameLocations = compareUrlLocations(filter.parameters.url, location.pathname + location.search);

  const onDeleteSearch = () => {
    setDeleting(true);
    onDeleteSavedSearch(filter.id);
  };

  const toggleEditName = () => {
    if (editName === null) {
      setEditName(filter.name);
    } else {
      setEditName(null);
    }
  };

  const editSave = () => {
    if (editName && editName.trim().length > 0) {
      setSaving(true);
      filter.name = editName.trim();
      onUpdateSavedSearch(filter).finally(() => {
        setSaving(false);
        setEditName(null);
      });
    }
  };

  const onUpdateFilters = () => {
    setUpdating(true);
    filter.parameters = {
      texts: buildSavedFiltesParameters(),
      url: location.pathname + location.search,
    };
    filter.active = filter.active || false;
    onUpdateSavedSearch(filter).finally(() => {
      setUpdating(false);
    });
  };

  const toggleAlertEmail = () => {
    setAlertChanging(true);
    filter.active = !filter.active;
    onUpdateSavedSearch(filter).finally(() => setAlertChanging(false));

    ReactGA.event({
      category: "Browse",
      action: filter.active ? "Activate Alert" : "Disable Alert",
      label: filter.name,
    });
  };

  return (
    <li className="flex w-full flex-row items-center justify-between gap-2 pt-1 align-middle">
      <div className="flex w-2/3 whitespace-nowrap">
        {editName === null ? (
          <div className={cn("w-full truncate", isSameLocations && "text-st-darkest bg-[#CAE8DE]")}>
            <a className="cursor-pointer" href={filter.parameters.url}>
              {filter.name}
            </a>
          </div>
        ) : (
          <div className="flex w-full items-center">
            <input
              type="text"
              className="h-[30px] w-32 items-center rounded-l-md border-gray-300 text-sm focus:border-gray-300"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
            />
            <button
              className="text-st-darkest flex whitespace-nowrap border border-l-0 border-gray-300 bg-white p-1 text-sm"
              onClick={() => setEditName(null)}
              disabled={saving}
            >
              Cancel
            </button>
            <button
              className="flex whitespace-nowrap rounded-r-md border border-black bg-black p-1 text-sm text-white"
              onClick={editSave}
              disabled={!(editName || "").trim()}
            >
              {saving ? <Spinner className="size-[18px] text-white" /> : <>Save</>}
            </button>
          </div>
        )}
      </div>
      <div className="flex w-1/3 flex-row justify-end whitespace-nowrap">
        {isSameLocations && (
          <div
            className="text-sl-normal ml-2 flex w-full cursor-pointer flex-row items-center justify-center align-middle"
            onClick={onUpdateFilters}
            title="Update with current filters"
          >
            {updating ? (
              <SpinnerWithArrows className="size-[18px] text-center" />
            ) : (
              <span className="text-xs">Update</span>
            )}
          </div>
        )}
        <div
          className="flex w-full cursor-pointer items-center justify-end align-middle text-[18px]"
          title={`${filter.active ? "Dea" : "A"}ctivate alert email`}
          onClick={toggleAlertEmail}
        >
          {alertChanging ? (
            <Spinner className="text-st-darkest size-[18px]" />
          ) : (
            <>
              {filter.active ? (
                <NotificationsActive className="text-st-darkest size-[18px]" />
              ) : (
                <NotificationsOff className="text-st-lightest size-[18px]" />
              )}
            </>
          )}
        </div>
        <Popover as="div" className="relative ml-2 bg-white align-middle">
          <Popover.Button className="flex align-middle">
            <DotsVerticalIcon className="hover:text-st-darkest text-st-lightest size-[18px] ui-open:transform ui-open:animate-bounce" />
          </Popover.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          ></Transition>
          <Popover.Panel className="text-st-lightest absolute right-1 z-10 mt-1 w-fit rounded-md bg-white p-2 text-right text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <ul>
              <li className="p-2">
                <Popover.Button>
                  <div
                    className="flex w-full cursor-pointer flex-row justify-end"
                    onClick={toggleEditName}
                    title="Rename"
                  >
                    Edit Name
                    <PencilAltIcon className="hover:text-st-darkest text-st-lightest ml-2 size-[18px]" />
                  </div>
                </Popover.Button>
              </li>
              <li className="p-2">
                <Popover.Button>
                  <div
                    className="flex w-full cursor-pointer flex-row justify-end"
                    onClick={onDeleteSearch}
                    title="Delete"
                  >
                    Delete
                    {deleting ? (
                      <Spinner className="text-st-darkest ml-2 size-[18px]" />
                    ) : (
                      <TrashIcon className="hover:text-st-darkest text-st-lightest ml-2 size-[18px]" />
                    )}
                  </div>
                </Popover.Button>
              </li>
            </ul>
          </Popover.Panel>
        </Popover>
      </div>
    </li>
  );
};
