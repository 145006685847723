import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

type Props = {
  open: boolean;
};

export const HamburguerButton = ({ open }: Props) => {
  return (
    <>
      <div className="inset-y-0 right-2 flex items-center sm:hidden">
        {/* Hamburguer menu button*/}
        <Disclosure.Button className="hover:text-st-lightest text-st-light inline-flex items-center justify-center rounded-md p-2 pl-0 focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white">
          <span className="sr-only">Open main menu</span>
          {open ? (
            <XIcon className="block h-6 w-6" aria-hidden="true" />
          ) : (
            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
          )}
        </Disclosure.Button>
      </div>
    </>
  );
};
