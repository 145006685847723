import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

export function useParcelPhotos(parcelId: number) {
  const url = appConfig.apiEndpoint.parcelPhotos.replace(":parcel_id", parcelId.toString());

  const { data, error, isLoading } = useSWR(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    photos: data,
    isLoading,
    isError: error,
  };
}
