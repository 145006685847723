import { cn } from "helpers";
import { useEffect, useState } from "react";

const [animate, awaiting, done] = ["animate-pulse text-st-lightest", "text-gray-200", "text-st-darkest"];

type LoadingPhrasesProps = {
  phrases: string[];
  isSourceLookup?: boolean;
};

export const LoadingPhrases = ({ phrases, isSourceLookup = false }: LoadingPhrasesProps) => {
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (phraseIndex !== phrases.length - 1) setPhraseIndex((prevIndex) => prevIndex + 1);
      if (phraseIndex === phrases.length - 1 && isSourceLookup) {
        setShowNotification(true);
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [phrases, phraseIndex, isSourceLookup]);

  return (
    <>
      {showNotification && (
        <div className="fixed left-0 right-0 top-0 z-50 bg-warning p-2 text-center text-st-dark">
          Due to a sudden increase in usage, the website is loading slower than usual. We apologize for the
          inconvenience.
        </div>
      )}
      <div className="flex h-screen w-full flex-row items-center justify-center">
        <div className="flex flex-col items-start">
          {phrases.map((phrase, index) => (
            <div
              key={index}
              className={cn(
                "text-lg",
                index === phraseIndex ? animate : index < phraseIndex ? done : awaiting,
              )}
            >
              <span className="font-['Roboto']">✓</span> {phrase}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
