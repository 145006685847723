import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

export type SaleAppreciationType = {
  city_ppsqft: number[];
  metro_area_ppsqft: number[];
  county_ppsqft: number[];
  appreciation: number[];
  dates: string[];
  historical_sales: [
    {
      sale_date: string;
      sale_price: number;
    },
  ];
  geo_attribute_for_appreciation: string;
  structure_type_for_appreciation: string;
};

export type RentalAppreciationType = {
  city_ppsqft: number[];
  metro_area_ppsqft: number[];
  county_ppsqft: number[];
  appreciation: number[];
  dates: string[];
  geo_attribute_for_appreciation: string;
  bedrooms_for_rental_appreciation: string;
};

export const useSaleAppreciation = (parcelId: number) => {
  const url = appConfig.apiEndpoint.saleAppreciationGraph.replace(":parcel_id", parcelId.toString());
  const { data, error } = useSWR<SaleAppreciationType>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    loading: !data && !error,
    data,
    error,
  };
};

export const useRentalAppreciation = (parcelId: number) => {
  const url = appConfig.apiEndpoint.rentalAppreciationGraph.replace(":parcel_id", parcelId.toString());
  const { data, error } = useSWR<RentalAppreciationType>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    loading: !data && !error,
    data,
    error,
  };
};
