import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { formatNumber } from "helpers";
import NumberFormat from "react-number-format";
import { atom, useRecoilState } from "recoil";
import { FinancialsType, getFoundationAmount, useFinancials } from "state/proformas";

export const costNameDictionary = {
  annual_taxes: "Taxes",
  closing_selling: "Title and Escrow",
  commission_listing: "Listing Commission",
  commission_selling: "Selling Commission",
  excise: "Excise Tax",
  hoa_dues: "HOA Dues",
  insurance: "Insurance",
  maintenance_hold: "Maintenance Budget",
  maintenance_brrr: "Maintenance Budget",
  management_brrr: "Property Management",
  management_hold: "Property Management",
  miscellaneous_acquisition: "Other",
  rental_insurance_hold: "Insurance",
  rental_insurance_brrr: "Rental Insurance",
  rental_utilities_hold: "Utilities",
  rental_utilities_brrr: "Utilities",
  taxes: "Taxes",
  taxes_hold: "Taxes",
  taxes_brrr: "Taxes",
  title_and_escrow: "Title and Escrow",
  utilities: "Utilities",
  vacancy_brrr: "Vacancy Budget",
  vacancy_hold: "Vacancy Budget",
};

const foundationNameDictionary = {
  annual_taxes: "Yearly Taxes",
  holding_time: "Holding Time",
  monthly_rent_brrr: "Monthly Rent",
  monthly_rent_hold: "Monthly Rent",
  purchase_price: "Purchase Price",
  resale_value: "After Repair Value",
  square_feet_finished: "Finished Square Foot",
};

export const getCostDetail = (cost: any) => {
  if (cost.is_fixed) return "";

  switch (cost._foundation_type) {
    case "cents":
      return `${formatNumber(cost.multiplier * 100)}¢ per ${foundationNameDictionary[cost.foundation as keyof typeof foundationNameDictionary] || cost.foundation}`;
    case "tax":
      return "based on annual taxes";
    case "fixed":
      return "fixed amount";
    default:
      if (cost.name === "title_and_escrow") {
        return `${Math.round(cost.multiplier * 10000) / 100}% of the price + ${cost.base}`;
      }
      return `${Math.round(cost.multiplier * 10000) / 100}% of the ${foundationNameDictionary[cost.foundation as keyof typeof foundationNameDictionary] || cost.foundation}`;
  }
};

const validateFields = (cost: any) => {
  if (cost.is_fixed) {
    // Gotta be a number!
    if (typeof cost.amount !== "number") {
      return false;
    }
  } else if (!cost.foundation) {
    return false;
  } else if (typeof cost.multiplier !== "number") {
    return false;
  }
  return true;
};

const updateAmounts = (cost: any, financials: FinancialsType | null) => {
  if (!validateFields(cost)) {
    return;
  }

  cost.multiplier = cost._percent / 100;

  if (cost.is_fixed) {
    // we'll store the amount entered as the new 'base'
    cost.base = cost._amount;
  } else {
    const foundationAmount = getFoundationAmount(cost.foundation, financials || ({} as FinancialsType));
    cost._amount = cost.base + cost.multiplier * foundationAmount;
    cost._amount = Math.round(cost._amount);
  }

  return true;
};

const updatePercents = (cost: any, financials: FinancialsType | null) => {
  if (!validateFields(cost)) {
    return;
  }

  if (!cost.is_fixed) {
    const foundationAmount = getFoundationAmount(cost.foundation, financials || ({} as FinancialsType));
    cost.multiplier = cost._amount / foundationAmount;
    cost._percent = cost.multiplier * 100;
  }

  return true;
};

export const setupCosts = (cost: any, financials: FinancialsType | null) => {
  const newCost = { ...cost };

  newCost._amount = cost.amount;
  if (cost._costgroup === "carrying") {
    newCost._amount /= financials?.calcsheet.carrying_time || 1;
  }
  // Convert the multiplier into a nice, readable percent
  if (typeof cost.multiplier === "number") {
    if (cost.is_fixed) {
      newCost._percent = 100;
    } else {
      newCost._percent = cost.multiplier * 100;
    }
  }
  updateAmounts(newCost, financials);
  return newCost;
};

export const editCostModalAtom = atom<null | any>({
  key: "editCostModalAtom",
  default: null,
});

export const CostEditModal = () => {
  const { financials, updateCost } = useFinancials();
  const [editCostModal, setEditCostModal] = useRecoilState(editCostModalAtom);
  const cost = editCostModal;
  const mode = cost?.foundation === "square_feet_finished" ? "cents" : "default";

  const closeModal = () => {
    setEditCostModal(null);
  };

  const closeSaveCost = () => {
    const newCost = { ...editCostModal };
    if (updateAmounts(newCost, financials)) {
      // If the amount is annual, divide by 12 to get monthly
      if (newCost._compound_rate === "annually") {
        newCost._amount /= 12;
      }
      // If we are in the CARRYING COSTS costgroup, multiply the amount by the carrying time
      if (newCost._costgroup === "carrying") {
        newCost._amount *= financials?.calcsheet.carrying_time || 1;
      }
      // Map it out
      newCost.amount = Math.round(newCost._amount);

      updateCost(newCost);
    }
    setEditCostModal(null);
  };

  const onAmountChange = (values: any, evInfo: any) => {
    if (evInfo.source === "event" && (values.floatValue || values.floatValue === 0)) {
      const newCost = { ...editCostModal, _amount: values.floatValue };
      if (updatePercents(newCost, financials)) {
        setEditCostModal(newCost);
      }
    }
  };

  const onPercentChange = (values: any, evInfo: any) => {
    if (evInfo.source === "event" && (values.floatValue || values.floatValue === 0)) {
      const newCost = { ...editCostModal, _percent: values.floatValue };
      if (updateAmounts(newCost, financials)) {
        setEditCostModal(newCost);
      }
    }
  };

  const onBaseChange = (values: any, evInfo: any) => {
    if (evInfo.source === "event" && (values.floatValue || values.floatValue === 0)) {
      const newCost = { ...editCostModal, base: values.floatValue };
      if (updateAmounts(newCost, financials)) {
        setEditCostModal(newCost);
      }
    }
  };

  return (
    <ModalWrapper isOpen={!!editCostModal} closeModal={closeModal}>
      <div className="inline-block w-full max-w-sm transform overflow-hidden rounded-lg border border-gray-200 bg-white p-2 text-left align-middle shadow-xl transition-all">
        <button className="float-right outline-none" onClick={closeModal}>
          <XIcon className="mr-1 mt-1 w-5" aria-hidden="true" />
        </button>

        <div className="text-md mt-4 p-3">
          <div className="text-md flex flex-row justify-items-stretch gap-2 pb-2 leading-9">
            <div className="w-full basis-3/5 whitespace-nowrap font-bold">
              {costNameDictionary[cost?.name as keyof typeof costNameDictionary] || cost?.name}
            </div>
            <div className="flex w-full basis-2/5 items-center pr-1 text-right">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                value={cost?._amount}
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
                onValueChange={onAmountChange}
              />
              {cost?._compound_rate === "monthly" && <span>&nbsp;/&nbsp;mo</span>}
              {cost?._compound_rate === "annually" && <span>&nbsp;/&nbsp;yr</span>}
            </div>
          </div>

          <div className="text-left leading-6">
            Set the {costNameDictionary[cost?.name as keyof typeof costNameDictionary] || cost?.name} to be:
            <div className="my-2">
              {cost?.is_fixed && (
                <NumberFormat
                  className="h-5 w-1/3 border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                  value={cost?._amount}
                  thousandSeparator={true}
                  prefix="$ "
                  decimalScale={0}
                  onValueChange={onAmountChange}
                />
              )}

              {!cost?.is_fixed && (
                <>
                  <NumberFormat
                    className="mr-2 h-5 w-1/3 border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                    value={cost?._percent}
                    thousandSeparator={true}
                    suffix={mode === "cents" ? " ¢" : " %"}
                    decimalScale={3}
                    onValueChange={onPercentChange}
                  />
                  {mode === "cents" ? <>per&nbsp;</> : <>of the&nbsp;</>}
                  {foundationNameDictionary[cost?.foundation as keyof typeof foundationNameDictionary] ||
                    cost?.foundation}
                </>
              )}
              {cost?._compound_rate === "monthly" && <span>&nbsp;per month</span>}
              {cost?._compound_rate === "annually" && <span>&nbsp;per year</span>}
            </div>
          </div>

          {cost?._has_base && (
            <div className="my-4">
              <span>Plus a base of </span>
              <NumberFormat
                className="h-5 w-1/3 border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                value={cost?.base}
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
                onValueChange={onBaseChange}
              />

              {cost?._compound_rate === "monthly" && <span>&nbsp;per month</span>}
              {cost?._compound_rate === "annually" && <span>&nbsp;per year</span>}
            </div>
          )}
        </div>
        <hr className="mb-2" />
        <div className="text-center">
          <button className="m-1 px-2 py-1 text-st-lightest hover:text-green-700" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="items-center rounded-sm bg-[#61a28d] px-4 py-2 text-sm font-normal text-white shadow-sm transition duration-150 ease-in-out hover:bg-[#5bbc9c] sm:inline-flex sm:h-10 sm:px-8"
            onClick={closeSaveCost}
          >
            Save
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};
