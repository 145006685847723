import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { formatCurrencyK1, formatPercent } from "helpers";
import { Fragment, useRef, useState } from "react";
import { isDesktop } from "react-device-detect";

type Props = {
  label: string;
  variable: string;
  unit?: string | null;
  tag?: "new" | null;
  filters: any;
  options: number[];
  equityRangeSet: (min: number | null, max: number | null) => void;
};

export const FilterGrossGainIncomeMenu = ({
  label,
  variable,
  unit = "",
  tag = null,
  filters,
  options = [],
  equityRangeSet,
}: Props) => {
  const min_value = filters[`min_${variable}`];
  const max_value = filters[`max_${variable}`];

  let buttonTittle = "";
  if (min_value !== null && max_value !== null) {
    buttonTittle = `${formatPercent(min_value / 100, 0)} - ${formatPercent(max_value / 100, 0)}`;
  } else if (variable === "equity" && min_value === 100) {
    buttonTittle = `${formatPercent(min_value / 100, 0)}`;
  } else if (min_value !== null) {
    buttonTittle = `${formatPercent(min_value / 100, 0)}+`;
  } else if (max_value !== null) {
    buttonTittle = `Up to ${formatPercent(max_value / 100, 0)}`;
  }

  const inputRef = useRef<any>();
  const [customValue, setCustomValue] = useState<number | null>(null);
  return (
    <>
      {isDesktop && (
        <Menu as="div" className={`relative ml-2 mr-1 hidden md:mx-2 filter-structure:flex`}>
          {({ open, close }) => (
            <>
              <Menu.Button
                className={`text-md flex items-center justify-between rounded-md border border-gray-300 py-2 pl-3 pr-2 text-left text-sm font-medium ${
                  min_value !== null || max_value !== null ? "bg-black text-white" : "text-st-light"
                }`}
              >
                <span className="flex items-center whitespace-nowrap">
                  {min_value !== null || max_value !== null ? <>{buttonTittle}</> : <>{label}</>}
                </span>
                {open ? (
                  <ChevronDownIcon className="ml-2 h-4 w-4" />
                ) : (
                  <>
                    {min_value !== null || max_value !== null ? (
                      <XIcon
                        className="ml-2 h-4 w-4"
                        onClick={(e) => {
                          e.stopPropagation();
                          equityRangeSet(null, null);
                          setCustomValue(null);
                        }}
                      />
                    ) : (
                      <ChevronDownIcon className="ml-2 h-4 w-4 text-st-darkest" />
                    )}
                  </>
                )}
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`absolute top-8 mt-2 ${variable === "gross_gain" ? "w-[180px]" : "w-[160px]"} origin-top-left rounded-md bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                  <div className="mb-2 flex pb-2 text-xs font-bold text-st-normal">
                    <div className="mr-2 text-sm font-bold">{label}</div>
                  </div>
                  <div className="flex h-fit w-full flex-col items-center justify-center overflow-y-scroll">
                    <input
                      ref={inputRef}
                      type="number"
                      className="h-10 w-full items-center rounded-md border border-dotted border-gray-400 p-1 text-center ring-1 ring-black ring-opacity-5 placeholder:text-center placeholder:text-sm placeholder:italic placeholder:text-st-lightest focus:border-gray-400 focus:placeholder-transparent focus:outline-none focus:ring-0"
                      placeholder={
                        customValue !== null
                          ? options.includes(customValue)
                            ? ""
                            : `${customValue.toString()}${unit} ${label}`
                          : `Enter Value`
                      }
                      onChange={(e) => {
                        const newValue = e.target.value ? parseFloat(e.target.value) : null;
                        setCustomValue(newValue);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                          if (
                            customValue !== null &&
                            ((max_value !== null && customValue > max_value) ||
                              customValue < options[0] ||
                              (max_value === null && customValue > options[options.length - 1]))
                          ) {
                            return;
                          }
                          equityRangeSet(customValue, max_value);
                          close();
                        }
                      }}
                    />
                    {options
                      .filter((option) => (max_value !== null ? option < max_value : true))
                      .map((option, idx) => (
                        <Menu.Item key={label + idx}>
                          <div className="m-1 w-full text-center text-sm" key={`ggo-${option}`}>
                            <button
                              className={`w-full p-1 text-center ${
                                min_value === option
                                  ? "bg-[#6389c4] text-white"
                                  : "bg-white text-st-light hover:bg-[#6389c4] hover:text-white"
                              }`}
                              onClick={() => {
                                setCustomValue(null);
                                equityRangeSet(option === min_value ? null : option, max_value);
                              }}
                            >
                              {option === +Infinity
                                ? `Any ${label}`
                                : unit === "$"
                                  ? formatCurrencyK1(option)
                                  : option === 0
                                    ? `0${unit}${variable === "gross_gain" ? "+" : ""} ${label}`
                                    : `${option}${unit} ${label}`}
                            </button>
                          </div>
                        </Menu.Item>
                      ))}
                    {variable === "gross_gain" && (
                      <div className="flex w-full flex-col whitespace-nowrap px-2 pt-4 text-sm italic text-st-light">
                        <div className="pb-2 font-medium">Example:</div>
                        <div className="">$300k Price</div>
                        <div className="">$400k ARV</div>
                        <div className="my-1 h-0.5 w-full bg-st-lightest" />
                        <div className="">33% Gross Gain</div>
                      </div>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </>
  );
};
