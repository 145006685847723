import { appConfig } from "config";
import { MultiPolygon } from "geojson";

const fetchPolygon = async (type: string, id: number): Promise<MultiPolygon> => {
  let idStr = id.toString();
  if (type === "zip_code") {
    idStr = idStr.padStart(5, "0");
  }
  return fetch(appConfig.apiEndpoint.locationPolygon.replace(":type", type).replace(":id", idStr)).then(
    (res) => res.json(),
  );
};

export const fetchPolygons = (locations: any[]) => {
  return Promise.all(locations.map((location) => fetchPolygon(location.type, location.id)));
};
