import { SearchIcon } from "@heroicons/react/solid";
import { AppreciationPeriodType, controlsLabel, Map, PropertyMobile } from "components/browse";
import { LayerIcon, SquareVectorIcon } from "components/icons";
import { TrendChartIcon } from "components/icons/TrendChartIcon";
import { NavBrowse } from "components/NavBrowse";
import { SearchBoxBrowseMobile } from "components/SearchBox";
import { useState } from "react";
import { useSearchParamsState } from "react-use-search-params-state";
import { useSelectedProperty, useUserPropertiesData } from "state";
import { mapBoundsDefaults } from "state/browse";

// Define basemap types for type safety
export type BasemapType = "streets" | "satellite";

export const BrowseMapMobile = ({ emptyResult }: { emptyResult: boolean }) => {
  const { selectedProperty } = useSelectedProperty();
  const { userPropertiesData, saveUserPropertiesData } = useUserPropertiesData();
  const [mapBoundsParams] = useSearchParamsState(mapBoundsDefaults);
  const [currentOutlineValue, setCurrentOutlineValue] = useState(mapBoundsParams.outLines);
  // State for the current values of the controls
  const [currentBasemapValue, setCurrentBasemapValue] = useState<BasemapType>("streets");
  const [currentAppreciationValue, setCurrentAppreciationValue] = useState<AppreciationPeriodType>(
    emptyResult ? "pct_growth_one_year" : "none",
  );

  // State for the visibility of the panels of each icon
  const [showIcons, setShowIcons] = useState({
    outline: false,
    basemap: false,
    appreciation: false,
    search: false,
    menu: false,
  });

  const toggleIcon = (iconKey: keyof typeof showIcons) => ({
    outline: false,
    basemap: false,
    appreciation: false,
    search: false,
    menu: false,
    [iconKey]: !showIcons[iconKey],
  });

  const handleOutlineChange = () => {
    setCurrentOutlineValue(!currentOutlineValue);
    setShowIcons(toggleIcon("outline"));
  };

  const handleBasemapChange = (type: BasemapType) => {
    setCurrentBasemapValue(type);
    setShowIcons(toggleIcon("basemap"));
  };

  const handleAppreciationChange = (type: AppreciationPeriodType) => {
    setCurrentAppreciationValue(type);
    setShowIcons(toggleIcon("appreciation"));
  };

  return (
    <div className="relative flex h-full w-full flex-col overscroll-none bg-[#aadaff]">
      <div
        className={`absolute left-2.5 top-2 z-[1000] size-fit rounded-md border border-st-soft ${
          currentOutlineValue ? "bg-st-soft" : "bg-white"
        } p-1.5 shadow-md`}
        onClick={() => handleOutlineChange()}
      >
        <SquareVectorIcon className="size-7 text-st-lightest" />
      </div>
      <div
        className="absolute left-2.5 top-16 z-[1000] size-fit rounded-md border border-st-soft bg-white p-2 shadow-md"
        onClick={() => setShowIcons(toggleIcon("appreciation"))}
      >
        <TrendChartIcon className="size-6 text-st-lightest" />
        {controlsLabel[currentAppreciationValue] !== "None" && (
          <div className="absolute -left-1 -top-1 rounded-full bg-red-500 px-1 text-xs text-white">
            {controlsLabel[currentAppreciationValue]}
          </div>
        )}
      </div>
      {showIcons.appreciation && (
        <div className="absolute left-[60px] top-16 z-[1000] w-24 rounded-md border border-st-soft bg-white shadow-md">
          <div className="p-2">
            <ul className="space-y-1">
              {Object.entries(controlsLabel).map(([type, label]) =>
                type === "none" && emptyResult ? null : (
                  <li
                    key={type}
                    className={`cursor-pointer rounded p-1 hover:bg-gray-100 ${
                      currentAppreciationValue === type ? "bg-gray-100 font-bold" : ""
                    }`}
                    onClick={() => handleAppreciationChange(type as AppreciationPeriodType)}
                  >
                    {label}
                  </li>
                ),
              )}
            </ul>
          </div>
        </div>
      )}
      <div
        className="absolute left-2.5 top-[120px] z-[1000] size-fit cursor-pointer rounded-md border border-st-soft bg-white p-2 shadow-md"
        onClick={() => setShowIcons(toggleIcon("basemap"))}
      >
        <LayerIcon className="size-6 text-st-lightest" />
      </div>
      {showIcons.basemap && (
        <div className="absolute left-[60px] top-[120px] z-[1000] w-24 rounded-md border border-st-soft bg-white shadow-md">
          <div className="p-2">
            <ul className="space-y-1">
              {[
                { type: "satellite", label: "Satellite" },
                { type: "streets", label: "Streets" },
              ].map(({ type, label }) => (
                <li
                  key={type}
                  className={`cursor-pointer rounded p-1 hover:bg-gray-100 ${
                    currentBasemapValue === type ? "bg-gray-100 font-bold" : ""
                  }`}
                  onClick={() => handleBasemapChange(type as BasemapType)}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div
        className="absolute right-2.5 top-2 z-[1010] size-fit rounded-full border border-st-soft bg-white p-2 shadow-md"
        onClick={() => setShowIcons(toggleIcon("menu"))}
      >
        <NavBrowse closed={!showIcons.menu} />
      </div>
      <div
        className="absolute right-16 top-2 z-[1000] size-fit rounded-full border border-st-soft bg-white p-2 shadow-md"
        onClick={() => setShowIcons(toggleIcon("search"))}
      >
        <SearchIcon className="size-6 text-st-lightest" />
      </div>
      {showIcons.search && (
        <SearchBoxBrowseMobile handleSearchChange={() => setShowIcons(toggleIcon("search"))} />
      )}

      <Map
        emptyResult={emptyResult}
        outline={currentOutlineValue}
        basemap={currentBasemapValue}
        currentAppreciation={currentAppreciationValue}
      />

      {selectedProperty && (
        <>
          {saveUserPropertiesData({
            ...userPropertiesData,
            viewed_properties: {
              ...userPropertiesData?.viewed_properties,
              [selectedProperty.parcel_id]: true,
            },
          })}

          <div className="fixed bottom-[71px] z-[1000] h-fit w-full px-0.5">
            <PropertyMobile property={selectedProperty} showCosts={false} overMap={true} />
          </div>
        </>
      )}
    </div>
  );
};
