import { ArrowRightIcon, PlusIcon } from "@heroicons/react/outline";
import { marketStructureTypes, newSearchParams } from "components/markets";
import NumberFormat from "react-number-format";
import { useMarketResearchTopPerformers } from "state/market";

type Props = {
  region: string;
  withAdd?: boolean;
};

export const BestPerformingMarkets = ({ region, withAdd }: Props) => {
  const performances = useMarketResearchTopPerformers(region);

  return (
    <div className="flex w-full flex-col gap-4 pb-4 text-left sm:flex-row">
      <div className="mb-4 w-full">
        <div>
          <div className="text-md text-left font-bold">Best Performing Markets</div>
          <div className="text-st-normal pb-3 pt-0 text-sm font-light">In the last 12 months</div>
          <table className="w-full table-auto text-left text-sm">
            <thead>
              <tr>
                <th></th>
                <th>Location</th>
                <th className="py-1 text-right">Growth</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {performances.map((performance, idx) => (
                <tr key={idx} className="border-b">
                  <td className="items-center py-2 text-left font-light">
                    <div className="flex flex-row">
                      {withAdd && (
                        <a
                          href={newSearchParams(performance.browse_page_path)}
                          className="text-st-darkest hover:text-sl-lighter cursor-pointer"
                        >
                          <PlusIcon className="text-st-darkest hover:text-sl-lighter mr-2 h-4 w-3" />
                        </a>
                      )}
                    </div>
                  </td>
                  <td className="py-2 align-top font-light">
                    <a
                      href={performance.browse_page_path}
                      className="hover:text-sl-lighter py-2 pb-0.5 align-top font-light"
                    >
                      {performance.market}&nbsp;
                      {marketStructureTypes[
                        performance.structure_type.toLowerCase() as keyof typeof marketStructureTypes
                      ] !== "All" && (
                        <>
                          {
                            marketStructureTypes[
                              performance.structure_type.toLowerCase() as keyof typeof marketStructureTypes
                            ]
                          }
                        </>
                      )}
                    </a>
                  </td>
                  <td className="py-2 text-right align-top font-light">
                    <NumberFormat
                      value={performance.pct_growth}
                      displayType="text"
                      suffix="%"
                      decimalScale={1}
                    />
                  </td>
                  <td className="items-center py-2 text-right font-light">
                    <div className="flex flex-row">
                      <div>
                        <a
                          href={performance.browse_page_path}
                          className="text-st-darkest hover:text-sl-lighter cursor-pointer"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ArrowRightIcon className="text-st-darkest hover:text-sl-lighter ml-2 h-4 w-3" />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
