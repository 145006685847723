import { LoanDetails } from "components/proformas/financials/rentaltab/";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { calculatePurchaseFromProfit, useFinancials } from "state/proformas";

export const RentalCashIn = () => {
  const { financials, updateCalcSheet, updateBRRRstatus } = useFinancials();
  const [purchasePrice, setPurchasePrice] = useState(financials?.calcsheet.purchase_price);
  const [rehabCostsHold, setRehabCostsHold] = useState(financials?.calcsheet.rehab_costs_hold);
  const [rehabCostsFlip, setRehabCostsFlip] = useState(financials?.calcsheet.rehab_costs_flip);
  const loans = financials?.calcsheet.loans.filter(
    (l) => l._type === "rental" && l.name.includes(financials?.calcsheet.is_brrr ? "brrr" : "hold"),
  );

  if (!financials) return null;

  const handleIsBRRRChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateBRRRstatus(e.target.value === "true");
  };

  const onPurchasePriceBlur = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d-]+/g, ""));
    if (val) {
      const pp = calculatePurchaseFromProfit(financials, undefined, val);
      if (pp.target_profit) {
        updateCalcSheet({ purchase_price: val });
        setPurchasePrice(val);
      }
    }
  };

  const onRehabCostsHoldBlur = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d-]+/g, ""));
    if (val) {
      updateCalcSheet({ rehab_costs_hold: val });
      setRehabCostsHold(val);
    }
  };

  const onRehabCostsFlipBlur = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d-]+/g, ""));
    if (val) {
      updateCalcSheet({ rehab_costs_flip: val });
      setRehabCostsFlip(val);
    }
  };

  return (
    <div className="mb-4 w-full">
      <div
        className="text-md flex flex-row justify-items-stretch gap-2 pb-2 leading-9"
        onChange={handleIsBRRRChange}
      >
        <div className="w-full text-left">
          <input
            className="mr-1 border text-sl-lighter outline-0 indeterminate:bg-gray-300 focus:ring-0"
            type="radio"
            name="isBRRR"
            value="false"
            defaultChecked={!financials?.calcsheet.is_brrr}
          />
          Buy and Hold
        </div>
        <div className="w-full pl-4 text-left">
          <input
            className="mr-1 border text-sl-lighter outline-0 indeterminate:bg-gray-300 focus:ring-0"
            type="radio"
            name="isBRRR"
            value="true"
            defaultChecked={financials?.calcsheet.is_brrr === true}
          />
          Rehab and Refi
        </div>
      </div>

      <div className="text-md flex flex-row justify-items-stretch gap-2 py-2 leading-6">
        <div className="basis-3/5 whitespace-nowrap font-medium">Purchase Price</div>
        <div className="w-full basis-2/5">
          <NumberFormat
            className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
            value={purchasePrice}
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
            onBlur={onPurchasePriceBlur}
            onKeyUp={(e: React.KeyboardEvent) => {
              if (e.key === "Enter") {
                onPurchasePriceBlur(e);
              }
            }}
          />
        </div>
      </div>

      <div className="text-md flex flex-row justify-items-stretch gap-2 py-2 leading-6">
        {financials.strategy_rental === "financed_hold" ? (
          <>
            <div className="basis-3/5 whitespace-nowrap font-medium">Rent Ready Fixes</div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                value={rehabCostsHold}
                thousandSeparator={true}
                decimalScale={0}
                prefix="$ "
                onBlur={onRehabCostsHoldBlur}
                onKeyUp={(e: React.KeyboardEvent) => {
                  if (e.key === "Enter") {
                    onRehabCostsHoldBlur(e);
                  }
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="basis-3/5 whitespace-nowrap font-medium">Rehab Budget</div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                value={rehabCostsFlip}
                thousandSeparator={true}
                decimalScale={0}
                prefix="$ "
                onBlur={onRehabCostsFlipBlur}
                onKeyUp={(e: React.KeyboardEvent) => {
                  if (e.key === "Enter") {
                    onRehabCostsFlipBlur(e);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>

      {financials.strategy_rental === "financed_brrr" && (
        <div className="text-md flex flex-row justify-items-stretch gap-2 pb-1 leading-9">
          <div className="w-3/5 whitespace-nowrap font-medium">Initial Cash In</div>
          <div className="w-full pr-1 text-right">
            <NumberFormat
              value={financials?.rental.cashin_initial}
              displayType="text"
              prefix="$ "
              thousandSeparator={true}
              decimalScale={0}
            />
          </div>
        </div>
      )}

      <div className="text-md flex flex-row justify-items-stretch gap-2 pb-1 leading-9">
        {/* Chash In */}
        <div className="w-3/5 whitespace-nowrap font-medium">
          {financials.strategy_rental === "financed_brrr" ? (
            <>Net Cash In</>
          ) : (
            <>
              Cash In
              {financials.strategy_rental === "financed_hold" && (
                <div className="text-xs text-st-lightest">includes title & escrow</div>
              )}
            </>
          )}
        </div>
        <div className="w-full pr-1 text-right">
          <NumberFormat
            value={financials?.rental.cashin}
            displayType="text"
            prefix="$ "
            thousandSeparator={true}
            decimalScale={0}
          />
        </div>
      </div>

      {loans?.map((loan) => <LoanDetails key={loan.name} loan={loan} />)}
    </div>
  );
};
