import { RehabCard, RehabGuide, RehabPropertyPhotos } from "components/proformas/financials/rehabtab/";
import { isPellego } from "config";

export const RehabTab = () => {
  return (
    <div className="basis-3/3 w-full">
      <div className="text-st-lighter mb-6 hidden text-center text-sm sm:mb-8 sm:block sm:text-base">
        The budget you enter in the yellow box will be used in the resell calculator. The example guides are
        computer generated by {isPellego ? "Pellego" : "Lotside"}.
      </div>
      <div className="flex w-full flex-col sm:flex-row sm:gap-2">
        <div className="basis-1/1 sm:basis-2/3 sm:pr-4">
          <RehabPropertyPhotos />
        </div>
        <div className="basis-1/1 mt-4 p-4 pb-0 sm:mt-0 sm:basis-1/3 sm:p-0">
          <RehabCard />
          <RehabGuide />
        </div>
      </div>
    </div>
  );
};
