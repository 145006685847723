import { mdiAutorenew, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { WithSignIn } from "components";
import {
  buildLocationsFromSearchBoxOption,
  Labels,
  RecentSearchResultItem,
  SavedSearchResultItem,
  SearchResultItem,
} from "components/SearchBox";
import { isPellego } from "config";
import { TypeAheadItemType, useTypeAhead } from "helpers";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-storage-complete";
import { useUserSession } from "state";
import { ISearchParamLocations, useLocationSearch } from "state/browse";
import { SaveSearchType, useSavedSearchList } from "state/proformas";
import { useOnClickOutside } from "usehooks-ts";

const browseURL = (locations: ISearchParamLocations) => {
  const nameValue: string[] = [];

  Object.entries(locations).forEach(([key, values]) => {
    values.forEach((value: any) => {
      nameValue.push(`${key}=${value}`);
    });
  });

  return "/browse?" + nameValue.join("&");
};

export const SearchBoxBrowseMobile = ({ handleSearchChange }: { handleSearchChange: CallableFunction }) => {
  const [inputText, setInputText] = useState("");
  const [typeaheadResult, setTypeaheadResult] = useState<TypeAheadItemType[]>([]);
  const [selectedItem, setSelectedItem] = useState<number | undefined>(undefined);
  const { locationSearchItems, setLocationSearchItems } = useLocationSearch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const location = useLocation();
  const currentUser = useUserSession();
  const isLogged = currentUser.isLogged;
  const [lsSearches] = useLocalStorage<any[]>("searches", [], {
    prefix: isPellego ? "pellego" : "lotside",
  });
  const recentSearches = lsSearches?.filter((lsSearch) => lsSearch.parameters.isRecent === true);
  const { savedSearchFilters: savedSearches } = useSavedSearchList(currentUser?.session?.user_id);

  const currentStateId = locationSearchItems[0]?.state_id || null;

  useEffect(() => {
    if (location.pathname !== "/browse" && locationSearchItems.length > 0) {
      setLocationSearchItems([]);
    }
  }, [location, locationSearchItems.length, setLocationSearchItems]);

  const { results, isLoading } = useTypeAhead(inputText);

  useEffect(() => {
    if (inputText.length > 1) {
      if (results) {
        const notParcelResults = results.filter((item) => item.type !== "parcel");
        // Order not parcel results to have the ones with the stae_id === currentStateId first and in alphabetical order
        const notParcelResultsWithCurrentStateId = notParcelResults.filter(
          (item) => item.state_id === currentStateId,
        );
        const notParcelResultsWithoutCurrentStateId = notParcelResults.filter(
          (item) => item.state_id !== currentStateId,
        );

        const parcelResults = results.filter((item) => item.type === "parcel");
        // Order parcel results to have the ones with the stae_id === currentStateId first
        const parcelResultsWithCurrentStateId = parcelResults.filter(
          (item) => item.state_id === currentStateId,
        );
        const parcelResultsWithoutCurrentStateId = parcelResults.filter(
          (item) => item.state_id !== currentStateId,
        );
        setTypeaheadResult([
          ...notParcelResultsWithCurrentStateId,
          ...notParcelResultsWithoutCurrentStateId,
          ...parcelResultsWithCurrentStateId,
          ...parcelResultsWithoutCurrentStateId,
        ]);
      }
    }
  }, [inputText, results]);

  const firstNonParcelItem = typeaheadResult.find((item) => item.type !== "parcel");
  const firstParcelItem = typeaheadResult.find((item) => item.type === "parcel");

  const resetStatus = (closePanel: boolean = true) => {
    setInputText("");
    setTypeaheadResult([]);
    setSelectedItem(undefined);
    if (closePanel) {
      handleSearchChange();
    }
  };

  useOnClickOutside(ref, () => resetStatus());

  const onInputTextChange = (e: any) => {
    if (e.target.value.length > 0) {
      setInputText(e.target.value);
    } else {
      resetStatus(false);
    }
  };

  const updateLocationsAndNavigation = (items: TypeAheadItemType[]) => {
    const [locations, newLocationSearchItems] = buildLocationsFromSearchBoxOption(items);

    setLocationSearchItems(newLocationSearchItems);
    navigate(browseURL(locations));
  };

  const selectItem = (item: TypeAheadItemType, add: boolean) => {
    resetStatus();

    if (item.type === "parcel") {
      setLocationSearchItems([]);
      navigate("/proformas/address/" + item.address_for_url?.replace(/ /g, "-") + "?source=lookup");
      return;
    }

    if (locationSearchItems.some((location: TypeAheadItemType) => location.id === item.id)) {
      return; // already added
    }

    const items = add ? [...locationSearchItems, item] : [item];
    updateLocationsAndNavigation(items);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        resetStatus();
        break;
      case "ArrowUp":
        e.preventDefault();
        if (typeaheadResult.length > 0 && selectedItem !== undefined && selectedItem > 0) {
          setSelectedItem(selectedItem - 1);
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        const nextIdx = selectedItem == null ? 0 : selectedItem + 1;
        if (typeaheadResult.length > 0 && nextIdx < typeaheadResult.length) {
          setSelectedItem(nextIdx);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (selectedItem != null) {
          selectItem(
            typeaheadResult[selectedItem],
            currentStateId === typeaheadResult[selectedItem].state_id,
          );
        }
        break;
    }
  };

  const onRemoveItem = (itemId: any) => {
    resetStatus();
    const newLocationSearchItems = locationSearchItems.filter((i) => i.id !== itemId);
    updateLocationsAndNavigation(newLocationSearchItems);
  };

  return (
    <div
      className="absolute right-0.5 top-0.5 z-[1010] h-[calc(100dvh-7rem)] w-[calc(100dvw-0.25rem)] overflow-hidden rounded-xl border border-st-soft bg-white"
      onKeyDown={handleKeyDown}
      ref={ref}
    >
      <div className="mx-1 mt-2">
        <Labels locations={locationSearchItems} onRemoveItem={onRemoveItem} />
      </div>
      <div className={`m-2 mt-1 ${typeaheadResult.length === 0 && "mb-2"}`}>
        <input
          type="text"
          className="h-9 w-full border border-gray-200 focus:border-gray-200"
          value={inputText}
          onChange={onInputTextChange}
          autoComplete="off"
          placeholder={locationSearchItems.length === 0 ? "" : "Enter another location"}
          autoFocus
        />
      </div>
      <button className="absolute -top-0.5 right-2 ml-1 p-2 font-bold" onClick={() => resetStatus(true)}>
        {!isLoading && <Icon path={mdiChevronRight} className="inline size-6 rounded-lg text-st-lighter" />}
        {isLoading && <Icon path={mdiAutorenew} className="mt-1 size-5 text-sl-light" spin />}
      </button>

      {typeaheadResult.length === 0 && (
        <div className="h-[calc(100%-6rem)] w-full items-center overflow-y-auto border-t-2 px-4">
          <div className="px-2 pb-1 pt-3 text-st-lightest">Recent Searches</div>
          {recentSearches?.slice(0, 3).map((search) => (
            <div className="p-1.5 pl-3 hover:bg-black hover:text-white" key={search.parameters?.url}>
              <RecentSearchResultItem search={search} resetStatus={resetStatus} />
            </div>
          ))}
          <div className="px-2 pb-1 pt-3 text-st-lightest">Saved Searches</div>
          {isLogged ? (
            <>
              {savedSearches?.map((search: SaveSearchType) => (
                <div className="p-1.5 pl-3 hover:bg-black hover:text-white" key={search.parameters?.url}>
                  <SavedSearchResultItem search={search} resetStatus={resetStatus} />
                </div>
              ))}
            </>
          ) : (
            <div className="p-2 pb-1 pl-3">
              <span className="inline-block whitespace-nowrap text-sl-normal">
                <WithSignIn useChildren>Log in</WithSignIn>
              </span>
              <span className="inline-blocktext-[#131618]">&nbsp;to see your saved searches.</span>
            </div>
          )}
        </div>
      )}
      {typeaheadResult.length > 0 && (
        <div className="h-[calc(100%-6rem)] w-full items-center overflow-y-auto border-t-2 px-4">
          {typeaheadResult.map((item, idx) => (
            <div key={item.id || item.parcel_id || idx}>
              {item === firstNonParcelItem && <div className="px-2 pb-1 pt-3 text-st-lightest">Regions</div>}
              {item === firstParcelItem && <div className="px-2 pb-1 pt-3 text-st-lightest">Addresses</div>}
              <SearchResultItem
                item={item}
                onSelection={selectItem}
                selected={selectedItem === idx}
                currentStateId={currentStateId}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
