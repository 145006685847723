import { EquityGraph } from "components/proformas";
import { loanTypesToNormalized } from "config";
import { cn, formatCurrency, formatDate } from "helpers";
import { useLoans, useProforma } from "state/proformas";

const buildLoans = (data: any[]) => {
  const allLoanSpecs: any[] = [];

  for (let i = data.length - 1; i >= 0; i--) {
    var loan = data[i];
    if (loan.loan_date) {
      const loanSpecs = {
        "Loan Type": loanTypesToNormalized[loan.loan_type] || "Loan",
        // Before interest rate was only calculated as a percentage when > 100
        "Interest Rate": loan.loan_interest_rate / 100 + "%",
        Amount: loan.loan_amount,
        Date: loan.loan_date,
        "Remaining Principal": loan.remaining_principal,
      };
      allLoanSpecs.push(loanSpecs);
    }
  }
  return allLoanSpecs;
};

export type LoanSpecs = {
  Amount: number;
  Date: string;
  "Interest Rate": number;
  "Loan Type": string;
  "Remaining Principal": number;
}[];

type Props = {
  parcelId: number;
};

export const Loan = ({ parcelId }: Props) => {
  const { proforma } = useProforma();
  const { loans, loading, error } = useLoans(parcelId);
  if (loading || error) return null;

  const lastSale = proforma.parcel?.listing_history?.find((listing: any) => listing.event === "Sold");
  const lastSaleDate = lastSale?.date;
  const lastSalePrice = lastSale?.price || 0;

  const loansSpecs: LoanSpecs = buildLoans(loans);

  if (loansSpecs[0]?.Amount > lastSalePrice) return null;

  return (
    <div className="w-full">
      <div className="mt-2 flex items-center text-xs font-bold uppercase text-green-900">
        Loan history from public records
      </div>
      {loansSpecs && loansSpecs.length > 0 ? (
        <div className="mt-2 w-full">
          {loansSpecs.map((loanSpec: any, index: number) => (
            <div
              key={`spec-${index}`}
              className={cn(
                "flex w-full items-center whitespace-nowrap border-b border-gray-200 px-1.5 py-2 text-sm last:border-b-0 sm:gap-3",
                !lastSaleDate || loanSpec.Date >= lastSaleDate.substr(0, 10) ? "" : "text-st-lightest",
              )}
            >
              <div className="w-2/5 truncate text-left">{loanSpec["Loan Type"]}</div>
              <div className="w-1/5 text-right">{loanSpec["Interest Rate"]}</div>
              <div className="w-1/5 text-right">{formatCurrency(loanSpec.Amount, 0)}</div>
              <div className="w-1/5 text-right">{formatDate(loanSpec.Date)}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-2 w-full text-sm font-light">No records available.</div>
      )}
      <EquityGraph loansSpecs={loansSpecs} />
    </div>
  );
};
