import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { WithSignIn } from "components";
import { RecentSearchBrowseCard, RecentSearchPropertyCard, SavedSearchesLinks } from "components/home";
import { SearchBoxHome } from "components/SearchBox";
import { isPellego } from "config";
import { cn } from "helpers";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocalStorage } from "react-storage-complete";
import { useUserSession } from "state";
import { useSavedSearchList } from "state/proformas";
import { Saved } from "./Saved";

export const Home = () => {
  const currentUser = useUserSession();
  const [lsSearches] = useLocalStorage<any[]>("searches", [], {
    prefix: isPellego ? "pellego" : "lotside",
  });
  const recentSearches = lsSearches?.filter((lsSearch) => lsSearch.parameters.isRecent === true);
  const { loading, savedSearchFilters } = useSavedSearchList(currentUser?.session?.user_id);

  const recentSearchesCount = recentSearches?.length || 0;
  const recentSearchesToShow = currentUser.isLogged ? 2 : 3;
  const isLogged = currentUser.isLogged;
  const showSearchesSection =
    (isLogged && recentSearchesCount >= 2) || (!isLogged && recentSearchesCount >= 3);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollBrowseCardsRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<number | null>(null);
  const handleBrowseCardsScroll = () => {
    setIsScrolling(true);
    if (scrollTimeoutRef.current !== null) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, 300);
  };

  const slideLeft = () => {
    if (scrollBrowseCardsRef.current) scrollBrowseCardsRef.current.scrollLeft -= 480;
  };
  const slideRight = () => {
    if (scrollBrowseCardsRef.current) scrollBrowseCardsRef.current.scrollLeft += 480;
  };

  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  };

  return (
    <div
      className={cn(
        "mx-auto flex w-full flex-col items-center pb-10",
        isLogged ? "bg-white" : "bg-[#f7faff]",
      )}
    >
      <section
        id="searchbox"
        className={cn("flex w-full bg-[#f7faff] px-4 py-20", !showSearchesSection && "pb-28 pt-32")}
      >
        <article className="mx-auto flex w-full max-w-7xl flex-col items-center gap-4 text-st-normal">
          <div className="text-3xl">Welcome to {isPellego ? "Pellego" : "Lotside"}!</div>
          <div className="mb-4 w-full max-w-md px-1 text-center text-[20px] font-extralight sm:text-[22px]">
            Search a region to find deals or look up
            <br />a specific address to analyze comps
          </div>
          <div className={`w-full ${isMobile ? "max-w-[320px]" : "max-w-[428px]"} px-1 sm:mx-2 sm:px-0`}>
            <SearchBoxHome />
          </div>
        </article>
      </section>
      {showSearchesSection && (
        <section
          id="searches"
          className={cn("flex w-full flex-col bg-[#f7faff] px-4", isLogged ? "py-16" : "py-0")}
        >
          <div className="mx-auto w-full max-w-5xl pb-6 pt-4 text-left font-bold">Recent Searches</div>
          <article className="mx-auto flex w-full max-w-5xl flex-col items-start align-top text-st-normal sm:justify-center lg:flex-row">
            <div
              className={cn(
                "relative flex w-full max-w-full flex-row overflow-x-auto",
                isLogged && "lg:max-w-2/3 md:overflow-hidden lg:w-2/3",
              )}
            >
              {!isScrolling && recentSearchesToShow > 1 && (
                <div className={cn("flex", isLogged ? "md:hidden" : "lg:hidden")}>
                  {scrollBrowseCardsRef.current && scrollBrowseCardsRef.current.scrollLeft > 2 && (
                    <ChevronLeftIcon
                      className="absolute left-2 top-[calc(50%-1.25rem)] z-[10000] size-8 cursor-pointer rounded-full bg-slate-500 text-white opacity-60"
                      onClick={slideLeft}
                    />
                  )}
                  {scrollBrowseCardsRef.current &&
                    scrollBrowseCardsRef.current.scrollLeft <=
                      scrollBrowseCardsRef.current.scrollWidth -
                        scrollBrowseCardsRef.current.offsetWidth -
                        1 && (
                      <ChevronRightIcon
                        className="absolute right-2 top-[calc(50%-1.25rem)] z-[10000] size-8 cursor-pointer rounded-full bg-slate-500 text-white opacity-60"
                        onClick={slideRight}
                      />
                    )}
                </div>
              )}
              <div
                className={cn(
                  "mb-4 flex w-full snap-x snap-mandatory flex-row justify-evenly overflow-x-auto scroll-smooth sm:gap-2",
                  isLogged ? "md:max-w-2/3 md:overflow-hidden" : "md:max-w-full",
                )}
                ref={scrollBrowseCardsRef}
                onScrollCapture={(e) => {
                  recentSearchesToShow > 1 && handleBrowseCardsScroll();
                }}
              >
                {recentSearches?.slice(0, recentSearchesToShow).map((search) => (
                  <div key={search.id}>
                    {search.parameters.url.includes("address" || "listing-number") && (
                      <RecentSearchPropertyCard search={search} />
                    )}
                    {search.parameters.url.includes("browse") && <RecentSearchBrowseCard search={search} />}
                  </div>
                ))}
              </div>
            </div>
            {currentUser.isLogged && (
              <div className="mb-4 h-56 w-full bg-transparent md:w-1/3">
                <SavedSearchesLinks savedSearches={!loading && savedSearchFilters} />
              </div>
            )}
          </article>
        </section>
      )}
      <section
        id="properties"
        className={cn("flex w-full px-4 py-16", isLogged && "bg-white", !isLogged && "flex-col bg-[#f7faff]")}
        ref={ref}
      >
        <article
          className={cn(
            "relative mx-auto flex w-full max-w-5xl flex-col text-st-normal",
            isLogged ? "items-center" : "items-start",
            !showSearchesSection ? "items-center justify-center" : "items-start justify-start",
          )}
        >
          {!isLogged && <div className="py-2 font-bold">Saved Properties</div>}
          <WithSignIn useChildren>
            {!isLogged && (
              <div className="text-sl-dark">
                Log in
                <span
                  className="cursor-pointer pl-1 text-st-normal"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  to see your saved properties
                </span>
              </div>
            )}
            {isLogged && (
              <>
                <div className="w-full text-center text-3xl">Saved Properties</div>
                <Saved handleScroll={handleScroll} />
              </>
            )}
          </WithSignIn>
        </article>
      </section>
    </div>
  );
};
