import { TakePhotosDown } from "components/admin";
import { appConfig } from "config";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFooter, useUserSession } from "state";

export const Admin = () => {
  const { setShowFooter } = useFooter();
  const currentUser = useUserSession();
  const navigate = useNavigate();

  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  useEffect(() => {
    if (!currentUser.isManagingAgent) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  return (
    <div className="mx-auto flex size-full max-w-7xl flex-col items-center py-4">
      <TakePhotosDown className="m-4 h-fit w-full px-4 pb-4 sm:w-[70%] sm:pb-0" />
      <iframe
        className="size-full"
        title="Admin"
        src={`${appConfig.apiBaseUrl}/admin/`}
        onLoad={() => {
          console.log("iframe loaded");
        }}
      >
        <p>Your browser does not support iframes!</p>
      </iframe>
    </div>
  );
};
