import { AsyncPaginate } from "react-select-async-paginate";
import { useRecoilValue } from "recoil";
import { listingRemarksTermsAtom } from "state/browse";

export type ListingRemarkItemType = {
  label: string;
  value: string;
};

type Props = {
  onChange: any;
  value: string[] | null;
};

export const ListingRemarksSearchBox = ({ onChange, value }: Props) => {
  const listingRemarksTerms = useRecoilValue(listingRemarksTermsAtom);

  const loadOptions = async (search: string) => {
    const retVal = { options: [] as ListingRemarkItemType[], hasMore: false };

    if (!search) return retVal;

    const searchLower = search.toLocaleLowerCase();
    const trackLetter = search[0].toLowerCase();

    const filteredOptions = listingRemarksTerms[trackLetter].filter((opt: string) =>
      opt.includes(searchLower),
    );

    retVal.options = filteredOptions.map((option: string) => ({
      value: option,
      label: option,
    }));

    return retVal;
  };

  const customStyles = {
    option: (provided: any, { isFocused }: { isFocused: boolean }) => {
      return {
        ...provided,
        borderBottom: "1px dotted #ccc",
        backgroundColor: isFocused ? "#ccc" : undefined,
        padding: "2px 10px",
        textTransform: "capitalize",
      };
    },
    control: (styles: any) => ({
      ...styles,
      textTransform: "capitalize",
    }),
  };

  return (
    <div className="listingremarks w-full">
      <AsyncPaginate
        value={value ? value.map((v: any) => ({ value: v, label: v })) : []}
        isMulti
        debounceTimeout={300}
        loadOptions={loadOptions}
        onChange={onChange}
        styles={customStyles}
        placeholder={<span className="text-st-lightest">Type to Search remarks</span>}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length < 1 ? "Start typing the first characters of the keyword" : "No results found"
        }
      />
    </div>
  );
};
