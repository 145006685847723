import { InformationCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { buildSummaryProperty } from "components/proformas/";
import { DetailsList } from "components/proformas/details";
import { useCustomStructure, useProforma } from "state/proformas";

type Props = {
  isOpen: boolean;
  closeModal: any;
};

export const RehabInfoModal = ({ isOpen, closeModal }: Props) => {
  const { proforma } = useProforma();
  const { customStructure } = useCustomStructure(proforma) || undefined;
  const { propertySpecs, propertySpecsMore } = buildSummaryProperty(proforma, customStructure);
  const propertySummary = [...propertySpecs, ...propertySpecsMore];
  const structureData = propertySummary.filter((item: any) => item.section === "structure");
  const parcelData = [
    ...propertySummary.filter((item: any) => item.section === "parcel"),
    ...propertySummary.filter((item: any) => item.section === "geography"),
  ];

  return (
    <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block h-full max-h-fit w-full max-w-xl transform overflow-hidden rounded-md px-0 pt-14 text-left align-middle shadow-xl transition-all">
        <div className="flex h-full max-h-[90%] w-full justify-center">
          <div className="w-full max-w-5xl rounded-md bg-white shadow-lg">
            <div className="flex h-full flex-col">
              <header className="flex w-full flex-row items-center">
                <div className="text-md flex basis-4/5 items-center pl-6 font-medium text-st-normal">
                  <InformationCircleIcon className="mr-2 mt-1 w-6" aria-hidden="true" />
                  Structure & Parcel Information
                </div>
                <div className="justify-right basis-1/5 p-4 pr-4 text-right">
                  <button
                    type="button"
                    className="rounded-md pb-1 pl-1 text-sm font-medium text-st-lightest hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </header>
              <main className="flex flex-grow flex-col bg-gray-50 p-6">
                <div className="flex w-full flex-col gap-6 sm:flex-row">
                  <div className="flex w-full flex-col pb-0 sm:flex-col">
                    <DetailsList title="Structure" data={structureData} />
                  </div>

                  <div className="flex w-full flex-col pb-0 sm:flex-col">
                    <DetailsList title="Parcel" data={parcelData} />
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
