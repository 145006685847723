import { XIcon } from "@heroicons/react/solid";
import { Footer } from "components";
import { LocationPriceType, MarketResearchGraph, SearchPerformingMarkets } from "components/markets";
import { titleCase, TypeAheadItemType } from "helpers";
import { fetchAllLocationPriceData } from "pages";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useSignInSignUpModal } from "state";
import { locationSearchItemsAtom } from "state/browse";
import { LocationPriceDataEntryType, useMarketDataSearchPerformers } from "state/market";

const getLocationPrice = (locationSearchItems: TypeAheadItemType[]): LocationPriceType[] => {
  const locationPrice: LocationPriceType[] = [];

  locationSearchItems?.forEach((item) =>
    locationPrice.push({
      id: `${item.type}/${item.id}`,
      loc_type: item.type,
      loc_id: Number(item.id),
      loc_name: titleCase(item.name),
    }),
  );
  return locationPrice;
};

export const NonMLSPanel = () => {
  const { openNonMLSSignUpModal } = useSignInSignUpModal();

  const [isHidden, setIsHidden] = useState(false);
  const handleClose = () => {
    setIsHidden(true);
  };

  const locationSearchItems = useRecoilValue(locationSearchItemsAtom);
  const [locationPriceItems, setLocationPriceItems] = useState<LocationPriceType[]>([]);

  const locationPrice = useMemo(() => getLocationPrice(locationSearchItems), [locationSearchItems]);

  const data = useMarketDataSearchPerformers();
  const performersByMarketAvailable = data.bottom_performers.length > 0 && data.top_performers.length > 0;

  useEffect(() => {
    fetchAllLocationPriceData(locationPrice).then((marketData) => {
      const newLocationPriceItems = marketData.map((data, index) => ({
        ...locationPrice[index],
        //NORMALIZE DATA to 1st of the month
        data: data.map((d: LocationPriceDataEntryType) => ({
          ...d,
          month: d.month.substring(0, 7) + "-01T00:00:00",
        })),
      }));
      setLocationPriceItems(newLocationPriceItems);
    });
  }, [locationPrice]);

  return (
    <div className="relative h-full w-full flex-1 overflow-y-scroll">
      <div>
        <div
          className={`relative mx-2 flex h-36 items-center bg-yellow-100 p-4 sm:m-2 sm:h-40 sm:flex-row ${
            isHidden ? "hidden" : ""
          }`}
        >
          <div className="text-md w-full text-center align-middle sm:text-lg">
            <div className="text-st-dark mb-2">We don't have MLS data for this region yet.</div>
            <div className="text-st-dark mb-2">
              <span className="text-sl-normal cursor-pointer underline" onClick={openNonMLSSignUpModal}>
                Click here
              </span>{" "}
              to sign up for updates.
            </div>
          </div>
          <button className="text-st-dark hover:text-st-light" onClick={handleClose}>
            <XIcon className="absolute right-1 top-1 z-50 mr-1 mt-1 h-5 w-5" />
          </button>
        </div>

        <div className="my-5 w-full flex-col items-center justify-center px-4 sm:my-10 sm:flex">
          <h1 className="mb-6 text-left text-xl font-medium sm:text-3xl">Market Insights</h1>

          {performersByMarketAvailable && (
            <div className="mb-6 flex w-full flex-row flex-wrap justify-around">
              <SearchPerformingMarkets data={data} order="top" />
              <SearchPerformingMarkets data={data} order="bottom" />
            </div>
          )}

          <div className="mt-4 w-full px-0">
            <MarketResearchGraph
              marketResearchData={locationPriceItems}
              valueName="median_value_per_sqft"
              title="Price per Square Foot"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
