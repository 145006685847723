import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {
  CustomStructureType,
  ProformaType,
  useCustomStructure,
  useFinancials,
  useProforma,
} from "state/proformas";

const getCustumStructureChanges = (profoma: ProformaType, customStructure: CustomStructureType) => {
  const customUpdates: any = [];

  const keyToName = {
    bedrooms: "Bedrooms",
    bathrooms: "Bathrooms",
    parking_count: "Covered Parking",
    square_feet_basement_unfinished: "Unfinished Basement Sqft",
    square_feet_finished: "Finished Sqft",
    square_feet_garage: "Garage SqFt",
  };

  Object.entries(customStructure).forEach(([key, value]) => {
    const name = keyToName[key as keyof typeof keyToName];
    if (value && name) {
      customUpdates.push({ key, value, name });
    }
  });

  return customUpdates;
};

export const RehabCard = () => {
  const { financials, updateCalcSheet } = useFinancials();
  const { proforma } = useProforma();
  const { customStructure } = useCustomStructure(proforma);

  const customUpdates = getCustumStructureChanges(proforma, customStructure);
  const ssf = proforma.parcel._characteristics.square_feet_finished;
  const [mainPpsf, setMainPpsf] = useState(0);

  useEffect(() => {
    setMainPpsf((financials?.calcsheet.rehab_costs_flip || 0) / ssf);
  }, [financials?.calcsheet.rehab_costs_flip, ssf]);

  const onMainRehabTotalChange = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ""));
    updateCalcSheet({ rehab_costs_flip: val });
  };

  const onMainPpsflChange = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d.]+/g, ""));
    setMainPpsf(val);
    if (Math.abs(val - mainPpsf) > 0.01) {
      updateCalcSheet({ rehab_costs_flip: val * ssf });
    }
  };

  return (
    <div className="rounded-lg border border-gray-300 bg-gray-50 p-6 shadow-lg">
      {/* card header */}

      {customUpdates.length > 0 && (
        <>
          <div className="mb-1 flex flex-row justify-items-stretch text-sm">Structure Changes</div>
          <div className="mb-4 text-sm">
            <ul>
              {customUpdates.map(({ key, value, name }: { key: string; value: number; name: string }) => (
                <li key={key} className="ml-4">
                  {name}: {proforma.parcel._characteristics[key] || 0} → {value}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-full basis-3/5 whitespace-nowrap font-bold">Rehab Budget</div>
        <div className="w-full basis-2/5">
          <NumberFormat
            className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
            value={financials?.calcsheet.rehab_costs_flip}
            prefix="$ "
            thousandSeparator={true}
            decimalScale={0}
            onBlur={onMainRehabTotalChange}
            onKeyUp={(e: React.KeyboardEvent) => {
              if (e.key === "Enter") {
                onMainRehabTotalChange(e);
              }
            }}
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-full basis-3/5 whitespace-nowrap pl-2 text-st-light">Per Square Foot</div>
        <div className="w-full basis-2/5">
          <NumberFormat
            className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
            value={mainPpsf}
            prefix="$ "
            decimalScale={2}
            thousandSeparator={true}
            onBlur={onMainPpsflChange}
            onKeyUp={(e: React.KeyboardEvent) => {
              if (e.key === "Enter") {
                onMainPpsflChange(e);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
