import { mdiAutorenew, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { SearchResultItem } from "components/SearchBox/SearchResultItem";
import { TypeAheadItemType, useTypeAhead } from "helpers";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

type Props = {
  serviceAreas: any[];
  updateServiceAreas: (serviceAreas: any[]) => void;
};

export const EditAreasOfService = ({ serviceAreas, updateServiceAreas }: Props) => {
  const [inputText, setInputText] = useState("");
  const [typeaheadResult, setTypeaheadResult] = useState<TypeAheadItemType[]>([]);
  const ref = useRef(null);

  const resetStatus = () => {
    setInputText("");
    setTypeaheadResult([]);
  };

  const { results, isLoading } = useTypeAhead(inputText, ["city", "county", "neighborhood", "zip_code"]);

  useEffect(() => {
    if (inputText.length > 1) {
      if (results) {
        setTypeaheadResult(results);
      }
    }
  }, [results, inputText]);

  useOnClickOutside(ref, () => resetStatus());

  const onInputTextChange = (e: any) => {
    if (e.target.value.length > 0) {
      setInputText(e.target.value);
    } else {
      resetStatus();
    }
  };

  const removeServiceArea = (area: any) => {
    const newSerciceAreas = serviceAreas.filter((sa) => {
      return sa.type !== area.type || sa.id !== area.id;
    });
    updateServiceAreas(newSerciceAreas);
  };

  const selectItem = (item: TypeAheadItemType) => {
    const newSerciceAreas = serviceAreas.filter((sa) => {
      return sa.type !== item.type || sa.id !== item.id;
    });
    newSerciceAreas.push({ id: item.id, type: item.type, name: item.name });
    updateServiceAreas(newSerciceAreas);
    resetStatus();
  };

  return (
    <div className="w-full font-light">
      <div className="relative">
        <input
          type="text"
          className="my-2 h-9 w-full border border-gray-200 pr-6 text-sm"
          value={inputText}
          onChange={onInputTextChange}
          autoComplete="off"
          placeholder="Enter city, county, neighborhood or zip code"
          autoFocus
        />
        <button className="absolute right-1 top-3.5 font-bold" onClick={() => resetStatus()}>
          {!isLoading && inputText.length > 0 && (
            <Icon
              path={mdiClose}
              className="ml-1 inline h-4 w-4 rounded-lg text-st-lightest hover:bg-gray-300"
            />
          )}
          {isLoading && <Icon path={mdiAutorenew} className="ml-1 mt-1 h-4 w-4 text-sl-light" spin />}
        </button>

        {typeaheadResult.length > 0 && (
          <div
            ref={ref}
            className="absolute left-0 top-12 z-10 max-h-80 w-full items-center overflow-y-scroll border border-blue-400 bg-gray-100 p-1"
          >
            {typeaheadResult.map((item, idx) => (
              <SearchResultItem
                key={item.id || item.parcel_id || idx}
                item={item}
                onSelection={selectItem}
                selected={false}
                currentStateId={null}
              />
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-wrap">
        {serviceAreas.length === 0 && <div className="text-sm text-st-light">No service areas selected</div>}
        {serviceAreas.map((area) => (
          <div
            key={`${area.type}-${area.id}`}
            className="relative mb-1 mr-1 whitespace-nowrap rounded border bg-gray-300 py-1 pl-2 pr-6 capitalize leading-4"
          >
            <span className="font-thin">{area.type.replace("_", " ")}: </span>
            <span className="font-normal">{area.name}</span>
            <button className="absolute right-1 top-0.5" onClick={() => removeServiceArea(area)}>
              <Icon
                path={mdiClose}
                className="ml-1 inline h-4 w-4 rounded-lg text-st-lightest hover:bg-gray-200"
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
