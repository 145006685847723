import { ChevronLeftIcon, ChevronRightIcon, XIcon } from "@heroicons/react/solid";
import { ModalWrapper, ShowMore } from "components";
import { ParcelHistory } from "components/proformas";
import { AdjustedValues, LeafletCompMapService } from "components/proformas/comparables/";
import { StreetView } from "components/proformas/details";
import { getListingDataByName, hasValue } from "helpers";
import Pluralize from "pluralize";
import { useEffect, useRef } from "react";
import NumberFormat from "react-number-format";
import { atom, useRecoilState } from "recoil";
import { useParcelHistory, useProforma } from "state/proformas";
import { CompStatsType, RentalComparableType } from "state/proformas/comparables";

export const rentalCompModalShowIdxAtom = atom<number | null>({
  key: "rentalCompModalShowIdxAtom",
  default: null,
});

type Props = {
  isOpen: boolean;
  comparables: RentalComparableType[];
  compStats: CompStatsType[];
};

export const RentalCompModal = ({ isOpen, comparables, compStats }: Props) => {
  const { proforma } = useProforma();
  const [compModalShowIdx, setCompModalShowIdx] = useRecoilState(rentalCompModalShowIdxAtom);
  const compIdx = compModalShowIdx || 0;
  const comparable = comparables[comparables.findIndex((comp) => comp._idx === compIdx)];
  const parcelHistory = useParcelHistory(comparable.parcel._id);
  const isMissingThumbnail =
    !comparable.parcel?.thumbnail_url ||
    comparable.parcel?.thumbnail_url === "https://cdn.davinci.pellego.com/static/images/core/missing.png";
  const closeModal = () => setCompModalShowIdx(null);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let compMapService: LeafletCompMapService | null = null;
    if (ref.current) {
      compMapService = new LeafletCompMapService([comparable], proforma);
      compMapService.createMap(ref.current);
    }
    return () => {
      if (compMapService) {
        compMapService.map?.off();
        compMapService.map?.remove();
      }
    };
  }, [comparable, proforma]);

  return (
    <ModalWrapper className="mt-14" isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block h-full w-full">
        <div className="inline-block h-full w-full max-w-5xl transform rounded-md px-0 text-left align-middle transition-all">
          <div className="flex w-full justify-center overflow-hidden">
            <div className="h-full w-full max-w-5xl rounded-md bg-gray-50 shadow-lg">
              <div className="relative flex w-full flex-row items-center justify-items-end border-b border-gray-200 bg-white p-2 align-top font-medium sm:p-4 sm:align-middle">
                {/* Navigation */}
                <div className="text-md flex w-full flex-row items-center justify-center gap-6 sm:gap-20">
                  <button
                    type="button"
                    className="h-8 w-8 cursor-pointer text-st-lightest"
                    onClick={() => setCompModalShowIdx(compIdx > 0 ? compIdx - 1 : comparables.length - 1)}
                  >
                    <ChevronLeftIcon />
                  </button>
                  <div className="text-md">
                    Comp {String.fromCharCode("A".charCodeAt(0) + comparable._idx)}
                  </div>
                  <button
                    type="button"
                    className="h-8 w-8 cursor-pointer text-st-lightest"
                    onClick={() => setCompModalShowIdx((compIdx + 1) % comparables.length)}
                  >
                    <ChevronRightIcon />
                  </button>
                </div>
                {/* Close button */}
                <div className="absolute right-3">
                  <button
                    type="button"
                    className="rounded-md text-sm font-medium text-st-lightest hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="my-0 flex flex-col">
                <div className="flex w-full flex-col px-0 pb-4 sm:flex-row">
                  <div className="relative w-screen overflow-auto">
                    <div className="styled-scrollbars relative flex w-full snap-mandatory gap-4 overflow-x-auto">
                      {(comparable.photos || []).map((photo: any) => (
                        <div
                          key={photo._id}
                          className="shrink-0 snap-center snap-normal first:pl-4 last:pr-8"
                        >
                          <img
                            className="w-100 sm:w-160 h-60 shrink-0 rounded bg-white object-cover shadow-xl sm:h-96"
                            src={photo.photo_url}
                            alt="Property Img"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Property Address for Desktop view */}
                <div className="flex w-full flex-col px-4">
                  <div className="flex w-full flex-col text-lg font-medium sm:px-4 md:flex-row">
                    <div className="whitespace-nowrap">{comparable.parcel?._characteristics.address}</div>
                    {/* Listing source and number */}
                    {proforma.listing?._on_market &&
                      proforma.listing_number &&
                      proforma.listing_number.length > 0 && (
                        <div className="flex w-full flex-row text-sm md:ml-1 md:text-lg">
                          (
                          {proforma.listing.listing_source && (
                            <span className="max-w-52 truncate sm:max-w-full">
                              {getListingDataByName(proforma.listing?.listing_source)?.abbr || "MLS"}
                            </span>
                          )}
                          <span className="ml-1">#{proforma.listing_number}</span>)
                        </div>
                      )}
                  </div>
                </div>

                <div className="flex w-full flex-col px-4 pb-4 sm:flex-row">
                  <div className="basis-full text-lg font-medium sm:basis-1/2 sm:px-4">
                    <div className="mb-4 font-light leading-3">
                      <span className="font-lignt text-sm text-st-light">
                        ({comparable.parcel?._characteristics.bedrooms} Bed -{" "}
                        {Pluralize("Bath", comparable.parcel?._characteristics.bathrooms, true)} -{" "}
                        <NumberFormat
                          value={comparable.parcel?._characteristics.square_feet_finished}
                          displayType="text"
                          thousandSeparator={true}
                        />{" "}
                        Fin/SqFt.)
                      </span>
                    </div>

                    <div className="flex gap-4 pb-2">
                      <div className="basis-1/2">
                        <div className="flex items-center pt-4 text-xs font-bold uppercase text-green-900">
                          Location
                        </div>
                      </div>
                      <div className="basis-1/2">
                        <div className="flex items-center pt-4 text-xs font-bold uppercase text-green-900">
                          Subject Property
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 flex gap-4">
                      <div className="w-full basis-1/2 rounded-lg border">
                        <div ref={ref} className="relative flex h-36 w-full items-end rounded-md" />
                      </div>
                      <div className="basis-1/2">
                        {isMissingThumbnail ? (
                          <div>
                            <StreetView
                              otherClasses="flex cursor-pointer items-end rounded-sm bg-blue-300"
                              sizeClasses="h-36 w-full"
                              parcel={comparable.parcel}
                              titles={false}
                            />
                          </div>
                        ) : (
                          <div className="shrink-0 snap-center snap-normal">
                            <div
                              className="bg-[background-size: 100% 100%;] relative flex h-36 w-full items-end rounded-md bg-cover bg-center bg-no-repeat"
                              style={{ backgroundImage: `url('${proforma.thumbnail_url}')` }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center pt-4 text-xs font-bold uppercase text-green-900">
                      Listing remarks
                    </div>
                    <ShowMore clampLines={4} className="mt-2 text-left text-sm font-light">
                      {comparable.parcel._characteristics.listing_remarks}
                    </ShowMore>
                    <div className="mb-8 mt-4 text-xs font-light">
                      Listed by {proforma.listing.listing_company && <>{proforma.listing.listing_company}</>}
                      {proforma.listing.selling_company && (
                        <>
                          <span>. Sold by </span> {proforma.listing.selling_company}
                        </>
                      )}
                    </div>

                    {!parcelHistory.loading &&
                      !parcelHistory.error &&
                      parcelHistory.data &&
                      parcelHistory.data.length > 0 && (
                        <>
                          <ParcelHistory history={parcelHistory.data} sinceListed disablePhotosModal />
                        </>
                      )}
                  </div>
                  <div className="justify-right basis-full sm:basis-1/2 sm:px-4 sm:pt-3">
                    {/* Property Address for Desktop view */}
                    <div className="flex pt-3 text-xs leading-6 sm:pt-14">
                      <div className="basis-1/2 text-left">
                        <div className="flex flex-row justify-items-stretch py-0">
                          <div className="mt-2 w-2/3 text-left text-xs font-bold text-st-normal">
                            Characteristics
                          </div>
                          <div className="mt-2 w-1/3 pr-2 text-left text-xs font-bold text-st-normal">
                            Subject
                          </div>
                        </div>

                        {compStats
                          .filter((stat) => stat.key !== "view_type")
                          .map((stat) => (
                            <div
                              key={stat.key}
                              className="flex flex-row justify-items-stretch gap-2 border-b border-gray-200 py-0 pr-2"
                            >
                              <div className="w-2/3 whitespace-nowrap font-bold">{stat.name}</div>
                              <div className="w-1/3 overflow-hidden text-ellipsis text-left">
                                {stat.subjectValue}
                              </div>
                            </div>
                          ))}
                      </div>

                      <div className="basis-1/2">
                        <div className="flex flex-row justify-items-stretch py-0">
                          <div className="mt-2 w-1/2 text-left text-xs font-bold text-st-normal">Comp</div>
                          <div className="mt-2 w-1/2 text-right text-xs font-bold text-st-normal">
                            Adjustments
                          </div>
                        </div>

                        {compStats
                          .filter((stat) => stat.key !== "view_type")
                          .map((stat) => (
                            <div
                              key={stat.key}
                              className="flex flex-row justify-items-stretch gap-2 border-b border-gray-200 py-0"
                            >
                              <div
                                className={`w-1/2 overflow-hidden text-ellipsis whitespace-nowrap ${
                                  !hasValue(comparable.parcel._characteristics[stat.key])
                                    ? "text-[0.6rem] italic text-st-soft"
                                    : ""
                                }`}
                              >
                                {hasValue(comparable.parcel._characteristics[stat.key]) ? (
                                  <>
                                    {stat.format === "currency" && (
                                      <NumberFormat
                                        value={comparable.parcel._characteristics[stat.key]}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix="$ "
                                        decimalScale={0}
                                      />
                                    )}
                                    {stat.format === "number" && (
                                      <NumberFormat
                                        value={comparable.parcel._characteristics[stat.key]}
                                        displayType="text"
                                        thousandSeparator={true}
                                        decimalScale={
                                          comparable.parcel._characteristics[stat.key] % 1 !== 0 ? 2 : 0
                                        }
                                      />
                                    )}
                                    {stat.format !== "number" && stat.format !== "currency" && (
                                      <>{comparable.parcel._characteristics[stat.key]}</>
                                    )}
                                  </>
                                ) : (
                                  <>{stat.fallback}</>
                                )}
                              </div>

                              <div className="w-1/2 text-right">
                                {comparable.rental_cost_diffs[stat.compsKey ? stat.compsKey : stat.key] ? (
                                  <NumberFormat
                                    className="font-bold"
                                    value={
                                      comparable.rental_cost_diffs[stat.compsKey ? stat.compsKey : stat.key]
                                    }
                                    displayType="text"
                                    thousandSeparator={true}
                                    prefix="$ "
                                    decimalScale={0}
                                  />
                                ) : (
                                  <>-</>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>

                    <AdjustedValues
                      costDiffs={comparable.rental_cost_diffs}
                      compChars={comparable.parcel._characteristics}
                      subjectChars={proforma.parcel._characteristics}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
