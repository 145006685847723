import { mdiAutorenew, mdiClose, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { Alert, TakePhotosDownModal } from "components";
import { TakePhotosDownResultItem } from "components/admin";
import { appConfig } from "config";
import { cn, TypeAheadItemType, useFetchWrapper, useTypeAhead } from "helpers";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useAlerts, useUserSession } from "state";
import { useOnClickOutside } from "usehooks-ts";

export const TakePhotosDown = ({ className }: { className?: string }) => {
  const currentUser = useUserSession();
  const [inputText, setInputText] = useState("");
  const fetchWrapper = useFetchWrapper();
  const [confirmModal, setConfirmModal] = useState(false);
  const [typeaheadResult, setTypeaheadResult] = useState<TypeAheadItemType[]>([]);
  const [selectedItemIdx, setSelectedItemIdx] = useState<number | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<TypeAheadItemType | undefined>(undefined);
  const [showTakePhotosDownModal, setShowTakePhotosDownModal] = useState(false);
  const alertActions = useAlerts();
  const ref = useRef(null);

  const { results, isLoading } = useTypeAhead(inputText, ["parcel"]);

  useEffect(() => {
    if (inputText.length > 1) {
      if (results) {
        setTypeaheadResult(results);
      }
    }
  }, [results, inputText]);

  useEffect(() => {
    if (confirmModal === true) {
      setConfirmModal(false);
    }
  }, [confirmModal]);

  const resetStatus = (closePanel: boolean = true) => {
    setInputText("");
    setTypeaheadResult([]);
    setSelectedItemIdx(undefined);
  };

  useOnClickOutside(ref, () => resetStatus());

  const onInputTextChange = (e: any) => {
    if (e.target.value.length > 0) {
      setInputText(e.target.value);
    } else {
      resetStatus(false);
    }
  };

  const selectItem = (item: TypeAheadItemType) => {
    setSelectedItem(item);
    setShowTakePhotosDownModal(true);
  };

  const handleConfirm = (parcelId: number) => {
    return fetchWrapper
      .post(appConfig.apiEndpoint.parcelTakePhotosDown.replace(":parcel_id", parcelId.toString()))
      .then((data) => {
        alertActions.success(`Photos for parcel #${parcelId} have been taken down successfully.`);
      })
      .catch((err) => {
        alertActions.error(`Error taking down photos for parcel $${parcelId}: ${err}`);
      })
      .finally(() => {
        setShowTakePhotosDownModal(false);
        resetStatus();
      });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        resetStatus();
        break;
      case "ArrowUp":
        e.preventDefault();
        if (typeaheadResult.length > 0 && selectedItemIdx !== undefined && selectedItemIdx > 0) {
          setSelectedItemIdx(selectedItemIdx - 1);
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        const nextIdx = selectedItemIdx == null ? 0 : selectedItemIdx + 1;
        if (typeaheadResult.length > 0 && nextIdx < typeaheadResult.length) {
          setSelectedItemIdx(nextIdx);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (selectedItemIdx != null) {
          selectItem(typeaheadResult[selectedItemIdx]);
        }
        break;
    }
  };

  const isAdmin = currentUser.session?.roles.find((role) => role.name === "admin");
  if (!isAdmin) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex h-full w-full flex-col items-center justify-center gap-4">
        <Alert />
        <div className="flex h-full w-full flex-col items-center justify-center sm:flex-row">
          <div className="flex h-9 w-auto items-center whitespace-nowrap pb-4 pr-4 text-sm font-bold text-st-normal sm:pb-0">
            Take Photos Down:
          </div>
          <div
            className="relative h-full w-full pb-4 text-base text-st-darkest sm:pb-0"
            onKeyDown={handleKeyDown}
            ref={ref}
          >
            <input
              type="text"
              className={cn(
                "absolute top-0 flex h-9 w-full items-center rounded-lg border border-gray-200 bg-white pl-2 text-sm font-light text-st-lighter focus:border-gray-200 sm:justify-between sm:pl-4",
                typeaheadResult.length > 0 && "rounded-b-none",
              )}
              value={inputText}
              onChange={onInputTextChange}
              autoComplete="off"
              placeholder="Search an address..."
              autoFocus
            />
            <button className="absolute right-3 top-2 ml-1 size-5" onClick={() => resetStatus(true)}>
              <Icon
                path={isLoading ? mdiAutorenew : inputText.length === 0 ? mdiMagnify : mdiClose}
                className={isLoading ? "text-sl-light" : "text-st-lighter"}
                spin={isLoading}
              />
            </button>
            {typeaheadResult.length > 0 && (
              <div
                className={cn(
                  "absolute top-[35px] max-h-[414px] w-full items-center overflow-y-scroll rounded-b-lg border bg-white px-2",
                  isMobile && "px-0",
                )}
              >
                {typeaheadResult.map((item, idx) => (
                  <div key={item.parcel_id}>
                    <TakePhotosDownResultItem
                      item={item}
                      onSelection={() => selectItem(item)}
                      selected={selectedItemIdx === idx}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <TakePhotosDownModal
        showTakePhotosDownModal={showTakePhotosDownModal}
        setShowTakePhotosDownModal={setShowTakePhotosDownModal}
        item={selectedItem as TypeAheadItemType}
        onConfirm={handleConfirm}
      />
    </div>
  );
};
