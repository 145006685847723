export const PeriodSelector = ({
  period,
  value,
  setPeriod,
}: {
  period: number;
  value: number;
  setPeriod: (p: number) => void;
}) => {
  return (
    <span
      className={`ml-2 w-7 text-center ${
        period === value ? "border-sb-secondary border-b-4" : "cursor-pointer"
      }`}
      onClick={() => setPeriod(value)}
    >
      {value === 0 ? "Max" : `${Math.floor(value / 12)}Y`}
    </span>
  );
};
