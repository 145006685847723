import { XIcon } from "@heroicons/react/solid";
import { useState } from "react";

interface NotificationBarProps {
  type?: "information" | "warning" | "error";
  onClickMessage?: CallableFunction;
  sticky?: boolean;
  closeable?: boolean;
  children?: any;
}

export const NotificationBar = ({
  type = "information",
  onClickMessage,
  sticky = false,
  closeable = false,
  children,
}: NotificationBarProps) => {
  const backgroundColor = type === "information" ? "info" : type === "warning" ? "warning" : "danger";

  const [isHidden, setIsHidden] = useState(false);

  return (
    <div
      className={`${isHidden && "hidden"} ${
        sticky && `bg-${backgroundColor} sticky top-0 z-10 mb-2 text-base`
      } mx-2 w-full`}
      role="alert"
    >
      <div
        className={`${closeable && "flex px-3"} items-center ${
          sticky ? `p-0` : `p-3 bg-${backgroundColor} text-md`
        } text-st-dark`}
      >
        <div
          className={`flex flex-col items-center text-center ${closeable && "m-auto"} ${
            onClickMessage ? "cursor-pointer" : ""
          }`}
          onClick={() => onClickMessage && onClickMessage()}
        >
          {children}
        </div>
        {closeable && (
          <button
            type="button"
            className="inline-flex items-center justify-center bg-transparent"
            onClick={() => setIsHidden(true)}
          >
            <span className="sr-only">Close</span>
            <XIcon className={`${sticky ? "h-4 w-4" : "h-5 w-5"} text-st-dark mr-2`} />
          </button>
        )}
      </div>
    </div>
  );
};
