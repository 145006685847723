import { highlightHandlerAtom } from "components/browse";
import { PinIcon } from "components/icons";
import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useRecoilValue } from "recoil";
import { useRegisterClientModal } from "state";

type Props = {
  property: any;
  selected?: boolean;
};

export const PropertyWholesale = ({ property, selected = false }: Props) => {
  const selectedPropertyRef = useRef<HTMLDivElement>(null);
  const highlightHandler = useRecoilValue(highlightHandlerAtom);
  const { openRegisterClientModal } = useRegisterClientModal();

  const onMouseHover = (propertyId: number | null) => {
    // Highlight property on Map
    if (highlightHandler) {
      highlightHandler(propertyId);
    }
  };

  useEffect(() => {
    if (selectedPropertyRef.current && selected) {
      selectedPropertyRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selected]);

  return (
    <div
      ref={selectedPropertyRef}
      className={`${
        isMobile ? "min-w-full" : "h-76 w-80 shrink-0"
      } relative flex flex-col whitespace-nowrap rounded-lg border bg-gray-50 ${
        selected ? "border-2 border-blue-500" : "hover:border-blue-500"
      }`}
      onMouseEnter={() => onMouseHover(property.parcel_id)}
      onMouseLeave={() => onMouseHover(null)}
    >
      <PinIcon className="text-st-darkest absolute left-2 top-0 z-10 mr-2 mt-2 h-5 w-5 shadow-xl" />
      <div className="relative flex aspect-video w-full flex-shrink-0 flex-col items-center justify-center gap-2">
        <div className="text-md font-bold">Wholesale Listing</div>
        <div className="text-sm">
          Must be{" "}
          <span className="text-sl-lightest cursor-pointer" onClick={() => openRegisterClientModal()}>
            registered
          </span>{" "}
          to view
        </div>
      </div>

      <div className="text-st-normal w-full border-t-[1px] bg-white p-3">
        <div className="text-md flex items-end overflow-hidden pb-1 font-bold leading-none">
          <img
            src={`https://cdn.davinci.pellego.com/static/images/black/${property.structureType}.svg`}
            className="mr-2 h-4"
            alt={property.structureType}
          />
          {property.location}
        </div>

        <div className="text-xs">Register to view</div>
      </div>

      <div className="rounded-b-lg bg-white text-center text-[10px]">
        <div className="flex flex-row gap-1 pb-3 text-left">
          <div className="flex w-full flex-col px-2 text-sm font-light xl:px-3">
            <div className="flex flex-row whitespace-nowrap">
              <div className="">ARV</div>
              <div className="w-full text-right">Register</div>
            </div>

            <div className="flex flex-row whitespace-nowrap">
              <div className="">Price</div>
              <div className="w-full text-right">Register</div>
            </div>
          </div>
          <div className="hidden w-0.5 max-w-0.5 flex-col border-l xl:flex"></div>
          <div className="flex w-full flex-col pr-2 text-sm font-light xl:px-3">
            <div className="flex flex-row whitespace-nowrap">
              <div className="">Rent</div>
              <div className="w-full text-right">Register</div>
            </div>

            <div className="flex flex-row whitespace-nowrap">
              {/* <div className="">{property.hoa_dues > 0 ? "PITIH" : "PITI"}</div> */}
              <div className="">PITI</div>
              <div className="w-full text-right">Register</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
