import { ListItemDisclosure, Restricted } from "components/browse";
import { formatPropertyName } from "helpers";
import { useEffect, useRef } from "react";
import NumberFormat from "react-number-format";
import TimeAgo from "react-timeago";
import { useUserSession } from "state";

type Props = {
  property: any;
  selected?: boolean;
};

export const PropertyListItem = ({ property, selected = false }: Props) => {
  const selectedPropertyRef = useRef<HTMLDivElement>(null);
  const currentUser = useUserSession();
  const notClientNorAgent = !currentUser.isClient && !currentUser.isAgent;

  useEffect(() => {
    if (selectedPropertyRef.current && selected) {
      selectedPropertyRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selected]);

  return (
    <>
      <td className="flex-1 flex-col py-2">
        <div className="flex flex-col" ref={selectedPropertyRef}>
          <div className="flex flex-row items-center">
            <img
              src={`https://cdn.davinci.pellego.com/static/images/black/${property.structureType}.svg`}
              className="mx-2 h-3 w-3"
              alt={property.structureType}
            />
            <span className="w-40 overflow-hidden text-ellipsis whitespace-nowrap text-xs xl:w-60 3xl:w-full">
              {formatPropertyName(property.name)}
            </span>
          </div>
        </div>
        <ListItemDisclosure property={property} />
      </td>
      <td className="hidden whitespace-nowrap p-2 text-center text-xs xl:table-cell">
        <div className="flex flex-col">
          <TimeAgo
            date={new Date(property.listingEventDate)}
            live={false}
            formatter={(value, unit, suffix) => {
              return `${value}${unit === "month" ? "mo" : unit.slice(0, 1)} ${suffix}`;
            }}
          />
          <div className="text-st-lightest whitespace-nowrap pt-1 text-xs tracking-tighter">
            {property.listingEvent}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap p-2 text-center text-xs">
        <NumberFormat
          value={property.listingPrice}
          displayType="text"
          thousandSeparator={true}
          prefix="$ "
          decimalScale={0}
        />
      </td>
      <td className="whitespace-nowrap p-2 text-center text-xs">
        {property.no_avm_display && notClientNorAgent ? (
          <Restricted />
        ) : (
          <NumberFormat
            value={property.pellegoArv}
            displayType="text"
            thousandSeparator={true}
            prefix="$ "
            decimalScale={0}
          />
        )}
      </td>
      <td className="hidden whitespace-nowrap p-2 text-center text-xs xl:table-cell">
        <NumberFormat
          // value={property.hoa_dues > 0 ? property.piti + property.hoa_dues : property.piti}
          value={property.piti}
          displayType="text"
          thousandSeparator={true}
          prefix="$ "
          decimalScale={0}
        />
      </td>
      <td className="hidden whitespace-nowrap p-2 text-center text-xs xl:table-cell">
        <NumberFormat
          value={property.monthly_rent_hold}
          displayType="text"
          thousandSeparator={true}
          prefix="$ "
          decimalScale={0}
        />
      </td>
      <td className="whitespace-nowrap p-2 text-center text-xs">&nbsp;</td>
    </>
  );
};
