// import { Map } from "components/browse";
import { LocationMap } from "components/home";
import { cn } from "helpers";
import { Link } from "react-router-dom";
import { NewSaveSearchType } from "state/proformas";

type Props = {
  search: NewSaveSearchType;
};

export const RecentSearchBrowseCard = ({ search }: Props) => {
  return (
    <Link to={search.parameters.url} target="_blank">
      <div
        className={cn(
          "h-76 mb-4 mr-4 flex w-80 max-w-full shrink-0 snap-start snap-normal flex-col whitespace-nowrap rounded-sm border bg-white p-4 shadow-lg shadow-x-2 shadow-y-2 hover:border-blue-500",
        )}
      >
        <div className="relative aspect-video w-full flex-shrink-0">
          <div className="flex size-full items-end rounded-t-md">
            <LocationMap url={search.parameters.url} />
          </div>
        </div>
        <div className="text-st-normal h-fit w-full items-center truncate bg-white pt-4 text-sm font-bold">
          {search.parameters?.browseData?.pageTitle
            ? search.parameters?.browseData?.pageTitle
            : "(Unknown Search)"}
        </div>
      </div>
    </Link>
  );
};
