import { MarketComparableType, marketStructureTypes } from "components/markets";
import { Label } from "./Label";

type Props = {
  items: MarketComparableType[];
  onRemoveItem: CallableFunction;
};

export const Labels = ({ items, onRemoveItem }: Props) => {
  return (
    <div className={`flex w-full cursor-pointer flex-wrap items-center pt-1.5`}>
      {items.map((item, idx) => (
        <Label
          key={idx}
          label={`${items[idx].loc_name}: ${marketStructureTypes[item.structureType as keyof typeof marketStructureTypes]}`}
          onRemove={() => {
            if (items.length > 1) {
              onRemoveItem(items[idx]);
            }
          }}
        />
      ))}
      {items.length === 0 && (
        <span className="text-st-lightest ml-2 whitespace-nowrap">Search a location</span>
      )}
    </div>
  );
};
