import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper, WithSignIn } from "components";
import { formatDateShort } from "helpers";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NumberFormat from "react-number-format";
import { ParcelHistoryEntryType } from "state/proformas";

type Props = {
  history: ParcelHistoryEntryType;
  isOpen: boolean;
  closeModal: any;
};

export const SaleHistoryPhotosModal = ({ history, isOpen, closeModal }: Props) => {
  return (
    <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block h-full w-full">
        <div className="inline-block h-full w-full max-w-5xl transform rounded-md px-0 text-left align-middle transition-all">
          <div className="flex w-full justify-center overflow-hidden">
            <div className="h-full w-full max-w-5xl rounded-md bg-gray-50 shadow-lg">
              <div className="flex w-full flex-row items-center border-b border-gray-200 bg-white align-top sm:align-middle">
                <div className="text-md ml-4 flex w-full flex-col items-center font-medium sm:basis-1/2 sm:flex-row sm:p-4">
                  <div className="w-full py-0">Historical Listing</div>
                </div>
                <div className="justify-right w-full basis-1/2 py-2 text-right sm:px-4 sm:py-4">
                  <button
                    type="button"
                    className="ml-2 mr-4 rounded-md pb-1 pl-1 text-sm font-medium text-st-lightest hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <div className="my-0 flex flex-col">
                <div className="flex w-full flex-col px-0 pb-4 sm:flex-row">
                  <div className="relative w-screen overflow-auto">
                    <div className="styled-scrollbars relative flex w-full snap-mandatory gap-4 overflow-x-auto">
                      {history.photos?.map((photo: any) => (
                        <div
                          key={photo._id}
                          className="min-w-[320px] shrink-0 snap-center snap-normal first:pl-4 last:pr-8"
                        >
                          <LazyLoadImage
                            className="w-100 sm:w-160 rounded-xs h-60 shrink-0 bg-white object-cover shadow-xl sm:h-96"
                            src={photo.photo_url}
                            alt="Property Img"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mt-2 flex w-full flex-col gap-8 px-4 pb-8 sm:flex-row sm:px-8">
                  <div className="basis-1/1 w-full px-4 text-sm sm:basis-1/3">
                    <div className="flex items-center pb-2 text-xs font-bold uppercase text-green-900">
                      Listing Details
                    </div>

                    <div className="text-lg font-medium md:text-lg">
                      <NumberFormat
                        value={history.price}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$ "
                        decimalScale={0}
                      />
                    </div>

                    <div className="mb-4 border-b pb-4">
                      {history.event} {formatDateShort(history.date)}
                    </div>

                    <table className="align-text-top text-xs">
                      <tbody>
                        {history.sale_type && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Sale Type:</strong>
                            </td>
                            <td className="align-text-top">{history.sale_type}</td>
                          </tr>
                        )}
                        {history.sale_deed && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Sale Deed:</strong>
                            </td>
                            <td className="align-text-top">{history.sale_deed}</td>
                          </tr>
                        )}
                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                        </tr>

                        {history.listing_agent && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Listing Agent: &nbsp;</strong>
                            </td>
                            <td className="align-text-top">{history.listing_agent}</td>
                          </tr>
                        )}
                        {history.listing_company && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Listing Company: &nbsp;</strong>
                            </td>
                            <td className="align-text-top">{history.listing_company}</td>
                          </tr>
                        )}

                        {history.selling_agent && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Selling Agent: &nbsp;</strong>
                            </td>
                            <td className="align-text-top">{history.selling_agent}</td>
                          </tr>
                        )}

                        {history.selling_company && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Selling Company: &nbsp;</strong>
                            </td>
                            <td className="align-text-top">{history.selling_company}</td>
                          </tr>
                        )}

                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                        </tr>

                        {history.buyer && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Buyer{history.buyer2 && " 1"}:</strong>
                            </td>
                            <td className="align-text-top">{history.buyer}</td>
                          </tr>
                        )}

                        {history.buyer2 && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Buyer 2:</strong>
                            </td>
                            <td className="align-text-top">{history.buyer2}</td>
                          </tr>
                        )}

                        {history.seller && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Seller{history.seller2 && " 1"}:</strong>
                            </td>
                            <td className="align-text-top">{history.seller}</td>
                          </tr>
                        )}

                        {history.seller2 && (
                          <tr>
                            <td className="align-text-top">
                              <strong>Seller 2:</strong>
                            </td>
                            <td className="align-text-top">{history.seller2}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="basis-1/1 w-full text-sm sm:basis-2/3">
                    {history.listing_remarks && (
                      <div>
                        <div className="flex items-center text-xs font-bold uppercase text-green-900">
                          Historical Listing remarks
                        </div>
                        <div className="mt-2 text-sm font-light">{history.listing_remarks}</div>
                      </div>
                    )}

                    {history.agent_listing_remarks && (
                      <>
                        <div className="mb-2 flex items-center pt-4 text-xs font-bold uppercase text-green-900">
                          Historical Agent Listing Remarks
                        </div>
                        <WithSignIn
                          asAgent={true}
                          text="Sign in with an agent account to view this information"
                        >
                          <div>
                            <div className="text-sm font-light">{history.agent_listing_remarks}</div>
                          </div>
                        </WithSignIn>
                      </>
                    )}

                    {history.listing_events &&
                      history.listing_events.length > 0 &&
                      history.listing_events[0].event && (
                        <div className="mt-4">
                          <div className="py-1 text-xs font-bold uppercase text-green-900">Sale History</div>
                          {history.listing_events.map((event: any, idx) => (
                            <React.Fragment key={idx}>
                              {event.event && (
                                <div className="flex w-full whitespace-nowrap border-b border-gray-200 py-2 text-sm">
                                  <div className="flex basis-1/3 items-center align-middle font-medium sm:basis-1/3">
                                    {event.event === "PriceDate" ? "Price Change" : event.event}
                                  </div>
                                  <div className="basis-1/3 text-right sm:basis-1/3">
                                    {formatDateShort(event.date)}
                                  </div>
                                  <div className="hidden text-right sm:block sm:basis-1/3">
                                    {event.price && (
                                      <NumberFormat
                                        value={event.price}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix="$ "
                                        decimalScale={0}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
