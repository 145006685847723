import { MinusIcon } from "@heroicons/react/outline";
import { AnnounceWidget } from "components/AnnounceWidget";
import { cn } from "helpers";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useRecoilValue } from "recoil";
import { useRegisterClientOrAgentModal } from "state";
import { filteredSearchResultsNoAVMCountSelector } from "state/browse";

type Props = {
  showAVMNotice: boolean;
  show: boolean;
  setShow: (value: boolean) => void;
};

export const NoAVMNotice = ({ showAVMNotice, show, setShow }: Props) => {
  const count = useRecoilValue(filteredSearchResultsNoAVMCountSelector);
  const { openRegisterClientOrAgentModal } = useRegisterClientOrAgentModal();
  const [renderAnnounceWidget, setRenderAnnounceWidget] = useState(false);

  useEffect(() => {
    setRenderAnnounceWidget(true);
  }, []);

  return (
    <>
      {renderAnnounceWidget && (
        <AnnounceWidget
          visible={!showAVMNotice}
          bottom={isMobile ? "50px" : "20px"}
          right={isMobile ? "50px" : "20px"}
        />
      )}
      {showAVMNotice && (
        <div
          className={cn(
            "relative shrink-0 cursor-pointer bg-warning text-sm",
            isMobile ? "fixed bottom-0 left-0 z-[60] w-full" : "mb-16",
            show ? "h-fit p-3" : "h-6",
          )}
          onClick={() => setShow(!show)}
        >
          <MinusIcon className="absolute -top-0.5 right-4 size-6 cursor-pointer" />
          {show && (
            <>
              {count} {isMobile ? " " : "additional "}listing{count === 1 ? "" : "s"} match your search but
              {count === 1 ? " is" : " are"} excluded from public automated valuations. You must have an agent
              or client account to filter for them by
              {isMobile ? " " : " estimated "}Gross Gain or Income.{" "}
              <span
                className="text-[#6389c4]"
                onClick={(e) => {
                  e.stopPropagation();
                  openRegisterClientOrAgentModal();
                }}
              >
                Learn more
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
};
