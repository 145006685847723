import { Navigate } from "react-router-dom";
import { useUserSession } from "state";

export const RequireNoAuth = ({ children }: { children: JSX.Element }) => {
  const currentUser = useUserSession();

  if (currentUser.isLogged) {
    // Redirect them to the Home page for pages like /login, /signup that
    // require no auth.
    return <Navigate to="/" />;
  }

  return children;
};
