import { listingsData } from "config";
import NumberFormat from "react-number-format";
import TimeAgo from "react-timeago";
import { useMapAppreciations } from "state/browse";

type Props = {
  property: any;
};

export const LegendsOverPhotosMobile = ({ property }: Props) => {
  const { mapAppreciations } = useMapAppreciations();
  const zipAppreciation = mapAppreciations?.[property.zip_code];

  return (
    <div className="absolute inset-0 z-10 size-full text-white">
      {/* Top Left Legend */}
      <div className="absolute left-3 top-2 flex w-full flex-row items-start justify-start">
        <img
          src={`https://cdn.davinci.pellego.com/static/images/${property.structureType}.svg`}
          className="mr-2 h-7"
          alt={property.structureType}
        />
        <div className="flex flex-col items-start justify-start">
          <div className="text-xs font-bold">
            {property.beds}bd&nbsp;
            {property.baths}ba&nbsp;
            <NumberFormat value={property.sqft} displayType="text" thousandSeparator={true} />
            sqft&nbsp;
          </div>
          <div className="overflow-hidden whitespace-nowrap text-[8px] tracking-tight">
            {property.structureType === "Condominium" || property.structureType === "Townhouse" ? (
              <>
                {property.year_built && `${property.year_built} build`}
                {property.year_built && property.hoa_dues > 0 && `, `}
                {property.hoa_dues > 0 && `$${property.hoa_dues.toFixed(0)}/mo HOA`}
              </>
            ) : (
              <>
                {property.lot_square_feet > 0 && (
                  <span>
                    <NumberFormat
                      value={property.lot_square_feet}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={2}
                    />{" "}
                    lot sqft
                  </span>
                )}
                {property.lot_square_feet > 0 && property.zoning && `, `}
                {property.zoning && `Zoning ${property.zoning}`}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Bottom Right Legend */}
      <div className="absolute bottom-2 right-3 flex w-1/2 flex-col items-end justify-center pl-2 pt-2">
        <div className="text-xs font-bold">{property?.location?.replace(/[()]/g, "")}</div>
        <div className="min-h-3 max-w-36 overflow-hidden truncate text-ellipsis whitespace-nowrap text-[8px] tracking-tight">
          {zipAppreciation &&
            `${zipAppreciation.pct_growth_three_months.toFixed(1)}% (3M), ${zipAppreciation.pct_growth_one_year.toFixed(1)}% (1Y)`}
        </div>
      </div>

      {/* Bottom Left Legend */}
      <div className="absolute bottom-2 left-3 w-1/2 pr-2 pt-2">
        <div className="flex flex-row items-center justify-between">
          <div className="flex w-full flex-col">
            <div className="text-xs font-bold">
              {property.listingEvent &&
                `${
                  property.listingEvent.startsWith("Price")
                    ? "Price"
                    : property.listingEvent === "Back on Market"
                      ? "Back"
                      : property.listingEvent
                } `}
              <TimeAgo
                date={new Date(property?.listingEventDate)}
                live={false}
                formatter={(value, unit, suffix) => {
                  return `${value}${unit === "month" ? "mo" : unit.slice(0, 1)} ${suffix}`;
                }}
              />
              {property.days_on_market > 0 &&
                property.listingEvent !== "Listed" &&
                property.listingEvent !== "Relisted" &&
                `, ${property.days_on_market} DOM`}
            </div>
            <div className="max-w-36 overflow-hidden truncate text-ellipsis whitespace-nowrap text-[8px] tracking-tight">
              Listing provided by {listingsData.find((l) => l.name === property.listing_source)?.abbr}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
