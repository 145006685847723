import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { FILTER_OPTIONS, MinMaxHybridDropdowns, PropertiesPriceSlider } from "components/browse";
import { formatCurrencyK1 } from "helpers";
import { Fragment } from "react";
import { Control, FieldValues, UseFormSetValue } from "react-hook-form";

type Props = {
  filters: any;
  control: Control<FieldValues, unknown>;
  priceRangeSet: (min: number | null, max: number | null) => void;
  handleChange: (e: any) => void;
  setValue: UseFormSetValue<FieldValues>;
};

export const FilterPriceMenu = ({ filters, priceRangeSet, handleChange, control, setValue }: Props) => {
  const priceMin = filters[`min_listing_price`];
  const priceMax = filters[`max_listing_price`];

  let buttonTittle = "";
  if (priceMin && priceMax) {
    buttonTittle = `${formatCurrencyK1(priceMin)} - ${formatCurrencyK1(priceMax)}`;
  } else if (priceMin) {
    buttonTittle = `${formatCurrencyK1(priceMin)}+`;
  } else if (priceMax) {
    buttonTittle = `Up to ${formatCurrencyK1(priceMax)}`;
  }

  return (
    <Menu as="div" className={`relative ml-2 mr-1 hidden filter-price:flex md:mx-2`}>
      {({ open }) => (
        <>
          <Menu.Button
            className={`text-md flex items-center justify-between rounded-md border border-gray-300 py-2 pl-3 pr-2 text-left text-sm font-medium ${
              priceMin || priceMax ? "bg-black text-white" : "text-st-light"
            }`}
          >
            <span className="flex items-center whitespace-nowrap">
              {priceMin || priceMax ? <>{buttonTittle}</> : <>Price</>}
            </span>
            {open ? (
              <ChevronDownIcon className="ml-2 h-4 w-4" />
            ) : (
              <>
                {priceMin || priceMax ? (
                  <XIcon
                    className="ml-2 h-4 w-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      priceRangeSet(null, null);
                    }}
                  />
                ) : (
                  <ChevronDownIcon className="ml-2 h-4 w-4 text-st-darkest" />
                )}
              </>
            )}
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute top-8 mt-2 w-fit min-w-72 max-w-80 origin-top-left rounded-md bg-white p-4 pb-6 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="mb-4 flex text-xs font-bold text-st-normal">
                <div className="mr-2 text-sm font-bold">Price</div>
              </div>
              <PropertiesPriceSlider
                priceMin={priceMin}
                priceMax={priceMax}
                priceRangeSet={priceRangeSet}
                autoApply={true}
                label=""
              />
              <MinMaxHybridDropdowns
                label="Price"
                variable="listing_price"
                unit="$"
                withLabel={false}
                filters={filters}
                control={control}
                handleChange={handleChange}
                setValue={setValue}
                options={FILTER_OPTIONS.price}
                className="mt-4"
              />
              <div className="mr-2 mt-4 flex flex-row-reverse gap-4">
                <Menu.Item>
                  <button className="rounded-xs h-8 bg-[#6389c4] px-3 text-sm text-white hover:bg-[#4674b9]">
                    Apply
                  </button>
                </Menu.Item>
                <button
                  className="rounded-xs h-8 px-3 text-sm text-st-lighter hover:bg-gray-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    priceRangeSet(null, null);
                  }}
                >
                  Reset
                </button>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
