import { formatPropertyPrice } from "helpers";
import NumberFormat from "react-number-format";
import { FinancialsType, ProformaType, useCustomStructure } from "state/proformas";

type Props = {
  financials: FinancialsType | null;
  proforma: ProformaType;
  mobileHeader?: boolean;
};

export const PellegoARV = ({ financials, proforma, mobileHeader }: Props) => {
  const isDataComplete = proforma.isDataComplete;
  const { hasCustomStructure } = useCustomStructure(proforma);

  return (
    <>
      {mobileHeader ? (
        <>
          {isDataComplete
            ? formatPropertyPrice(financials?.calcsheet.pellego_arv)
            : !proforma.isRestError && (
                <div className="ml-1 inline h-3 w-10 rounded-lg bg-gray-400/10 loading sm:mb-0.5 sm:ml-0 sm:h-[18px] sm:w-20" />
              )}
          {hasCustomStructure() &&
            financials?.calcsheet.pellego_arv !== financials?.calcsheet.resale_value && (
              <>{` → ${isDataComplete ? formatPropertyPrice(financials?.calcsheet.resale_value) : !proforma.isRestError && <div className="ml-1 inline h-3 w-10 rounded-lg bg-gray-400/10 loading sm:mb-0.5 sm:ml-0 sm:h-[18px] sm:w-20" />}`}</>
            )}
        </>
      ) : (
        <>
          {isDataComplete ? (
            <NumberFormat
              value={financials?.calcsheet.pellego_arv}
              displayType="text"
              thousandSeparator={true}
              prefix="$"
              decimalScale={0}
            />
          ) : (
            !proforma.isRestError && (
              <div className="mb-0.5 h-[18px] w-20 rounded-lg bg-gray-400/10 loading" />
            )
          )}
          {hasCustomStructure() &&
            financials?.calcsheet.pellego_arv !== financials?.calcsheet.resale_value && (
              <>
                {" → "}
                {isDataComplete ? (
                  <NumberFormat
                    value={financials?.calcsheet.resale_value}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                    decimalScale={0}
                  />
                ) : (
                  !proforma.isRestError && (
                    <div className="mb-0.5 h-[18px] w-20 rounded-lg bg-gray-400/10 loading" />
                  )
                )}
              </>
            )}
        </>
      )}
    </>
  );
};
