import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ExternalLinkIcon, HomeIcon, LogoutIcon } from "@heroicons/react/outline";
import { useUserActions } from "actions";
import { isPellego } from "config";
import { cn } from "helpers";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSignInSignUpModal, useUserSession } from "state";

export const ProfileButton = () => {
  const currentUser = useUserSession();
  const userActions = useUserActions();
  const { openSignInModal, openSignUpModal } = useSignInSignUpModal();
  const location = useLocation();
  return (
    <>
      {/* Profile buttons when desktop and logout*/}
      {!currentUser.isLogged && (
        <div className="inset-y-0 right-0 hidden h-8 content-center items-center pr-2 align-middle text-xs font-medium sm:static sm:inset-auto sm:flex sm:pr-0">
          <Link
            to="/login"
            state={{ from: location }}
            className="flex-nowrap whitespace-nowrap px-6 text-sm"
            onClick={(e) => {
              e.preventDefault();
              openSignInModal();
            }}
          >
            Log In
          </Link>
          <Link
            to="/signup"
            className="flex items-center justify-between whitespace-nowrap border border-black bg-black px-3 py-2 text-left text-xs uppercase text-white"
            onClick={(e) => {
              e.preventDefault();
              openSignUpModal();
            }}
          >
            Free Account
          </Link>
        </div>
      )}
      {/* Profile avatar when desktop and login */}
      {currentUser.isLogged && (
        <div
          className={cn(
            "right-0 hidden h-8 content-center items-center pr-2 align-middle text-xs font-medium sm:flex sm:flex-none sm:pr-0",
          )}
        >
          <Menu as="div" className="xs:mt-4 relative ml-1 align-middle sm:ml-3">
            <Menu.Button className="flex align-middle text-sm text-white">
              <span className="sr-only">Open user menu</span>
              <img
                className="ml-2 inline-block size-8 shrink-0 rounded-full border border-white object-cover focus:outline-none"
                src={currentUser.avatar}
                alt="avatar"
              />
            </Menu.Button>

            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="text-st-dark absolute right-0 mt-6 w-48 origin-top-right rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ul>
                  <li className="px-0 py-2">
                    <Menu.Item>
                      {({ active }) => (
                        <ul>
                          <li className="px-4 pt-2 text-left">
                            <div className="text-xs">Welcome back</div>
                            {currentUser.name && (
                              <div className="text-lg font-bold">
                                <Disclosure.Button as={Fragment}>
                                  <Link to="/account">{currentUser.name}</Link>
                                </Disclosure.Button>
                              </div>
                            )}
                          </li>
                          <li className="px-4 pt-0 text-xs">
                            <Disclosure.Button as={Fragment}>
                              <Link to="/account">Edit your profile</Link>
                            </Disclosure.Button>
                          </li>
                        </ul>
                      )}
                    </Menu.Item>
                  </li>

                  <li className="mx-4 mb-2 mt-4 border-b-2 border-gray-200"></li>

                  <li className="px-4 py-2">
                    <Menu.Item>
                      <Link to="/" className="text-st-dark flex-nowrap">
                        <HomeIcon className="mr-2 inline size-5" aria-hidden="true" />
                        Home
                      </Link>
                    </Menu.Item>
                  </li>
                  <li className="px-4 pt-2">
                    <a
                      href={`https://www.${isPellego ? "pellego" : "lotside"}.com/plans/agents`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ExternalLinkIcon className="mr-2 inline size-5" aria-hidden="true" />
                      Agent&nbsp;Plans
                    </a>
                  </li>
                  <li className="px-4 pt-2">
                    <a
                      href={`https://www.${isPellego ? "pellego" : "lotside"}.com/buyers`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ExternalLinkIcon className="mr-2 inline h-5 w-5" aria-hidden="true" />
                      Buyer&nbsp;Solutions
                    </a>
                  </li>

                  <li className="px-4 pt-2">
                    <a
                      href={`https://www.${isPellego ? "pellego" : "lotside"}.com/how-it-works`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ExternalLinkIcon className="mr-2 inline h-5 w-5" aria-hidden="true" />
                      How it Works
                    </a>
                  </li>
                  <li className="mx-4 mb-2 mt-6 border-b-2 border-gray-200"></li>

                  <Menu.Item>
                    {({ active }) => (
                      <li className="px-4 py-2 pb-4">
                        <button onClick={userActions.logout}>
                          <LogoutIcon className="mr-2 inline h-5 w-5" aria-hidden="true" />
                          <span className="text-md text-st-darkest">Sign out</span>
                        </button>
                      </li>
                    )}
                  </Menu.Item>
                </ul>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      )}
    </>
  );
};
