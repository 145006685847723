import { PeriodSelector } from "components";
import { MarketComparableType } from "components/markets";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { MarketDataEntryType } from "state/market";

const seriesColors = [
  { normalColor: "#68a691", lightColor: "#d2e3dc" },
  { normalColor: "#020202", lightColor: "#b1b1b1" },
  { normalColor: "#964b62", lightColor: "#e0c8ce" },
  { normalColor: "#666da3", lightColor: "#d0d2e2" },
  { normalColor: "#a19a6c", lightColor: "#e2e0d1" },
  { normalColor: "#68a691", lightColor: "#d2e3dc" },
  { normalColor: "#020202", lightColor: "#b1b1b1" },
  { normalColor: "#964b62", lightColor: "#e0c8ce" },
  { normalColor: "#666da3", lightColor: "#d0d2e2" },
  { normalColor: "#a19a6c", lightColor: "#e2e0d1" },
];

type Props = {
  marketResearchData: MarketComparableType[];
  valueName: keyof MarketDataEntryType;
  title: string;
  updated?: boolean;
};

export const MarketResearchGraph = ({ marketResearchData, valueName, title, updated = false }: Props) => {
  const [period, setPeriod] = useState(37);
  const [selectedLine, setSelectedLine] = useState("");
  const ticks: Date[] = [];
  const strTicks: string[] = [];
  const data: any[] = [];
  const lines: any[] = [];
  const cleanData: any = {};

  // Get the lastDate where marketResearchData was updated
  let today = new Date();
  let lastUpdateMonth: number | undefined = undefined;
  let lastUpdatedDate = new Date().toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  if (marketResearchData[0]?.data && marketResearchData[0]?.data?.length > 0) {
    const lastDate = new Date(marketResearchData[0].data[marketResearchData[0].data.length - 1].month);
    lastUpdateMonth = Number(lastDate.toLocaleString("en-us", { month: "numeric" }));
    if (today.getMonth() + 1 === lastUpdateMonth) {
      lastUpdatedDate = new Date(lastDate.getFullYear(), lastUpdateMonth - 1, 0).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    } else {
      lastUpdatedDate = new Date(lastDate.getFullYear(), lastUpdateMonth, 0).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
  }

  // fillTicks()
  let maxLength = 0;
  for (let i = 0; i < marketResearchData.length; i++) {
    if ((marketResearchData[i].data?.length || 0) > maxLength) {
      maxLength = marketResearchData[i].data?.length || 0;
    }
  }
  const months = period > 0 ? period : maxLength || 0;
  for (let i = 0; i < months; i++) {
    const date_ = lastUpdateMonth
      ? today.getMonth() + 1 === lastUpdateMonth
        ? new Date(today.getFullYear(), lastUpdateMonth - 1 - months + i, 1)
        : new Date(today.getFullYear(), lastUpdateMonth - months + i, 1)
      : new Date();
    ticks.push(date_);
    strTicks.push(date_.toISOString().substring(0, 11) + "00:00:00");
  }

  // organizeData()
  marketResearchData.forEach((location) => {
    cleanData[location.id] = { ticks: {}, valueName: valueName };
    location.data?.forEach((item) => {
      cleanData[location.id].ticks[item.month] = item[valueName];
    });
  });

  // buildData()
  strTicks.forEach((currentMonth, idx) => {
    const row: any = {
      name: new Intl.DateTimeFormat("en-US", { year: "2-digit", month: "short" }).format(
        new Date(currentMonth),
      ),
    };
    marketResearchData.forEach((location) => {
      const label = `${location.loc_name} ${
        location.structureType !== "all" && location.structureType !== undefined ? location.structureType : ""
      }`;
      const value: number = Number(
        Intl.NumberFormat("en-US", {
          useGrouping: false,
          maximumFractionDigits: 2,
        }).format(cleanData[location.id].ticks[currentMonth]),
      );
      row[label] = value || "N/A";
    });
    data.push(row);
  });

  // buildLines()
  marketResearchData.forEach((location, idx) => {
    const label = `${location.loc_name} ${
      location.structureType !== "all" && location.structureType !== undefined ? location.structureType : ""
    }`;
    lines.push(
      <Line
        type="linear"
        key={label}
        dataKey={label}
        stroke={
          selectedLine === "" || selectedLine === label
            ? seriesColors[idx].normalColor
            : seriesColors[idx].lightColor
        }
        strokeWidth={selectedLine === label ? 3 : 1}
        animationEasing={selectedLine === label ? "ease-in-out" : "linear"}
        dot={false}
        activeDot={{
          r: 3,
          onMouseOver: () => setSelectedLine(label),
          onClick: () => setSelectedLine(label),
        }}
        onMouseOver={() => setSelectedLine(label)}
        onClick={() => setSelectedLine(label)}
      />,
    );
  });

  return (
    <div className="mb-10">
      <div className="mb-2 flex w-full flex-row">
        <div className="text-md text-st-lighter basis-1/2 overflow-hidden text-ellipsis whitespace-nowrap align-top font-medium">
          {title}
        </div>
        <div className="items-right flex basis-1/2 justify-end text-sm">
          <PeriodSelector period={period} value={13} setPeriod={setPeriod} />
          <PeriodSelector period={period} value={37} setPeriod={setPeriod} />
          <PeriodSelector period={period} value={121} setPeriod={setPeriod} />
          <PeriodSelector period={period} value={0} setPeriod={setPeriod} />
        </div>
      </div>
      <div className="h-full w-full text-xs">
        {data.length > 0 && (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={data}
              margin={{
                top: 5,
                right: 20,
                left: -18,
                bottom: 5,
              }}
              onMouseLeave={() => setSelectedLine("")}
              onClick={() => {
                !isMobile && setSelectedLine("");
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} syncWithTicks={true} />
              <XAxis dataKey="name" angle={-30} tickMargin={10} />
              <YAxis
                type="number"
                tickMargin={5}
                domain={["auto", "auto"]}
                tickFormatter={(value) => {
                  if (value >= 10000) {
                    return `${value / 1000}K`;
                  }
                  return value;
                }}
              />
              <Tooltip
                offset={200}
                labelStyle={{ fontSize: 12, fontWeight: "bold" }}
                itemStyle={{ fontSize: 12 }}
              />
              <Legend verticalAlign="top" height={36} />
              {lines}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
      {updated && (
        <div className="flex w-full items-center justify-center pt-10 text-xs">
          Data last updated&nbsp;{lastUpdatedDate}
        </div>
      )}
    </div>
  );
};
