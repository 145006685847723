import { AppreciationGraph, ParcelHistory } from "components/proformas";
import { Loan } from "components/proformas/details";
import { cn } from "helpers";
import { useRef, useState } from "react";
import { useSetVisibleSection } from "state";
import { useProforma, useRentalAppreciation, useSaleAppreciation } from "state/proformas";

export const PropertyData = () => {
  const { proforma } = useProforma();
  const ref = useRef<HTMLDivElement>(null);
  useSetVisibleSection(ref, "remarks");
  const [graph, setGraph] = useState<"sale" | "rental">("sale");

  const {
    data: rentalAppreciationData,
    loading: rentalAppreciationLoading,
    error: rentalAppreciationError,
  } = useRentalAppreciation(proforma.parcel_id);
  const RentalAppreciationData = !(
    rentalAppreciationLoading ||
    rentalAppreciationError ||
    !rentalAppreciationData ||
    rentalAppreciationData?.dates?.length === 0
  );

  const {
    data: saleAppreciationData,
    loading: saleAppreciationLoading,
    error: saleAppreciationError,
  } = useSaleAppreciation(proforma.parcel_id);
  const SaleAppreciationData = !(
    saleAppreciationLoading ||
    saleAppreciationError ||
    !saleAppreciationData ||
    saleAppreciationData?.dates?.length === 0
  );

  return (
    <div className="w-full max-w-7xl gap-4 px-4 sm:rounded-md xl:px-0" ref={ref}>
      <div className="justyfy-center flex w-full flex-col gap-6">
        {/* Sales History */}
        <div>
          <ParcelHistory history={proforma.parcel.listing_history} />
        </div>

        {/* Median Graphs */}
        <div>
          {!proforma.isRestError && (
            <>
              {!proforma.isDataComplete && proforma.isGeoData ? (
                <div className="h-[353px] w-full rounded-lg bg-gray-400/10 loading" />
              ) : (
                <>
                  <div className="flex flex-row items-center justify-end">
                    {RentalAppreciationData && (
                      <div
                        className={`z-10 my-0 w-14 rounded-t-md border-x border-y border-stone-100 px-2 py-1 text-center text-xs ${
                          graph === "rental"
                            ? "cursor-default border-b-[#ECF1F5] bg-[#ECF1F5]"
                            : "cursor-pointer border-b-stone-100 bg-white"
                        }`}
                        onClick={() => setGraph("rental")}
                      >
                        Rent
                      </div>
                    )}
                    {SaleAppreciationData && (
                      <div
                        className={`z-10 my-0 w-14 rounded-t-md border-y border-r border-stone-100 px-2 py-1 text-center text-xs ${
                          graph === "sale"
                            ? "cursor-default border-b-[#ECF1F5] bg-[#ECF1F5]"
                            : "cursor-pointer border-b-stone-100 bg-white"
                        }`}
                        onClick={() => setGraph("sale")}
                      >
                        Sale
                      </div>
                    )}
                  </div>
                  <div className="relative flex h-[353px] w-full flex-col">
                    <div
                      className={cn(
                        "absolute -top-[1px] left-0 w-full",
                        graph === "rental" ? "visible" : "invisible",
                      )}
                    >
                      {RentalAppreciationData && (
                        <AppreciationGraph type="rental" data={rentalAppreciationData} />
                      )}
                    </div>
                    <div
                      className={cn(
                        "absolute -top-[1px] left-0 w-full",
                        graph === "sale" ? "visible" : "invisible",
                      )}
                    >
                      {SaleAppreciationData && <AppreciationGraph type="sale" data={saleAppreciationData} />}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {/* Loans Data */}
        <div>
          <Loan parcelId={proforma.parcel_id} />
        </div>
      </div>
    </div>
  );
};
