import { yupResolver } from "@hookform/resolvers/yup";
import { useUserActions } from "actions";
import { Alert } from "components";
import { LabeledInput, SubmitButton } from "components/forms";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useSignInSignUpModal } from "state";
import * as Yup from "yup";

type Props = {
  asModal?: boolean;
};

export const PasswordForgotten = ({ asModal }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userActions = useUserActions();
  const { openSignInModal, openSignUpModal } = useSignInSignUpModal();

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, setFocus, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (userData: any) => {
    setIsSubmitting(true);
    userActions.forgotPassword(userData, asModal).finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <div className="mx-auto max-w-md px-6 pb-4">
      <h3 className={`${asModal ? "" : "mt-8"} text-3xl`}>Password Reset</h3>
      <Alert />
      <div className="mt-4 max-w-md">
        <p>
          Please enter your email address and click "Reset My Password." If we have an account on file, you
          will receive an email with instructions to reset your password.
        </p>
        <div className="grid grid-cols-1 gap-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <LabeledInput type="email" label="Email" {...register("email")} errMsg={errors.email?.message} />
            <SubmitButton isSubmitting={isSubmitting}>Reset My Password</SubmitButton>
            <div className="mt-2 text-sm">
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-sl-normal"
                onClick={(e) => {
                  if (asModal) {
                    e.preventDefault();
                    openSignInModal();
                  }
                }}
              >
                Sign&nbsp;In
              </Link>
              <br />
              Don't have an account?{" "}
              <Link
                to="/signup"
                className="text-sl-normal"
                onClick={(e) => {
                  if (asModal) {
                    e.preventDefault();
                    openSignUpModal();
                  }
                }}
              >
                Create Free Account
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
