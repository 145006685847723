import { appConfig } from "config";
import { formatCurrencyK1 } from "helpers";
import L from "leaflet";
import "leaflet.markercluster";
import "@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";
import { Position } from "geojson";
import { TypeAheadItemType } from "helpers";
import { isDesktop, isMobile } from "react-device-detect";
import { MapAppreciationType, SearchResultType, useMapRequirements } from "state/browse";
import { BasemapType } from "./BrowseMapMobile";

export type AppreciationPeriodType =
  | "none"
  | "pct_growth_three_months"
  | "pct_growth_one_year"
  | "pct_growth_three_years";

export const controlsLabel: Record<AppreciationPeriodType, string> = {
  none: "None",
  pct_growth_three_months: "3M",
  pct_growth_one_year: "1Y",
  pct_growth_three_years: "3Y",
};

const AppreciationScaleMuliplier = {
  none: 1.0,
  pct_growth_three_months: 0.5,
  pct_growth_one_year: 1.0,
  pct_growth_three_years: 2.0,
};

const colorScale = [
  ["#ECF4F1", "#FAE7E5"],
  ["#DFECE8", "#F7D7D4"],
  ["#D2E4DF", "#F4C6C3"],
  ["#C5DDD5", "#F1B6B1"],
  ["#B9D5CC", "#EEA6A0"],
  ["#ACCEC3", "#EB968F"],
  ["#9FC6BA", "#E8867D"],
  ["#92BEB1", "#E4766C"],
  ["#85B7A7", "#E1665B"],
  ["#79AF9E", "#DE554A"],
  ["#6CA795", "#DB4538"],
  ["#5FA08C", "#D83527"],
  ["#589380", "#C73124"],
  ["#508675", "#B52D21"],
  ["#487A6A", "#A4281E"],
];

const addDrawingText = "ADD DRAWING";
const addExtraDrawingText = "ADD EXTRA DRAWING";
const removeAllDrawingText = "REMOVE ALL DRAWINGS";

function getHtmlColorFromPct(number: number, appreciation_type: AppreciationPeriodType): string {
  if (appreciation_type === "none") return "#000000";
  const columnIdx = number >= 0 ? 0 : 1;
  const absPct = Math.abs(number);
  const colorIdx = Math.min(
    Math.floor(absPct / AppreciationScaleMuliplier[appreciation_type]),
    colorScale.length - 1,
  );
  return colorScale[colorIdx][columnIdx];
}

const getClusterIcon = (count: number, highlighted: boolean = false): L.DivIcon => {
  let sizeIdx = Math.floor(count / 50);
  if (sizeIdx > 4) sizeIdx = 4;
  const iconSizes = [48, 51, 54, 57, 60];
  return L.divIcon({
    className: `marker-cluster-icon marker-cluster-icon-${sizeIdx + 1}`,
    html: `<img src="https://cdn.davinci.pellego.com/static/images/map/cluster/${
      highlighted ? "c" : "g"
    }${sizeIdx + 1}.png" />${count}`,
    iconSize: [iconSizes[sizeIdx], iconSizes[sizeIdx]],
    iconAnchor: [iconSizes[sizeIdx] / 2, iconSizes[sizeIdx] / 2],
  });
};

function simplifyPolygon(coordinates: any[]) {
  const simplifiedCoordinates = L.LineUtil.simplify(
    coordinates.map((c) => new L.Point(c[0], c[1])),
    0.0001,
  );
  return simplifiedCoordinates.map((c) => [c.x, c.y]);
}

export class LeafletMapService {
  map: L.Map | null = null;

  // Appreciation data
  onlyAppreciation: boolean;
  appreciationType: AppreciationPeriodType = "none";
  locationsAppreciations: Record<string, any> | null | undefined = null;
  appreciationGeoJSONLayer: L.GeoJSON | null = null;
  appreciationLegendLayer: L.Control | null = null;
  appreciationControlLayer: L.Control | null = null;

  // Areas outlines data
  boundariesJSONLayer: L.GeoJSON | null = null;
  areasOutlinesVisible = true;
  areasOutlinesPolygons: number[][] = [];

  // Properties/clusters data
  properties: SearchResultType[] = [];
  markersClusterLayer: L.MarkerClusterGroup | null = null;
  markersClusterLayerVisible = true;

  // Free hand drawing data
  freeHandActive: boolean = false;
  freeHandJSONLayer: L.GeoJSON | null = null;
  freeHandAreas: Record<number, { geoJson: L.GeoJSON; polygon: L.Polygon }> = {};
  freeHandControlLayer: L.Control | null = null;
  freeHandDrawButton: HTMLDivElement | null = null;
  freeHandDelButton: HTMLDivElement | null = null;

  highlightedMarker: L.Marker | null = null;
  mapControlTexts: any = {};
  selectedIdx: number | undefined = undefined; // Marker clicked on the map
  viewedProperties: Record<number, boolean> = {};
  staredProperties: Record<number, boolean> = {};
  setSelectedProperty: CallableFunction;
  idtoMarkerIdx: Record<number, number> = {};
  removeAreasControlLayer: L.Control | null = null;
  isClient: boolean | undefined;
  locationSearchItems: TypeAheadItemType[] = [];
  mapRequirements: ReturnType<typeof useMapRequirements>;
  setMultiPolygons?: (polygons: L.Polygon[]) => void;
  setMapBoundsParams?: (newVal: Record<string, any>) => void;
  currentMapBounds: string = "";
  zoomLevel: number;
  toolTipLayer: L.Polygon | null = null;
  basemap: BasemapType;
  currentAppreciation: AppreciationPeriodType;
  setMapAppreciations: (newVal: MapAppreciationType | null) => void;

  constructor(
    viewedProperties: Record<number, boolean>,
    staredProperties: Record<number, boolean>,
    setSelectedProperty: CallableFunction,
    onlyAppreciation: boolean,
    isClient: boolean | undefined,
    mapRequirements: ReturnType<typeof useMapRequirements>,
    basemap: BasemapType,
    currentAppreciation: AppreciationPeriodType,
    setMapAppreciations: (newVal: MapAppreciationType | null) => void,
  ) {
    this.setSelectedProperty = setSelectedProperty;
    this.viewedProperties = viewedProperties;
    this.staredProperties = staredProperties;
    this.onlyAppreciation = onlyAppreciation;
    this.isClient = isClient;
    this.mapRequirements = mapRequirements;
    this.zoomLevel = onlyAppreciation ? 3 : 11;
    this.basemap = basemap;
    this.currentAppreciation = currentAppreciation;
    this.setMapAppreciations = setMapAppreciations;
    window.Pellego.map = this;
  }

  createMap(domRef: HTMLDivElement) {
    const streetMap = L.maplibreGL({
      style: this.onlyAppreciation
        ? `https://api.maptiler.com/maps/basic-v2-light/style.json?key=${appConfig.mapTilerApiKey}`
        : `https://api.maptiler.com/maps/2eadbf66-831a-4e49-9604-48d6515c3a10/style.json?key=${appConfig.mapTilerApiKey}`,
    });

    const satelliteMap = L.tileLayer("https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
      maxZoom: 18,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    });

    this.map = L.map(domRef, {
      zoom: this.zoomLevel,
      minZoom: 7,
      maxZoom: 18,
      attributionControl: false,
      zoomControl: false,
      layers: [isMobile ? (this.basemap === "streets" ? streetMap : satelliteMap) : streetMap],
    });

    const baseMaps = {
      Street: streetMap,
      Satellite: satelliteMap,
    };

    const overlays = {
      //add any overlays here
    };

    if (!isMobile) {
      L.control.layers(baseMaps, overlays, { position: "bottomleft" }).addTo(this.map);
    }

    // Only add zoom controls if not on mobile
    if (!isMobile) {
      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(this.map);
    }

    this.createAppreciationLayers();

    if (!this.onlyAppreciation) {
      this.createMarkerClusterLayer();

      if (isDesktop) {
        this.createFreeHandControls();
      }
      // this.onClickAppreciationControl("pct_growth_one_year");

      this.map.on("click", () => this.onMapClick());
      this.map.on("moveend", () => this.onMapMoveEnd());
    }
    this.createAreasOutlines(true);

    this.setGeoJsonPolygons(this.mapRequirements.geoJsonPolygons);

    this.fetchAppreciationData();
  }

  updateBasemap(newBasemap: BasemapType) {
    if (!this.map) return;

    // Remove existing layers
    this.map.eachLayer((layer) => {
      if (layer instanceof L.TileLayer || layer instanceof L.MaplibreGL) {
        this.map?.removeLayer(layer);
      }
    });

    // Add new layer based on basemap type
    if (newBasemap === "streets") {
      L.maplibreGL({
        style: this.onlyAppreciation
          ? `https://api.maptiler.com/maps/basic-v2-light/style.json?key=${appConfig.mapTilerApiKey}`
          : `https://api.maptiler.com/maps/2eadbf66-831a-4e49-9604-48d6515c3a10/style.json?key=${appConfig.mapTilerApiKey}`,
      }).addTo(this.map);
    } else {
      L.tileLayer("https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
        maxZoom: 18,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }).addTo(this.map);
    }

    this.basemap = newBasemap;
  }

  onMapClick() {
    if (this.selectedIdx) {
      this.unHighlightItem();
      this.selectedIdx = undefined;
    }
    this.setSelectedProperty(null);
  }

  updateTooltipLayer() {
    if (!this.map || this.onlyAppreciation) return;

    this.toolTipLayer?.remove();

    if (!this.areasOutlinesVisible || this.freeHandActive || isMobile) {
      this.toolTipLayer = null;
      return;
    }

    const mapBound = this.map.getBounds().pad(1);
    const sw = mapBound.getSouthWest();
    const ne = mapBound.getNorthEast();

    const latlngs: any = [
      [ne.lat, ne.lng],
      [ne.lat, sw.lng],
      [sw.lat, sw.lng],
      [sw.lat, ne.lng],
      [ne.lat, ne.lng],
    ];

    this.toolTipLayer = new L.Polygon(latlngs, { fill: true, fillOpacity: 0 }).addTo(this.map);
    this.toolTipLayer
      .bindTooltip("Click to see<br />all homes", {
        direction: "right",
        offset: [8, 10],
        className: "mapTooltipOutlines",
        sticky: true,
      })
      .openTooltip();
    this.toolTipLayer.on("click", () => {
      if (this.areasOutlinesVisible) {
        this.setAreasOutlinesVisible(false);
        this.updateTooltipLayer();
      }
    });
    this.toolTipLayer.bringToBack();
  }

  onMapMoveEnd() {
    if (!this.map) return;

    if (this.freeHandActive) return;

    if (this.selectedIdx) {
      this.unHighlightItem();
      this.selectedIdx = undefined;
    }
    this.setSelectedProperty(null);

    this.updateMapBoundsParam();

    this.updateTooltipLayer();
  }

  updateMapBoundsParam() {
    const bounds = this.map?.getBounds();
    if (!bounds || !this.map) return;
    const nw = bounds.getNorthWest();
    const se = bounds.getSouthEast();

    const min_lat = Math.min(nw.lat, se.lat).toFixed(6);
    const min_lng = Math.min(nw.lng, se.lng).toFixed(6);
    const max_lat = Math.max(nw.lat, se.lat).toFixed(6);
    const max_lng = Math.max(nw.lng, se.lng).toFixed(6);
    const newBounds = `${min_lat} ${min_lng} ${max_lat} ${max_lng} ${this.areasOutlinesVisible}`;
    if (this.setMapBoundsParams && newBounds !== this.currentMapBounds) {
      this.setMapBoundsParams?.({
        mapBounds: `${min_lat} ${min_lng} ${max_lat} ${max_lng}`,
        outLines: this.areasOutlinesVisible,
      });
    }
    this.currentMapBounds = newBounds;
  }

  //
  // Free hand drwaing related methods
  //
  createFreeHandControls() {
    this.createHandDrawControls();
    this.freeHandHandlers();
  }

  createHandDrawControls() {
    const that = this;
    const MyControls = L.Control.extend({
      options: {
        position: "topright",
      },

      onAdd: function (map: L.Map) {
        const container = L.DomUtil.create("div", "leaflet-control");
        container.setAttribute("id", "browseMapFreeHandControl");
        L.DomEvent.disableClickPropagation(container);
        L.DomEvent.disableScrollPropagation(container);

        const divDraw = L.DomUtil.create("div", "mapControl", container);
        that.freeHandDrawButton = L.DomUtil.create("div", "mapControlText", divDraw);
        that.freeHandDrawButton.innerHTML = addDrawingText;

        divDraw.addEventListener("click", () => that.onClickFreeHandDrawButton());

        const divDelete = L.DomUtil.create("div", "mapControl hideRemoveAll", container);
        that.freeHandDelButton = L.DomUtil.create("div", "mapControlText", divDelete);
        that.freeHandDelButton.innerHTML = removeAllDrawingText;

        divDelete.addEventListener("click", () => that.onClickRemoveAllDrawings());

        return container;
      },
    });

    this.freeHandControlLayer = new MyControls();
    this.freeHandControlLayer.addTo(this.map!);
  }

  createRemoveAreasOutlinesControl() {
    const that = this;
    this.hideAreasButton();

    const MyControls = L.Control.extend({
      options: {
        position: isMobile ? "topright" : "bottomright",
      },

      onAdd: function (map: L.Map) {
        const container = L.DomUtil.create("div", isMobile ? "mobile" : "leaflet-control");
        container.setAttribute("id", "browseMapRemoveAreasControl");
        L.DomEvent.disableClickPropagation(container);
        L.DomEvent.disableScrollPropagation(container);

        const divDel = L.DomUtil.create("div", "mapControl", container);
        divDel.style.display = "flex";
        divDel.style.alignItems = "center";
        divDel.style.justifyContent = "start";

        divDel.addEventListener("click", () => that.setAreasOutlinesVisible(!that.areasOutlinesVisible));

        return container;
      },
    });

    this.removeAreasControlLayer = new MyControls();
    this.removeAreasControlLayer.addTo(this.map!);
  }

  freeHandHandlers() {
    let dragging = false;
    let coordinates: any[];

    if (!this.map) return;

    this.map.on("mousedown", (e) => {
      if (!this.freeHandActive || this.freeHandJSONLayer) return;
      e.target.dragging.disable();
      dragging = true;
      coordinates = [[e.latlng.lng, e.latlng.lat]];
      this.createFreeHandLayer();
      this.freeHandJSONLayer!.addTo(this.map!);
      this.properties = [];
    });

    this.map.on("mousemove", (e) => {
      if (!this.freeHandActive || !dragging) {
        return;
      }
      coordinates.push([e.latlng.lng, e.latlng.lat]);
      const featureCollection: GeoJSON.FeatureCollection<any> = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: coordinates,
              type: "LineString",
            },
          },
        ],
      };
      this.freeHandJSONLayer?.addData(featureCollection);
    });

    this.map.on("mouseup", (e) => {
      if (!this.freeHandActive || !dragging) return;
      this.freeHandActive = false;
      e.target.dragging.enable();
      dragging = false;
      L.DomUtil.removeClass(this.map?.getContainer()!, "leaflet-crosshair");
      this.freeHandDrawButton?.classList.remove("active");

      this.freeHandJSONLayer?.remove();
      this.freeHandJSONLayer = null;

      const simplifiedCoordinates = simplifyPolygon(coordinates);

      if (simplifiedCoordinates.length > 4) {
        this.addFreeHandArea(simplifiedCoordinates);
        this.updateMapDrawnPolygons();
      }

      if (Object.keys(this.freeHandAreas).length > 0) {
        if (this.freeHandDrawButton) this.freeHandDrawButton.innerHTML = addExtraDrawingText;
        this.freeHandDelButton?.parentElement?.classList.remove("hideRemoveAll");
      }
      this.setHandDrawMode(false);
    });
  }

  addFreeHandArea(coordinates: number[][]) {
    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [coordinates],
            type: "Polygon",
          },
        },
      ],
    };
    const newArea = L.geoJSON(featureCollection, {
      style: {
        weight: 1,
        color: "#888",
        fillColor: "#888",
        fillOpacity: 0.2,
      },
    });
    const polygon = L.polygon(L.GeoJSON.coordsToLatLngs(coordinates));
    const newAreaId = newArea.getLayerId(newArea);
    newArea.addEventListener("mousedown", () => this.onClickFreeHandAreaRemove(newAreaId));
    this.freeHandAreas[newAreaId] = { geoJson: newArea, polygon };
    newArea.addTo(this.map!);

    newArea.addEventListener("mouseover", () => {
      newArea
        .bindTooltip("Click to remove this area", {
          direction: "right",
          offset: [10, 0],
          className: "mapTooltip",
          sticky: true,
        })
        .openTooltip();
    });
  }

  setGeoJsonPolygons(coordinates: Position[][][] | null | undefined) {
    if (coordinates && coordinates.length > 0) {
      coordinates.forEach((coords) => {
        this.addFreeHandArea(coords[0]);
      });
      this.updateMapDrawnPolygons();
      if (Object.keys(this.freeHandAreas).length > 0) {
        if (this.freeHandDrawButton) this.freeHandDrawButton.innerHTML = addExtraDrawingText;
        this.freeHandDelButton?.parentElement?.classList.remove("hideRemoveAll");
      }
    }
  }

  setHandDrawMode(active: boolean) {
    if (active) {
      this.hideAppreciationsLayers();
      this.hidePropertiesLayers();
      this.hideAreasOutlines();
      this.hideAreasButton();
    } else {
      this.showAppreciationsLayers();
      this.showPropertiesLayers();
      this.showAreasOutlines();
      this.showAreasButton(this.areasOutlinesVisible);
    }
    this.updateTooltipLayer();
  }

  updateMapDrawnPolygons() {
    const polygons: L.Polygon[] = [];
    Object.values(this.freeHandAreas).forEach((area) => {
      polygons.push(area.polygon);
    });
    this.setMultiPolygons?.(polygons);
  }

  onClickFreeHandAreaRemove(areaId: number) {
    this.freeHandAreas[areaId].geoJson.remove();
    delete this.freeHandAreas[areaId];
    if (Object.keys(this.freeHandAreas).length === 0) {
      this.onClickRemoveAllDrawings();
    } else {
      this.updateMapDrawnPolygons();
    }
  }

  createFreeHandLayer() {
    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [],
            type: "LineString",
          },
        },
      ],
    };
    this.freeHandJSONLayer = L.geoJSON(featureCollection, {
      style: {
        weight: 2,
        color: "#888",
      },
    });
  }

  onClickFreeHandDrawButton() {
    let handDrawMode = true;
    this.freeHandActive = !this.freeHandActive;
    this.freeHandDrawButton?.classList.toggle("active", this.freeHandActive);
    if (this.freeHandActive) {
      L.DomUtil.addClass(this.map?.getContainer()!, "leaflet-crosshair");
    } else {
      L.DomUtil.removeClass(this.map?.getContainer()!, "leaflet-crosshair");
      if (Object.keys(this.freeHandAreas).length === 0) {
        handDrawMode = false;
      }
    }
    this.setHandDrawMode(handDrawMode);
  }

  removeAllHandDrawings() {
    Object.values(this.freeHandAreas).forEach((area) => {
      area.geoJson.remove();
    });
    this.freeHandAreas = {};
    this.setHandDrawMode(false);
    this.freeHandDelButton?.parentElement?.classList.add("hideRemoveAll");
    if (this.freeHandDrawButton) this.freeHandDrawButton.innerHTML = addDrawingText;
  }

  onClickRemoveAllDrawings() {
    this.removeAllHandDrawings();
    this.updateMapDrawnPolygons();
  }

  //
  // Marker related methods
  //
  highlightItem(itemIdx: number) {
    if (!this.properties[itemIdx]) return;
    if (this.properties[itemIdx].dont_show_map_link) return;

    // Create new marker
    this.highlightedMarker = new L.Marker(
      [this.properties[itemIdx].latitude, this.properties[itemIdx].longitude],
      {
        icon: this.getMarkerIcon(
          this.properties[itemIdx],
          true,
          this.viewedProperties?.[this.properties[itemIdx].parcel_id],
          this.staredProperties?.[this.properties[itemIdx].parcel_id],
        ),
        zIndexOffset: 1000,
      },
    );
    this.highlightedMarker.addEventListener("click", () => this.onClickMarker(itemIdx));
    this.highlightedMarker.addTo(this.map!);
  }

  unHighlightItem() {
    if (!this.highlightedMarker) return;
    this.highlightedMarker.remove();
  }

  highlightHandler(propertyId: number) {
    this.unHighlightItem();

    if (this.selectedIdx) {
      this.selectedIdx = undefined;
    }

    if (!propertyId || !this.markersClusterLayerVisible) return;

    const markerIdx = this.idtoMarkerIdx[propertyId];
    if (markerIdx !== undefined) {
      this.highlightItem(markerIdx);
    }
  }

  onClickMarker(idx: number) {
    const oldSelected = this.selectedIdx;
    this.selectedIdx = oldSelected === idx ? undefined : idx;
    if (oldSelected !== undefined) {
      this.unHighlightItem();
    }
    if (oldSelected !== idx) {
      this.highlightItem(idx);
    }
    if (this.selectedIdx !== undefined) {
      this.setSelectedProperty(this.properties[this.selectedIdx]);
    } else {
      this.setSelectedProperty(null);
    }
  }

  getMarkerIcon(
    item: SearchResultType,
    selected: boolean = false,
    viewed: boolean = false,
    stared: boolean = false,
  ): L.DivIcon {
    return L.divIcon({
      className: "marker",
      html: `<div class="relative"><span class="marker-text marker-${selected && !viewed ? "selected" : "neutral"} ${viewed ? "marker-viewed" : ""}">
      ${!this.isClient && item?.is_wholesale ? "Wholesale" : formatCurrencyK1(item?.listingPrice)}</span>${stared ? '<span class="absolute z-10 text-lg -top-3 -left-2">⭐</span>' : ""}</div>`,
      iconSize: [61, 29],
      iconAnchor: [31, 27],
    });
  }

  createMarkers() {
    if (this.markersClusterLayer) {
      const markers: L.Marker[] = [];
      this.idtoMarkerIdx = {};

      this.markersClusterLayer?.clearLayers();

      this.properties.forEach((item, idx) => {
        if (item.dont_show_map_link) return;

        const marker = new L.Marker([item.latitude, item.longitude], {
          icon: this.getMarkerIcon(
            item,
            false,
            this.viewedProperties?.[item.parcel_id],
            this.staredProperties?.[item.parcel_id],
          ),
        });
        marker.addEventListener("click", () => this.onClickMarker(idx));
        markers.push(marker);
        this.idtoMarkerIdx[item.parcel_id] = idx;
      });

      this.markersClusterLayer.addLayers(markers);
      this.map?.addLayer(this.markersClusterLayer);
    }
  }

  //
  // Properties/clusters related methods
  //
  createMarkerClusterLayer() {
    this.markersClusterLayer = new L.MarkerClusterGroup({
      disableClusteringAtZoom: 15,
      spiderfyOnMaxZoom: false,
      maxClusterRadius: 45,
      iconCreateFunction: function (cluster) {
        return getClusterIcon(cluster.getChildCount());
      },
    });
  }

  updateResults(
    properties: SearchResultType[],
    mapRequirements: ReturnType<typeof useMapRequirements>,
    mapBoundsParams: any,
    firstResultsUpdate: boolean,
  ) {
    this.mapRequirements = mapRequirements;
    this.createAreasOutlines(!mapBoundsParams.mapBounds, mapBoundsParams.outLines);
    this.properties = properties;
    this.createMarkers();
    this.updateAppreciations();

    if (!this.mapRequirements?.geoJsonPolygons || this.mapRequirements?.geoJsonPolygons?.length === 0) {
      this.removeAllHandDrawings();
    }
    this.updateTooltipLayer();

    if (firstResultsUpdate && this.markersClusterLayer) {
      this.map?.fitBounds(this.markersClusterLayer.getBounds());
    }
  }

  showPropertiesLayers() {
    if (this.markersClusterLayer && !this.markersClusterLayerVisible) {
      this.markersClusterLayer.addTo(this.map!);
      this.markersClusterLayerVisible = true;
    }
  }

  hidePropertiesLayers() {
    this.markersClusterLayer?.remove();
    this.markersClusterLayerVisible = false;
  }

  //
  // Areas outlines related methods
  //
  createAreasOutlines(shouldFitBounds: boolean, showOutline: boolean = true) {
    if (!this.mapRequirements.locationsPolygons) return;

    this.removeAreasOutlines();
    if (!showOutline) {
      this.areasOutlinesVisible = false;
      this.showAreasButton(this.areasOutlinesVisible);
      return;
    }
    this.areasOutlinesVisible = true;

    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: "FeatureCollection",
      features: this.mapRequirements.locationsPolygons.map((polygon: any) => {
        return {
          type: "Feature",
          properties: {},
          geometry: polygon,
        };
      }),
    };

    this.boundariesJSONLayer = L.geoJSON(featureCollection, {
      style: {
        weight: 2,
        color: "#888",
        fillOpacity: 0.1,
      },
    });
    this.boundariesJSONLayer.addTo(this.map!);
    this.boundariesJSONLayer.bringToBack();

    if (shouldFitBounds) {
      this.map?.fitBounds(this.boundariesJSONLayer.getBounds());
    }
    this.createRemoveAreasOutlinesControl();
  }

  setAreasOutlinesVisible(visible: boolean) {
    if (visible === this.areasOutlinesVisible) return;

    this.areasOutlinesVisible = visible;
    if (this.areasOutlinesVisible) {
      this.showAreasOutlines();
    } else {
      this.removeAreasOutlines();
    }
    this.showAreasButton(this.areasOutlinesVisible);
    this.updateMapBoundsParam();
  }

  showAreasOutlines() {
    if (this.areasOutlinesVisible) {
      this.boundariesJSONLayer?.addTo(this.map!);
      this.boundariesJSONLayer?.bringToBack();
      this.updateTooltipLayer();
    }
  }

  removeAreasOutlines() {
    this.boundariesJSONLayer?.remove();
  }

  hideAreasOutlines() {
    this.boundariesJSONLayer?.remove();
  }

  hideAreasButton() {
    this.removeAreasControlLayer?.remove();
  }

  showAreasButton(outlinesVisibles: boolean) {
    this.removeAreasControlLayer?.addTo(this.map!);
    const buttonDiv = this.removeAreasControlLayer?.getContainer()?.children[0];
    if (buttonDiv) {
      buttonDiv.innerHTML = `<div class="flex items-center justify-center">
        <svg 
          fill="currentColor" 
          viewBox="0 0 24 24" 
          xmlns="http://www.w3.org/2000/svg" 
          class="size-5"
        >
          <path d="M21,18.3V5.7c0.6-0.3,1-1,1-1.7c0-1.1-0.9-2-2-2c-0.7,0-1.4,0.4-1.7,1H5.7C5.4,2.4,4.7,2,4,2C2.9,2,2,2.9,2,4c0,0.7,0.4,1.4,1,1.7v12.6c-0.6,0.3-1,1-1,1.7c0,1.1,0.9,2,2,2c0.7,0,1.4-0.4,1.7-1h12.6c0.3,0.6,1,1,1.7,1c1.1,0,2-0.9,2-2C22,19.3,21.6,18.6,21,18.3z M19,18.3c-0.3,0.2-0.5,0.4-0.7,0.7H5.7c-0.2-0.3-0.4-0.5-0.7-0.7V5.7C5.3,5.5,5.5,5.3,5.7,5h12.6c0.2,0.3,0.4,0.5,0.7,0.7V18.3z" />
          ${outlinesVisibles ? `<line x1="0" y1="24" x2="24" y2="0" stroke="currentColor" stroke-width="1.5"/>` : ""}
        </svg>
      </div>
      <div class="mx-1">${outlinesVisibles ? "REMOVE OUTLINE" : "SHOW OUTLINE"}</div>
    </div>`;
    }
  }

  //
  // Appreciation related methods
  //
  createAppreciationLayers() {
    this.appreciationType = this.onlyAppreciation ? "pct_growth_one_year" : "none";
    !isMobile && (this.appreciationControlLayer = this.createAppreciationControls());
    this.appreciationControlLayer?.addTo(this.map!);
    this.appreciationLegendLayer = this.createAppreciationLegends();
    if (this.onlyAppreciation) {
      this.appreciationLegendLayer.addTo(this.map!);
    }
  }

  createAppreciationButton(container: HTMLDivElement, controlType: AppreciationPeriodType) {
    const selected = this.appreciationType === controlType;
    const div = L.DomUtil.create("div", "mapControlItem", container);
    const textDiv = L.DomUtil.create("div", "mapControlText" + (selected ? " active" : ""), div);
    this.mapControlTexts[controlType] = textDiv;
    textDiv.innerHTML = controlsLabel[controlType as keyof typeof controlsLabel];
    div.addEventListener("click", () => {
      this.onClickAppreciationControl(controlType);
    });
  }

  createAppreciationControls() {
    const that = this;
    const MyControls = L.Control.extend({
      options: {
        position: "topleft",
      },

      onAdd: function (map: L.Map) {
        const container = L.DomUtil.create("div", "pellego-leaflet-control");
        container.setAttribute("id", "browseMapControls");
        L.DomEvent.disableClickPropagation(container);
        L.DomEvent.disableScrollPropagation(container);

        const buttonDiv = L.DomUtil.create("div", "mapControl", container);
        buttonDiv.innerHTML = `APPRECIATION
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#000" aria-hidden="true" class="ml-2 h-4 w-4 text-st-darkest"><path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path></svg>
        `;

        const dropDownContainer = L.DomUtil.create("div", "dropDownContainer", container);

        if (!that.onlyAppreciation) {
          that.createAppreciationButton(dropDownContainer, "none");
        }
        that.createAppreciationButton(dropDownContainer, "pct_growth_three_months");
        that.createAppreciationButton(dropDownContainer, "pct_growth_one_year");
        that.createAppreciationButton(dropDownContainer, "pct_growth_three_years");

        container.addEventListener("click", (e) => {
          e.stopPropagation();
          buttonDiv.classList.toggle("show");
          dropDownContainer.classList.toggle("show");
        });

        return container;
      },
    });

    return new MyControls();
  }

  createAppreciationLegends() {
    const that = this;
    const LegendControl = L.Control.extend({
      options: {
        position: "topleft",
      },

      onAdd: function (map: L.Map) {
        const container = L.DomUtil.create("div", `pellego-appreciation-legend ${isMobile ? "mobile" : ""}`);
        container.setAttribute("id", "browseMapLegend");
        L.DomEvent.disableClickPropagation(container);
        L.DomEvent.disableScrollPropagation(container);

        that.createLegendTable(container);
        return container;
      },
    });

    return new LegendControl();
  }

  createLegendTable(container: HTMLDivElement) {
    const table = L.DomUtil.create("table", "legendTable", container);
    const pctPctMultiplier = AppreciationScaleMuliplier[this.appreciationType];

    colorScale
      .slice()
      .reverse()
      .forEach((color, idx) => {
        if (idx % 3 === 0) {
          const tr = L.DomUtil.create("tr", undefined, table);
          const tdColor = L.DomUtil.create("td", "lgColor", tr);
          const tdText = L.DomUtil.create("td", "lgText", tr);
          tdColor.style.backgroundColor = color[0];
          tdText.innerHTML = `+${((15 - idx) * pctPctMultiplier).toFixed(pctPctMultiplier < 1 ? 1 : 0)}%`;
        }
      });
    const tr = L.DomUtil.create("tr", undefined, table);
    const tdColor = L.DomUtil.create("td", "lgColor", tr);
    const tdText = L.DomUtil.create("td", "lgText", tr);
    tdColor.style.backgroundColor = "#fff";
    tdText.innerHTML = `0%`;
    colorScale.forEach((color, idx) => {
      if (idx % 3 === 2) {
        const tr = L.DomUtil.create("tr", undefined, table);
        const tdColor = L.DomUtil.create("td", "lgColor", tr);
        const tdText = L.DomUtil.create("td", "lgText", tr);
        tdColor.style.backgroundColor = color[1];
        tdText.innerHTML = `-${((idx + 1) * pctPctMultiplier).toFixed(pctPctMultiplier < 1 ? 1 : 0)}%`;
      }
    });
  }

  onClickAppreciationControl(controlType: AppreciationPeriodType) {
    if (this.appreciationType === controlType) return;

    !isMobile && this.mapControlTexts[this.appreciationType].classList.remove("active");
    !isMobile && this.mapControlTexts[controlType].classList.add("active");
    this.appreciationType = controlType;

    if (this.appreciationGeoJSONLayer) {
      this.map?.removeLayer(this.appreciationGeoJSONLayer);
      this.appreciationGeoJSONLayer = null;
    }

    if (this.appreciationType === "none") {
      this.appreciationLegendLayer?.remove();
    } else {
      this.appreciationLegendLayer?.addTo(this.map!);
      if (!this.locationsAppreciations) {
        this.fetchAppreciationData();
      } else {
        this.addAppreciationPolygons();
      }
    }
  }

  fetchAppreciationData() {
    if (!this.map) return;

    let fetcher: Promise<any>;
    if (this.areasOutlinesVisible) {
      fetcher = this.mapRequirements.fetchLocationAppreciations();
    } else {
      const mapBounds = this.map.getBounds();
      const se = mapBounds.getSouthEast();
      const nw = mapBounds.getNorthWest();
      fetcher = this.mapRequirements.fetchBoxAppreciations(se.lat, se.lng, nw.lat, nw.lng);
    }

    fetcher.then((locAppr) => {
      this.locationsAppreciations = locAppr;
      this.setMapAppreciations(locAppr);
      this.addAppreciationPolygons();
    });
  }

  colorlayer = (feature: any, layer: any) => {
    const color = getHtmlColorFromPct(feature.properties[this.appreciationType], this.appreciationType);
    layer.setStyle({
      fillColor: color,
    });
    layer
      .bindTooltip(
        `Zip code: <b>${feature.properties.zipCode}</b><br/>
          Appreciation:<br/>
          &nbsp;3M: <b>${feature.properties.pct_growth_three_months.toFixed(2)}%</b><br/>
          &nbsp;1Y: <b>${feature.properties.pct_growth_one_year.toFixed(2)}%</b><br/>
          &nbsp;3Y: <b>${feature.properties.pct_growth_three_years.toFixed(2)}%</b>`,
        {
          direction: "right",
          offset: [10, 0],
          className: "mapTooltip",
          sticky: true,
        },
      )
      .openTooltip();
  };

  addAppreciationPolygons() {
    if (!this.locationsAppreciations || this.appreciationType === "none") return;

    const zipCodes = Object.keys(this.locationsAppreciations);
    if (zipCodes.length === 0) return;
    // try {
    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: "FeatureCollection",
      features: zipCodes.map((zipCode) => {
        return {
          type: "Feature",
          properties: {
            zipCode,
            pct_growth_three_months: this.locationsAppreciations![zipCode].pct_growth_three_months,
            pct_growth_one_year: this.locationsAppreciations![zipCode].pct_growth_one_year,
            pct_growth_three_years: this.locationsAppreciations![zipCode].pct_growth_three_years,
          },
          geometry: this.locationsAppreciations![zipCode].geom,
        };
      }),
    };

    this.appreciationGeoJSONLayer = L.geoJSON(featureCollection, {
      onEachFeature: this.colorlayer,
      style: {
        weight: 1,
        fillOpacity: 0.55,
        color: "#CCC",
      },
    });
    this.map?.addLayer(this.appreciationGeoJSONLayer);
    this.appreciationGeoJSONLayer.bringToBack();
    this.boundariesJSONLayer?.bringToBack();
    this.toolTipLayer?.bringToBack();

    if (this.onlyAppreciation) {
      this.map?.fitBounds(this.appreciationGeoJSONLayer.getBounds());
      this.appreciationLegendLayer?.addTo(this.map!);
    }
  }

  hideAppreciationsLayers() {
    this.appreciationGeoJSONLayer?.remove();
    this.appreciationControlLayer?.remove();
    this.appreciationLegendLayer?.remove();
  }

  showAppreciationsLayers() {
    this.appreciationGeoJSONLayer?.addTo(this.map!);
    this.appreciationGeoJSONLayer?.bringToBack();
    this.appreciationControlLayer?.addTo(this.map!);
    if (this.appreciationType !== "none") {
      this.appreciationLegendLayer?.addTo(this.map!);
    }
  }

  updateAppreciations() {
    if (this.appreciationGeoJSONLayer) {
      this.map?.removeLayer(this.appreciationGeoJSONLayer);
      this.appreciationGeoJSONLayer = null;
    }
    if (this.locationsAppreciations) {
      this.fetchAppreciationData();
    }
  }

  clearMap() {
    this.map?.off();
    this.map?.remove();
  }

  updatePropertiesMarkers(
    viewedProperties: Record<number, boolean>,
    staredProperties: Record<number, boolean>,
  ) {
    this.viewedProperties = viewedProperties;
    this.staredProperties = staredProperties;

    // Update only the markers layer
    if (this.markersClusterLayer) {
      this.markersClusterLayer.eachLayer((layer: L.Layer) => {
        if (layer instanceof L.Marker) {
          const markerIdx = Object.values(this.idtoMarkerIdx).find(
            (idx) =>
              this.properties[idx] &&
              layer.getLatLng().lat === this.properties[idx].latitude &&
              layer.getLatLng().lng === this.properties[idx].longitude,
          );

          if (markerIdx !== undefined) {
            const property = this.properties[markerIdx];
            const isSelected = markerIdx === this.selectedIdx;
            layer.setIcon(
              this.getMarkerIcon(
                property,
                isSelected,
                viewedProperties?.[property?.parcel_id],
                staredProperties?.[property?.parcel_id],
              ),
            );
          }
        }
      });
    }

    // Update highlighted marker if exists
    if (this.highlightedMarker && this.selectedIdx !== undefined) {
      const property = this.properties[this.selectedIdx];
      this.highlightedMarker.setIcon(
        this.getMarkerIcon(
          property,
          true,
          viewedProperties?.[property?.parcel_id],
          staredProperties?.[property?.parcel_id],
        ),
      );
    }
  }
}

/**
 * FROM: https://makinacorpus.github.io/Leaflet.GeometryUtil/leaflet.geometryutil.js.html#line713

   Returns the point that is a distance and heading away from
    the given origin point.
    @param {L.LatLng} latlng: origin point
    @param {float} heading: heading in degrees, clockwise from 0 degrees north.
    @param {float} distance: distance in meters
    @returns {L.latLng} the destination point.
    Many thanks to Chris Veness at http://www.movable-type.co.uk/scripts/latlong.html
    for a great reference and examples.
 */
export const destination = (latlng: L.LatLng, heading: number, distance: number) => {
  heading = (heading + 360) % 360;
  const rad = Math.PI / 180;
  const radInv = 180 / Math.PI;
  const R = 6378137; // approximation of Earth's radius
  const lon1 = latlng.lng * rad;
  const lat1 = latlng.lat * rad;
  const rheading = heading * rad;
  const sinLat1 = Math.sin(lat1);
  const cosLat1 = Math.cos(lat1);
  const cosDistR = Math.cos(distance / R);
  const sinDistR = Math.sin(distance / R);
  const lat2 = Math.asin(sinLat1 * cosDistR + cosLat1 * sinDistR * Math.cos(rheading));
  const lon2 =
    lon1 + Math.atan2(Math.sin(rheading) * sinDistR * cosLat1, cosDistR - sinLat1 * Math.sin(lat2));
  const lon2RadInv = lon2 * radInv;
  const lon2Deg = lon2RadInv > 180 ? lon2RadInv - 360 : lon2RadInv < -180 ? lon2RadInv + 360 : lon2RadInv;
  return L.latLng([lat2 * radInv, lon2Deg]);
};
