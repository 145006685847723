import { isPellego } from "config";
import { cn } from "helpers";
import { useLocation } from "react-router";

const statesInfo = [
  {
    name: "Alabama",
    brokerage: "Lotside LLC",
    license: "AREC #000162891-0",
    agent: "Joel Smith Hinckley, Broker",
    address1: "2100 Southbridge Parkway #650",
    address2: "Homewood, AL 35209",
  },
  {
    name: "California",
    brokerage: "Pellego, Inc.",
    license: "DRE #02043232",
    agent: "Joseph Homs, Broker",
    address1: "23121 Verdugo Dr, #100",
    address2: "Laguna Hills, CA 92653",
  },
  {
    name: "Connecticut",
    brokerage: "Coming soon!",
    license: "",
    agent: "",
    address1: "",
    address2: "",
  },
  {
    name: "Florida",
    brokerage: "Pellego LLC",
    license: "DBPR #CQ1061370",
    agent: "Violet Rose, Broker",
    address1: "2560 NE 11th Ct E",
    address2: "Pompano Beach, FL 33062",
  },
  {
    name: "Georgia",
    brokerage: "Lotside LLC",
    license: "RECAB #81363",
    agent: "Angela Kearns, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "Kansas",
    brokerage: "Lotside LLC",
    license: "KREC #CO00003637",
    agent: "Joel S Hinckley, Broker",
    address1: "997 Sage Creek Court",
    address2: "Heber City, UT 84032",
  },
  {
    name: "Massachusetts",
    brokerage: "Lotside LLC",
    license: "DOL #42430",
    agent: "Thomas L McCreary Jr, Broker",
    address1: "136 Plantation St",
    address2: "Worcester, MA 01604",
  },
  {
    name: "Michigan",
    brokerage: "Lotside LLC",
    license: "LARA #6505432818",
    agent: "Joel S Hinckley, Broker",
    address1: "860 Washtenaw Ave Ste i #350",
    address2: "Ann Arbor, MI 48108",
  },
  {
    name: "Minnesota",
    brokerage: "Coming soon!",
    license: "",
    agent: "",
    address1: "",
    address2: "",
  },
  {
    name: "Missouri",
    brokerage: "Lotside LLC",
    license: "MREC #2024002965",
    agent: "Joel S Hinckley, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "New Jersey",
    brokerage: "Coming soon!",
    license: "",
    agent: "",
    address1: "",
    address2: "",
  },
  {
    name: "New Mexico",
    brokerage: "Lotside LLC",
    license: "NMREC #REC-2024-0053",
    agent: "Joel Smith Hinckley, Broker",
    address1: "997 Sage Creek Court",
    address2: "Heber City, UT 84032",
  },
  {
    name: "New York",
    brokerage: "Lotside LLC",
    license: "DSLS #10991239526",
    agent: "Thomas L McCreary Jr, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "North Carolina",
    brokerage: "Lotside LLC",
    license: "NCREC #C39072",
    agent: "Violet Michelle Rose, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "Oregon",
    brokerage: "Pellego LLC",
    license: "OREA #201254120",
    agent: "Jennifer McLaughlin, Broker",
    address1: "500 SW 116th Ave #214",
    address2: "Beaverton, OR 97225",
  },
  {
    name: "Pennsylvania",
    brokerage: "Lotside LLC",
    license: "REC #RB069904",
    agent: "Thomas L McCreary Jr, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "South Carolina",
    brokerage: "Coming soon!",
    license: "",
    agent: "",
    address1: "",
    address2: "",
  },
  {
    name: "Tennessee",
    brokerage: "Lotside LLC",
    license: "DCI #266298",
    agent: "Violet Rose, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "Texas",
    brokerage: "Lotside LLC",
    license: "TREC #9014866",
    agent: "Von Stowe Sutten, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "Utah",
    brokerage: "Coming soon!",
    license: "",
    agent: "",
    address1: "",
    address2: "",
  },
  {
    name: "Virginia",
    brokerage: "Lotside LLC",
    license: "DPOR #0226037220",
    agent: "Joel Smith Hinckley, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "Washington",
    brokerage: "Pellego, Inc.",
    license: "DOL #20141",
    agent: "Gideon Sylvan, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "Washington",
    brokerage: "Lotside LLC",
    license: "DOL #23033587",
    agent: "Gideon Sylvan, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "Washington",
    brokerage: "Pellego Partners LLC",
    license: "DOL #24014578",
    agent: "Jennifer McLaughlin, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  {
    name: "Wisconsin",
    brokerage: "Lotside LLC",
    license: "DSPS #950109-91",
    agent: "Thomas J Karras, Broker",
    address1: "5603 230th St SW",
    address2: "Mountlake Terrace, WA 98043",
  },
  // Empties placeholders for formatting purposes
  {
    name: "",
    brokerage: "",
    license: "",
    agent: "",
    address1: "",
    address2: "",
  },
  {
    name: "",
    brokerage: "",
    license: "",
    agent: "",
    address1: "",
    address2: "",
  },
  {
    name: "",
    brokerage: "",
    license: "",
    agent: "",
    address1: "",
    address2: "",
  },
];

type Props = {
  stateId?: number;
  browsePage?: boolean;
};

export const Footer = ({ browsePage = false }: Props) => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  return (
    <div className={cn("bg-[#4d4d4d]", !isHome && "md:mt-10")}>
      <div className="flex max-w-7xl flex-col items-center justify-center px-4 pb-10 pt-5 md:mx-auto">
        <div
          className={cn(
            "mb-0 grid w-full gap-6 p-2 text-white sm:mb-10",
            !browsePage && "grid-cols-2 md:mt-20 md:grid-cols-4",
            browsePage && "mt-20 grid-cols-1 xl:grid-cols-2 3xl:grid-cols-4",
          )}
        >
          <div className="text-sm leading-7">
            <strong>Menu</strong>
            <ul className="text-st-soft">
              <li>
                <a href={`https://${isPellego ? "pellego" : "lotside"}.com`}>Home</a>
              </li>
              <li>
                <a href={`https://www.${isPellego ? "pellego" : "lotside"}.com/how-it-works`}>Features</a>
              </li>
              <li>
                <a href={`https://www.${isPellego ? "pellego" : "lotside"}.com/pro`}>Plans</a>
              </li>
              <li>
                <a href={`https://www.${isPellego ? "pellego" : "lotside"}.com/about`}>About</a>
              </li>
            </ul>
          </div>
          <div className="text-sm leading-7">
            <strong>Social</strong>
            <ul className="text-st-soft">
              <li>
                <a href="https://www.facebook.com/pellegorealty/" target="_blank" rel="noreferrer">
                  Follow us on facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=Wwpy436vsBo&amp;list=PLtD4SOHqq7bdhd-7DJkTxc7aB-QV2GNUs"
                  target="_blank"
                  rel="noreferrer"
                >
                  How to Use {isPellego ? "Pellego" : "Lotside"}
                </a>
              </li>
            </ul>
          </div>
          <div className="text-sm leading-7">
            <strong>Legal</strong>
            <ul className="text-st-soft">
              <li>
                <a
                  href="https://cdn.davinci.pellego.com/static/resources/TermsofService.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="https://cdn.davinci.pellego.com/static/resources/PrivacyPolicy.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
              </li>
            </ul>
          </div>
          <div className="text-sm leading-7">
            <strong>Contact</strong>
            <ul className="text-st-soft">
              <li className="">
                <a href="tel:(425)441-3800">(425) 441-3800</a>
              </li>
              <li>
                <a href={`mailto:contact@${isPellego ? "pellego" : "lotside"}.com`}>
                  contact@{isPellego ? "pellego" : "lotside"}.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-st-soft my-6 grid max-w-7xl grid-cols-1 gap-6 p-0 text-xs sm:p-2">
          <p>
            DISCLAIMER: The financial estimates on this website, or otherwise from this website or people
            affiliated with this website, should not substitute your own due diligence. We strongly recommend
            that you conduct your own research and arrive at your own conclusions. Furthermore, while we try
            to keep our data up to date, the information we are provided is sometimes incomplete or not
            compatible with our systems, leading to misinformation. Please remember all estimates are computer
            generated and prone to inaccuracies.
          </p>
          <p>
            DISCLOSURE: We are a licensed real estate brokerage in Alabama, California, Connecticut, Florida,
            Georgia, Kansas, Massachusetts, Michigan, Minnesota, New Jersey, New Mexico, New York, North
            Carolina, Oregon, Pennsylvania, South Carolina, Tennessee, Texas, Utah, Virginia, Washington, and
            Wisconsin. We are a member of one or more multiple listing services in each of those states. In
            other states where listing data is displayed, the data is served in partnership with a local
            brokerage. Our brokerage information:
          </p>
          <div className="mt-4 flex flex-row flex-wrap justify-around gap-5 sm:gap-6">
            {statesInfo.map((state, index) => (
              <div key={index} className="flex w-40 flex-col justify-start sm:w-48">
                <div className="font-bold">{state?.name}</div>
                <div>{state?.brokerage}</div>
                <div>{state?.license}</div>
                <div>{state?.agent}</div>
                <div>{state?.address1}</div>
                <div>{state?.address2}</div>
              </div>
            ))}
          </div>
          <p>
            In addition to the general disclaimers listed above, we also have some state and MLS specific
            disclosures:
          </p>
          <p>
            California (via MLSListings) - This data is updated every 30 minutes at minimum. Some properties
            appearing for sale on this display may subsequently have sold and may no longer be available. The
            data relating to real estate for sale on this display comes in part from the Internet Data
            Exchange program of the MLSListings™ MLS system. Real estate listings held by brokerage firms
            other than Pellego are marked with the Internet Data Exchange and detailed information about them
            includes the names of the listing brokers and listing agents.
          </p>
          <p>
            California (via San Diego MLS) - This information is deemed reliable but not guaranteed. You
            should rely on this information only to decide whether or not to further investigate a particular
            property. BEFORE MAKING ANY OTHER DECISION, YOU SHOULD PERSONALLY INVESTIGATE THE FACTS (e.g.
            square footage and lot size) with the assistance of an appropriate professional. You may use this
            information only to identify properties you may be interested in investigating further. All uses
            except for personal, non- commercial use in accordance with the foregoing purpose are prohibited.
            Redistribution or copying of this information, any photographs or video tours is strictly
            prohibited. This information is derived from the Internet Data Exchange (IDX) service provided by
            San Diego MLS. Displayed property listings may be held by a brokerage firm other than the broker
            and/or agent responsible for this display. The information and any photographs and video tours and
            the compilation from which they are derived is protected by copyright. Compilation © 2023 San
            Diego MLS.
          </p>
          <p>
            Georgia (via FMLS) - Information Deemed Reliable But Not Guaranteed. If you believe any FMLS
            listing contains material that infringes your copyrighted work please click here to review our
            DMCA policy and learn how to submit a takedown request. © 2023 FMLS
          </p>
          <p>
            Oregon (via RMLS) - The content relating to real estate for sale on this web site comes in part
            from the IDX program of the RMLS of Portland, Oregon. Real estate listings held by brokerage firms
            other than (Site owning Broker company name goes here) are marked with the RMLS logo, and detailed
            information about these properties includes the names of the listing brokers. Listing content is
            copyright © 2023 RMLS, Portland, Oregon. All information provided is deemed reliable, but is not
            guaranteed and should be independently verified. Some properties appearing for sale on this
            display may subsequently have sold and may no longer be available.
          </p>
          <p>
            Texas (via San Antonio Board of Realtors) - The data relating to real estate for sale on this web
            site comes in part from the Broker Reciprocity Program of the San Antonio Board of Realtors. Real
            estate listings held by brokerage firms are indicated by detailed information about them such as
            the name of the listing firms. © 2024 San Antonio Board of Realtors, All Rights Reserved.
          </p>
          <p>
            The information on this web site is provided exclusively for consumers, personal, non-commercial
            use and may not be used for any purpose other than to identify prospective properties consumers
            may be interested in purchasing. Properties subject to prior sale, change, withdrawal or rental.
            We are not responsible or liable for misinformation, misprints, or typographical errors. All
            information provided is deemed reliable but is not guaranteed and should be independently verified
            through personal inspection by and/or with the appropriate professionals. Data displayed may be a
            portion of, and not a complete set of all listings published in the MLS.
          </p>
        </div>
      </div>
    </div>
  );
};
