import { appConfig } from "config";
import { MultiPolygon, Position } from "geojson";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

type LocationParamsType = {
  city: number[];
  county: number[];
  metro_area: number[];
  neighborhood: number[];
  zip_code: string[];
};

type BaseType = {
  id: number;
  name: string;
};

type StateType = BaseType & {
  abbreviation: string;
};

type BaseWithStateType = BaseType & {
  state_id: number;
};

type CityType = BaseWithStateType & {
  county_id: number;
};

type MetroAreaType = BaseWithStateType & {
  state_ids: number[];
};

type ZipCodeType = {
  id: string;
  state_id: number;
};

export type LocationResponseType = {
  state: StateType[];
  county: BaseWithStateType[];
  city: CityType[];
  metro_area: MetroAreaType[];
  neighborhood: BaseWithStateType[];
  school_district: BaseWithStateType[];
  zip_code: ZipCodeType[];
};

type useBrowseApiRequirementsType = {
  isLoading: boolean;
  error: any;
  locationData: LocationResponseType | null;
  polygonsData: MultiPolygon[] | null;
  geoJsonData: Position[][][] | null;
};

export const validSearchParams = ["city", "county", "metro_area", "neighborhood", "zip_code"];

type multiFetchType = {
  url: string;
  type: "locations" | "polygon" | "geoId";
};

const fetchAll = async (allRequests: multiFetchType[]): Promise<any> => {
  return Promise.all(allRequests.map((item) => fetcherWithCredentials(item.url)));
};

export const useBrowseApiRequirements = (
  locationParams: LocationParamsType,
  geoId: number | null,
): useBrowseApiRequirementsType => {
  const newSearchParams = new URLSearchParams();
  const allRequests: multiFetchType[] = [];

  // Collect valid parameters
  validSearchParams.forEach((key) => {
    locationParams[key as keyof LocationParamsType].forEach((value) => {
      if (key === "zip_code") {
        value = value.toString().padStart(5, "0");
      }
      newSearchParams.append(key, value.toString());

      const locationPolygonUrl = appConfig.apiEndpoint.locationPolygon
        .replace(":type", key)
        .replace(":id", value.toString());
      allRequests.push({ type: "polygon", url: locationPolygonUrl });
    });
  });

  if (geoId) {
    allRequests.push({ type: "geoId", url: `${appConfig.apiEndpoint.locationGeoJson}/${geoId}` });
  }

  // Construct full locations URL
  const locationsUrl = newSearchParams.toString()
    ? `${appConfig.apiEndpoint.locations}?${newSearchParams}`
    : undefined;

  allRequests.push({ type: "locations", url: locationsUrl || "" });

  const { data, error, isLoading } = useSWR<any[] | undefined>(allRequests, fetchAll, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  let locationData: LocationResponseType | null = null;
  let polygonsData: MultiPolygon[] | null = null;
  let geoJsonData: Position[][][] | null = null;

  if (data) {
    allRequests.forEach((request, index) => {
      switch (request.type) {
        case "locations":
          locationData = data[index];
          break;
        case "polygon":
          polygonsData = polygonsData || [];
          polygonsData.push(data[index]);
          break;
        case "geoId":
          geoJsonData = data[index].coordinates;
          break;
      }
    });
  }

  return {
    isLoading: isLoading,
    error,
    locationData,
    polygonsData,
    geoJsonData,
  };
};
