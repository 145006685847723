import { cn, formatCurrency } from "helpers";
import React from "react";
import { isMobile } from "react-device-detect";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

export const PieChartSection = ({
  data,
  selectedSector,
  setSelectedSector,
  equity,
  marketValue,
}: {
  data: any;
  selectedSector: any;
  setSelectedSector: any;
  equity: any;
  marketValue: any;
}) => (
  <div
    className={cn("relative flex h-full flex-col items-center text-xs", isMobile ? "w-[190px]" : "w-[200px]")}
  >
    <div className="relative w-full" style={{ paddingBottom: "100%" }}>
      <div className="absolute inset-0">
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            {/* Middle labels */}
            <text
              fill={selectedSector !== null ? data[selectedSector].normalColor : "#4cb598"}
              x="50%"
              y={selectedSector === null || data[selectedSector].name !== "Down Payment" ? "53%" : "49%"}
              textAnchor="middle"
              dominantBaseline="middle"
            >
              <tspan x="50%" dy="-0.5em" className="text-[22px] font-medium">
                {selectedSector !== null
                  ? ((data[selectedSector].value / marketValue) * 100).toFixed(0) + "%"
                  : ((equity / marketValue) * 100).toFixed(0) + "%"}
              </tspan>
              <tspan x="50%" dy="1.2em" className="text-[13px] font-light uppercase">
                {selectedSector === null
                  ? "equity"
                  : data[selectedSector].name !== "Down Payment"
                    ? data[selectedSector].name
                    : "Down"}
              </tspan>
              {selectedSector !== null && data[selectedSector].name === "Down Payment" && (
                <tspan x="50%" dy="1.2em" className="text-[13px] font-light uppercase">
                  Payment
                </tspan>
              )}
            </text>

            {/* Donut draw */}
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius="52%"
              outerRadius="100%"
              startAngle={180}
              endAngle={-180}
              className="focus:outline-none"
            >
              {data.map((_: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={selectedSector === index ? data[index].hoverColor : data[index].normalColor}
                  onMouseEnter={() => setSelectedSector(index)}
                  onMouseLeave={() => setSelectedSector(null)}
                  className="focus:outline-none"
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
);

export const TooltipSection = ({
  selectedSector,
  data,
  loansSpecsFiltered,
}: {
  selectedSector: any;
  data: any;
  loansSpecsFiltered: any;
}) =>
  selectedSector !== null && loansSpecsFiltered.length > 0 ? (
    <div className="absolute left-1/2 top-[calc(100%+0.5rem)] w-fit -translate-x-1/2 transform rounded-md border border-gray-100 bg-white p-2 shadow-sm">
      <table className="w-full">
        <tbody>
          <tr
            className="h-6 whitespace-nowrap text-xs font-bold"
            style={{ color: data[selectedSector].normalColor }}
          >
            <td>{data[selectedSector].name + (loansSpecsFiltered.length > 1 ? "s" : "")}</td>
            <td className="pl-5 text-right">{formatCurrency(data[selectedSector].value, 0)}</td>
          </tr>
          {((data[selectedSector].name === "Paid Down" && loansSpecsFiltered.length > 1) ||
            data[selectedSector].name === "Principal") &&
            loansSpecsFiltered.map((loan: any, loanIndex: any) => (
              <tr key={loanIndex} className="whitespace-nowrap text-[10px]">
                <td className="w-1/2 font-medium">{loan["Loan Type"]}:&nbsp;</td>
                <td className="w-1/2 pl-5 text-right">
                  {data[selectedSector].name === "Paid Down" &&
                    formatCurrency(loan.Amount - loan["Remaining Principal"], 0)}
                  {data[selectedSector].name === "Principal" && (
                    <span>
                      {formatCurrency(loan["Remaining Principal"], 0)} | {loan["Interest Rate"]}
                    </span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  ) : null;

export const RightColumn = ({
  loansSpecsFiltered,
  marketValue,
  lastSalePrice,
  appreciation,
  totalRemainingPrincipals,
  totalOriginalPrincipals,
  totalPayDowns,
  equity,
}: {
  loansSpecsFiltered: any;
  marketValue: any;
  lastSalePrice: any;
  appreciation: any;
  totalRemainingPrincipals: any;
  totalOriginalPrincipals: any;
  totalPayDowns: any;
  equity: any;
}) => (
  <div className="mx-auto mt-2 flex h-full min-w-fit max-w-full shrink-0 flex-col text-sm xl:mt-0">
    <table className="w-full">
      <tbody>
        {[
          ...(!(loansSpecsFiltered.length === 0 && !lastSalePrice)
            ? [
                { label: "Value", value: marketValue, isBold: true },
                { label: "Last Sale", value: lastSalePrice || "-", isGray: true },
                { label: "Appreciation", value: appreciation || "-", isGray: true },
              ]
            : []),
          ...(loansSpecsFiltered.length > 0
            ? [
                {
                  label: "Principal" + (loansSpecsFiltered.length > 1 ? "s" : ""),
                  value: totalRemainingPrincipals,
                  isBold: true,
                },
                {
                  label: "Initial Loan" + (loansSpecsFiltered.length > 1 ? "s" : ""),
                  value: totalOriginalPrincipals,
                  isGray: true,
                },
                {
                  label: "Paid Down" + (loansSpecsFiltered.length > 1 ? "s" : ""),
                  value: totalPayDowns,
                  isGray: true,
                },
              ]
            : []),
          { label: "Equity", value: equity, isBold: true },
        ].map(({ label, value, isBold, isGray }, index) => (
          <tr
            key={index}
            className={`mb-1 flex items-center justify-start ${isBold ? "font-medium" : ""} ${isGray ? "text-st-lightest" : ""}`}
          >
            <td className="mr-4 w-full text-right">
              {typeof value === "number" ? formatCurrency(value, 0) : value}
            </td>
            <td className="w-[90px] shrink-0 pl-1 text-left">{label}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
