import { PeriodSelector } from "components";
import { useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { RentalAppreciationType, SaleAppreciationType, useProforma } from "state/proformas";

export const SERIES_COLORS: { normalColor: string; lightColor: string }[] = [
  { normalColor: "#FFA500", lightColor: "#FFA500" },
  { normalColor: "#777777", lightColor: "#777777" },
  { normalColor: "#000000", lightColor: "#000000" },
  { normalColor: "#45AB8F", lightColor: "#45AB8F" },
];

type AppreciationProps<T extends "sale" | "rental"> = {
  type: T;
  data: T extends "sale" ? SaleAppreciationType : RentalAppreciationType;
};

export const AppreciationGraph = <T extends "sale" | "rental">({ type, data }: AppreciationProps<T>) => {
  const { proforma } = useProforma();

  const lastListingPrice = proforma.parcel?.listing_history?.slice(0, 1);
  const lines: any[] = [];

  const [period, setPeriod] = useState(0);

  const sqFtPricesCounty = data?.county_ppsqft?.slice(-period);
  const sqFtPricesCity = data?.city_ppsqft?.slice(-period);
  const sqFtPricesMetroArea = data?.metro_area_ppsqft?.slice(-period);
  const sqFtPricesAppreciation =
    type === "sale"
      ? (data as SaleAppreciationType)?.appreciation?.slice(-period)
      : (data as RentalAppreciationType)?.appreciation?.slice(-period);
  const historicalSales =
    type === "sale" ? (data as SaleAppreciationType).historical_sales?.slice(-period) : [];
  const geo_attribute_for_appreciation = data?.geo_attribute_for_appreciation;
  const dates = data?.dates?.slice(-period);
  const selectorValues = {
    "1Y": 13,
    "3Y": 37,
    "10Y": 121,
    All: 0,
  };

  // Build the graph title
  let graphTitle;
  if (type === "sale") {
    const saleData = data as SaleAppreciationType;
    if (saleData.structure_type_for_appreciation === "all") {
      graphTitle = "Median Sale";
    } else if (saleData.structure_type_for_appreciation === "Multifamily") {
      graphTitle = saleData.structure_type_for_appreciation + " Median Sale";
    } else {
      graphTitle = saleData.structure_type_for_appreciation + "s Median Sale";
    }
  } else {
    const rentalData = data as RentalAppreciationType;
    if (rentalData.bedrooms_for_rental_appreciation === "all") {
      graphTitle = "Rent over time";
    } else {
      graphTitle = rentalData.bedrooms_for_rental_appreciation + " Bedroom Rent Over Time";
    }
  }

  const getLocationsToShow = () => {
    const availableLocations: any = {};

    // Check County data
    if (sqFtPricesCounty?.length > 0 || geo_attribute_for_appreciation === "county") {
      availableLocations.county = {
        legend: proforma.parcel?.county ? `${proforma.parcel?.county} Co.` : "",
        price: sqFtPricesCounty.length > 0 ? sqFtPricesCounty : sqFtPricesAppreciation,
      };
    }

    // Check City data
    if (sqFtPricesCity?.length > 0 || geo_attribute_for_appreciation === "city") {
      availableLocations.city = {
        legend: proforma.parcel?.city ? `${proforma.parcel?._characteristics?.city_for_address}` : "",
        price: sqFtPricesCity.length > 0 ? sqFtPricesCity : sqFtPricesAppreciation,
      };
    }

    // Check Metro data
    if (sqFtPricesMetroArea?.length > 0 || geo_attribute_for_appreciation === "metro") {
      availableLocations.metro = {
        legend: "Metro",
        price: sqFtPricesMetroArea.length > 0 ? sqFtPricesMetroArea : sqFtPricesAppreciation,
      };
    }

    // Check Neighborhood data
    if (
      type === "sale" &&
      sqFtPricesAppreciation?.length > 0 &&
      geo_attribute_for_appreciation === "neighborhood"
    ) {
      availableLocations.neighborhood = {
        legend: proforma.parcel?.neighborhood ? `${proforma.parcel?.neighborhood}` : "",
        price: sqFtPricesAppreciation,
      };
    }

    // Check ZIP data
    if (
      sqFtPricesAppreciation?.length > 0 &&
      !["county", "city", "metro", "neighborhood"].includes(geo_attribute_for_appreciation)
    ) {
      availableLocations.zip = {
        legend: "Zip",
        price: sqFtPricesAppreciation,
      };
    }

    // Filter to max 3 locations based on priority
    const locationsToShow: any = [];

    if (availableLocations.county) locationsToShow.push(availableLocations.county);
    if (availableLocations.city) locationsToShow.push(availableLocations.city);
    if (!availableLocations.city && availableLocations.metro) locationsToShow.push(availableLocations.metro);
    if (
      availableLocations.city &&
      availableLocations.metro &&
      !availableLocations.neighborhood &&
      !availableLocations.zip
    )
      locationsToShow.push(availableLocations.metro);
    if (availableLocations.neighborhood) locationsToShow.push(availableLocations.neighborhood);
    if (!availableLocations.neighborhood && availableLocations.zip)
      locationsToShow.push(availableLocations.zip);

    return locationsToShow;
  };

  const locationsToShow = getLocationsToShow();

  const locations: string[] = locationsToShow.map((loc: any) => loc.legend);

  // Build Data object
  const newData = dates?.map((date, index) => {
    let dateToShow = new Date(date);
    let salePrice = 0;
    let listingLastPriceForDate = false;
    let historicalSalesForDate: any[] = [];

    if (type === "sale") {
      historicalSalesForDate = historicalSales.filter((sale) => {
        if (sale.sale_date && sale.sale_date.slice(0, 7) === date.slice(0, 7)) {
          dateToShow = new Date(sale.sale_date);
          salePrice = sale.sale_price;
          return true;
        }
        return false;
      });

      if (lastListingPrice[0]?.date.slice(0, 7) === date.slice(0, 7)) {
        dateToShow = new Date(lastListingPrice[0]?.date);
        salePrice = lastListingPrice[0].price;
        listingLastPriceForDate = true;
      } else {
        listingLastPriceForDate = false;
      }
    }

    const row: any = {
      name: dateToShow,
    };

    if (type === "sale") {
      if (historicalSalesForDate?.length > 0) {
        row["Sale/Listing"] = ((salePrice / proforma.structure?.square_feet_finished) * 100) / 100;
      }

      if (listingLastPriceForDate) {
        row["Sale/Listing"] = ((salePrice / proforma.structure?.square_feet_finished) * 100) / 100;
      }
    }

    locationsToShow.forEach((loc: any) => {
      row[loc.legend] =
        Number(
          Intl.NumberFormat("en-US", {
            useGrouping: false,
            maximumFractionDigits: 2,
          }).format(loc.price[index]),
        ) || "N/A";
    });

    return row;
  });

  if (type === "sale") {
    // Add the last listing price to the data when its date is greater than the last date in the data
    if (new Date(lastListingPrice[0]?.date) > new Date(newData[newData?.length - 1].name)) {
      newData.push({
        name: new Date(lastListingPrice[0]?.date),
        "Sale/Listing": ((lastListingPrice[0].price / proforma.structure?.square_feet_finished) * 100) / 100,
      });
    }
  }
  // Build Lines
  locations?.forEach((location, idx: number) => {
    lines.push(
      <Line
        type="natural"
        key={location}
        dataKey={location}
        stroke={SERIES_COLORS[idx + 1].normalColor}
        legendType="plainline"
        strokeWidth={3.5}
        dot={false}
        activeDot={{
          r: 4,
        }}
      />,
    );
  });

  return (
    <div className="rounded-b-lg rounded-tl-lg border border-stone-100 bg-[#ECF1F5] p-3">
      <div className="flex w-full flex-col">
        <div className="pb-0 text-sm font-bold text-st-darker">{graphTitle}</div>
        <div className="flex w-full flex-row">
          <div className="w-full basis-1/2">
            <div className="text-pretty pb-3 pt-0 text-sm font-light text-st-normal">
              Price per Square Foot
            </div>
          </div>
          <div className="items-right flex w-full basis-1/2 flex-col justify-end text-sm">
            <div className="flex w-full flex-row pb-0 text-sm font-bold text-st-darker"></div>
            <div className="w-full pb-3 pt-0 text-right text-sm font-light text-st-normal">
              <PeriodSelector period={period} value={selectorValues["1Y"]} setPeriod={setPeriod} />
              <PeriodSelector period={period} value={selectorValues["3Y"]} setPeriod={setPeriod} />
              <PeriodSelector period={period} value={selectorValues["10Y"]} setPeriod={setPeriod} />
              <PeriodSelector period={period} value={selectorValues["All"]} setPeriod={setPeriod} />
            </div>
          </div>
        </div>
      </div>

      <div className="h-full w-full text-xs">
        {newData?.length > 0 && (
          <ResponsiveContainer aspect={4 / 3}>
            <ComposedChart
              data={newData}
              margin={{
                top: 10,
                right: 5,
                left: -20,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} syncWithTicks={true} opacity={0.3} />
              <XAxis
                type="category"
                opacity={0.4}
                dataKey="name"
                tickMargin={5}
                tickSize={3}
                tickFormatter={(value) => {
                  let dateFormat: any;
                  if (period === selectorValues["1Y"] || period === selectorValues["3Y"]) {
                    dateFormat = new Intl.DateTimeFormat("en-US", {
                      month: "short",
                    }).format(value);
                  } else {
                    dateFormat = new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                    }).format(value);
                  }
                  return dateFormat;
                }}
                interval={"equidistantPreserveStart"}
                axisLine={true}
              />

              <YAxis
                tickMargin={5}
                tickSize={0}
                domain={[
                  (dataMin: number) =>
                    Number(type === "sale" ? (dataMin - 20).toFixed(0) : (dataMin - 0.02).toFixed(2)),
                  (dataMax: number) =>
                    Number(type === "sale" ? (dataMax + 20).toFixed(0) : (dataMax + 0.02).toFixed(2)),
                ]}
                tickFormatter={(value) => `$${value}`}
                axisLine={true}
                opacity={0.4}
                tickCount={10}
              />

              <Tooltip
                offset={50}
                labelFormatter={(value: any) => {
                  return (
                    <span className="text-[11px] font-bold">
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(new Date(value))}
                    </span>
                  );
                }}
                formatter={(value: any, name: any, props: any) => {
                  return [
                    <ul className="list-inside">
                      <li key={name} className="list-disc text-[11px] leading-none">
                        {name}: <span className="font-bold text-st-normal">${Math.round(value)}/sqft</span>
                      </li>
                    </ul>,
                  ];
                }}
              />
              {lines}
              {type === "sale" && (
                <Scatter
                  name="Sale"
                  dataKey={"Sale/Listing"}
                  fill={SERIES_COLORS[0].lightColor}
                  stroke={SERIES_COLORS[0].normalColor}
                  legendType="circle"
                />
              )}
              <Legend
                verticalAlign="bottom"
                wrapperStyle={{
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                }}
                iconSize={10}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};
