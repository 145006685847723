import { ChevronLeftIcon, ChevronRightIcon, TrashIcon } from "@heroicons/react/outline";
import { Spinner } from "components";
import { ArrowsUpDown } from "components/icons";
import { SavedProperty } from "components/saved";
import { buildProformasAddressUrl, buildProformasAddressUrlNewConstruction } from "helpers";
import { Status } from "pages";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import ReactPaginate from "react-paginate";
import { useSavedPropertiesList } from "state/proformas";

// import type Status from Saved component

type Props = {
  loading: boolean;
  filteredProperties: any;
  status: Status;
  setStatus: (status: Status) => void;
  handleScroll: () => void;
};

export const SavedProperties = ({ loading, filteredProperties, status, setStatus, handleScroll }: Props) => {
  const { deleteSavedProperties } = useSavedPropertiesList();
  const [selectedItems, setSelectedItems] = useState<boolean[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const numSelected = selectedItems.filter(Boolean).length;

  useEffect(() => {
    setCurrentPage(0);
    setSelectedItems([]);
  }, [status]);

  const [orderDirection, setOrderDirection] = useState(false);

  const orderedProformas = filteredProperties?.sort((a: any, b: any) => {
    const dateA = new Date(a.last_updated).getTime();
    const dateB = new Date(b.last_updated).getTime();
    if (orderDirection === true) {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });

  const [currentPage, setCurrentPage] = useState(0);
  const resultsPerPage = 20;
  const numPages = Math.ceil(orderedProformas?.length / resultsPerPage);
  const pagedResults = orderedProformas?.slice(
    currentPage * resultsPerPage,
    (currentPage + 1) * resultsPerPage,
  );

  const onSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedItems(pagedResults.map(() => e.target.checked));
  };

  const onItemSelectChange = (idx: number) => {
    const newSelection = [...selectedItems];
    newSelection[idx] = !newSelection[idx];
    setSelectedItems(newSelection);
  };

  const onDeleteSelected = () => {
    const selectedIds = pagedResults.reduce((result: number[], property: any, idx: number) => {
      if (selectedItems[idx]) {
        result.push(property._id);
      }
      return result;
    }, []);
    setDeleting(true);
    deleteSavedProperties(selectedIds).finally(() => {
      setSelectedItems([]);
      pagedResults.length === 0 && currentPage > 1 ? setCurrentPage(currentPage - 1) : setStatus("all");
      setDeleting(false);
    });

    ReactGA.event({
      category: "Saved",
      action: "Delete Deal",
      label: selectedIds.length > 1 ? "Bulk" : selectedIds[0],
    });
  };

  const handleLinkClick = (e: React.MouseEvent, property: any) => {
    e.preventDefault();
    property.proforma_type === "flip_rental" &&
      window.open(buildProformasAddressUrl(property.full_address), "_blank", "noopener");
    property.proforma_type === "new_construction" &&
      window.open(buildProformasAddressUrlNewConstruction(property.full_address), "_blank", "noopener");
  };

  const handlePageClick = (page: { selected: number }) => {
    setCurrentPage(page.selected);
    handleScroll();
  };

  return (
    <>
      {loading && <div className="w-full text-center">Loading saved properties...</div>}

      <table className="w-full text-left text-xs">
        <thead className="w-full border-b border-gray-200">
          <tr className="text-st-light text-xs">
            <th className="min-w-fit items-center whitespace-nowrap px-1 py-2 font-light sm:p-2 sm:pr-4">
              <div className="flex flex-row items-center">
                <input
                  type="checkbox"
                  className="border outline-0 indeterminate:bg-gray-300 focus:ring-0"
                  onChange={onSelectAllChange}
                  checked={numSelected === pagedResults?.length}
                />
                {numSelected > 0 && (
                  <div className="ml-1 cursor-pointer content-end text-left" onClick={onDeleteSelected}>
                    {deleting ? (
                      <Spinner className="text-sl-normal size-4" />
                    ) : (
                      <TrashIcon className="text-sl-normal size-4" />
                    )}
                  </div>
                )}
              </div>
            </th>
            <th className="flex min-w-fit flex-row items-center gap-1 px-1 py-2 font-medium sm:p-2 sm:pr-4">
              Saved
              <ArrowsUpDown
                className="inline size-3 cursor-pointer"
                onClick={() => {
                  setSelectedItems([]);
                  setOrderDirection(!orderDirection);
                }}
              />
            </th>
            <th className="hidden w-full grow px-1 py-2 font-medium sm:p-2 sm:pr-4 md:table-cell">Address</th>
            <th className="w-full grow px-1 py-2 font-medium sm:p-2 sm:pr-4 md:hidden">Address/Status</th>
            <th className="hidden min-w-fit px-1 py-2 font-medium sm:p-2 md:table-cell">Status</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {pagedResults?.map((property: any, i: number) => (
            <tr
              key={property._id}
              className="cursor-pointer border-b border-gray-100 align-top hover:bg-black hover:text-white"
              onClick={(event) => handleLinkClick(event, property)}
            >
              <SavedProperty
                key={property._id}
                property={property}
                selected={!!selectedItems[i]}
                onChangeSelection={() => onItemSelectChange(i)}
              />
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex items-center justify-center text-sm">
        {numPages > 1 && (
          <ReactPaginate
            pageCount={numPages}
            forcePage={currentPage}
            onPageChange={handlePageClick}
            pageRangeDisplayed={currentPage < 3 || currentPage > numPages - 3 ? 4 : 3}
            marginPagesDisplayed={1}
            previousLabel={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
            nextLabel={<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />}
            containerClassName="mt-4 flex justify-center text-sm text-st-lighter"
            previousClassName="mr-1"
            previousLinkClassName="py-1 hover:text-st-darker cursor-pointer"
            nextClassName="ml-1"
            nextLinkClassName="py-1 hover:text-st-darker cursor-pointer"
            pageClassName="mx-1"
            activeClassName="text-white "
            disabledClassName="text-st-lightest"
            pageLinkClassName="px-2.5 py-1 cursor-pointer border border-[#6389c4] rounded-md"
            activeLinkClassName="px-2.5 py-1 bg-[#6389c4] cursor-default border border-[#6389c4] rounded-md"
            disabledLinkClassName="cursor-default"
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    </>
  );
};
