import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { Spinner, SpinnerWithArrows } from "components";
import { ArrowPath, NotificationsActive, NotificationsOff } from "components/icons";
import { useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

type Props = {
  filter: any;
  onDeleteSavedSearch: CallableFunction;
  onUpdateSavedSearch: CallableFunction;
  buildSavedFiltesParameters: CallableFunction;
};

export const FiltersSavedSearch = ({
  filter,
  onDeleteSavedSearch,
  onUpdateSavedSearch,
  buildSavedFiltesParameters,
}: Props) => {
  const location = useLocation();
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [alertChanging, setAlertChanging] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [editName, setEditName] = useState<string | null>(null);

  const isHome = location.pathname === "/";

  const onDeleteSearch = () => {
    setDeleting(true);
    onDeleteSavedSearch(filter.id);
  };

  const toggleEditName = () => {
    if (editName === null) {
      setEditName(filter.name);
    } else {
      setEditName(null);
    }
  };

  const editSave = () => {
    if (editName && editName.trim().length > 0) {
      setSaving(true);
      filter.name = editName.trim();
      onUpdateSavedSearch(filter).finally(() => {
        setSaving(false);
        setEditName(null);
      });
    }
  };

  const onUpdateFilters = () => {
    setUpdating(true);
    filter.parameters = {
      texts: buildSavedFiltesParameters(),
      url: location.pathname + location.search,
    };
    filter.active = filter.active || false;
    onUpdateSavedSearch(filter).finally(() => {
      setUpdating(false);
    });
  };

  const toggleAlertEmail = () => {
    setAlertChanging(true);
    filter.active = !filter.active;
    onUpdateSavedSearch(filter).finally(() => setAlertChanging(false));

    ReactGA.event({
      category: "Browse",
      action: filter.active ? "Activate Alert" : "Disable Alert",
      label: filter.name,
    });
  };

  return (
    <li className="flex w-full flex-row items-center pt-1 align-middle">
      <div
        className="flex basis-1/12 cursor-pointer items-center align-middle text-[18px]"
        title={`${filter.active ? "Dea" : "A"}ctivate alert email`}
        onClick={toggleAlertEmail}
      >
        {alertChanging ? (
          <Spinner className="text-st-darkest size-[18px]" />
        ) : (
          <>
            {filter.active ? (
              <NotificationsActive className="text-st-darkest size-[18px]" />
            ) : (
              <NotificationsOff className="text-st-lightest size-[18px]" />
            )}
          </>
        )}
      </div>
      <div className="flex basis-8/12 px-2">
        {editName === null ? (
          <a href={filter.parameters.url} className="cursor-pointer text-[#6389c4]">
            {filter.name}
          </a>
        ) : (
          <div className="flex">
            <input
              type="text"
              className={`${isHome ? "w-36 sm:w-44" : "w-28 sm:w-36"} rounded-l-md border-gray-300 text-sm`}
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
            />
            <button
              className="flex whitespace-nowrap rounded-r-md bg-black px-4 py-2 text-sm text-white"
              onClick={editSave}
              disabled={!(editName || "").trim()}
            >
              {saving ? <Spinner className="size-[18px] text-white" /> : <>Save</>}
            </button>
          </div>
        )}
      </div>

      <div className="flex basis-1/12 cursor-pointer" onClick={toggleEditName} title="Rename">
        <PencilAltIcon className="hover:text-st-darkest text-st-lightest size-[18px]" />
      </div>
      <div className="flex basis-1/12" onClick={onUpdateFilters} title="Update with current filters">
        {updating ? (
          <SpinnerWithArrows className="text-st-darkest size-[18px]" />
        ) : (
          <ArrowPath className="hover:text-st-darkest text-st-lightest size-[18px]" />
        )}
      </div>
      <div className="flex basis-1/12" onClick={onDeleteSearch} title="Delete">
        {deleting ? (
          <Spinner className="text-st-darkest size-[18px]" />
        ) : (
          <TrashIcon className="hover:text-st-darkest text-st-lightest size-[18px]" />
        )}
      </div>
    </li>
  );
};
