import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { StructureFilters } from "components/browse";
import { Fragment } from "react";
import { isDesktop } from "react-device-detect";

type Props = {
  structureTypeValues: any[];
  handleClick: CallableFunction;
  handleReset: CallableFunction;
};

export const FilterStructureMenu = ({ structureTypeValues, handleClick, handleReset }: Props) => {
  return (
    <>
      {isDesktop && (
        <Menu as="div" className={`relative ml-2 mr-1 hidden md:mx-2 filter-structure:flex`}>
          {({ open }) => (
            <>
              <Menu.Button
                className={`text-md flex items-center justify-between rounded-md border border-gray-300 py-2 pl-3 pr-2 text-left text-sm font-medium ${
                  structureTypeValues?.length > 0 ? "bg-black text-white" : "text-st-light"
                }`}
              >
                <span className="flex w-16 items-center whitespace-nowrap">
                  {structureTypeValues?.length > 0 ? (
                    <span className="w-20 truncate">{structureTypeValues.join(", ")}</span>
                  ) : (
                    <>Structure</>
                  )}
                </span>
                {open ? (
                  <ChevronDownIcon className="ml-2 h-4 w-4" />
                ) : (
                  <>
                    {structureTypeValues?.length > 0 ? (
                      <XIcon
                        className="ml-2 h-4 w-4"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReset();
                        }}
                      />
                    ) : (
                      <ChevronDownIcon className="text-st-darkest ml-2 h-4 w-4" />
                    )}
                  </>
                )}
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute top-8 mt-2 w-[15rem] origin-top-left rounded-md bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <StructureFilters
                    structureTypeValues={structureTypeValues}
                    handleClick={handleClick}
                    handleReset={handleReset}
                  />
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </>
  );
};
