import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { GraphTypes, HelpModal, RentalGraphClass } from "components/proformas/financials/rentaltab/";
import { useState } from "react";
import { Chart } from "react-google-charts";

type Props = {
  graphType: GraphTypes;
  name: string;
  mathService: any;
};

export const RentalGraph = ({ graphType, name, mathService }: Props) => {
  const rentalChart = new RentalGraphClass(mathService, graphType);
  const [showHelp, setShowHelp] = useState(false);

  const closeHelpModal = () => {
    setShowHelp(false);
  };

  return (
    <div className="w-full py-0">
      <div className="text-md py-4 font-bold">
        <div className="basis-1/1 flex w-full">
          <div className="w-full basis-1/2">{name}</div>
          <div
            className="flex w-full basis-1/2 justify-end pr-5 text-right"
            onClick={() => setShowHelp(true)}
          >
            <QuestionMarkCircleIcon className="text-st-lightest w-4 cursor-pointer" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="w-full p-0">
        <Chart chartType={rentalChart.chartType} data={rentalChart.data} options={rentalChart.options} />
      </div>
      {showHelp && <HelpModal closeModal={closeHelpModal} isOpen={showHelp} graphType={graphType} />}
    </div>
  );
};
