import {
  costNameDictionary,
  editCostModalAtom,
  getCostDetail,
  setupCosts,
} from "components/proformas/financials/";
import sortBy from "lodash/sortBy";
import NumberFormat from "react-number-format";
import { useSetRecoilState } from "recoil";
import { useFinancials } from "state/proformas";

type Props = {
  costGroupName: "Expenses" | "Budgets";
};

export const RentalCostGroup = ({ costGroupName }: Props) => {
  const { financials } = useFinancials();

  const costGroup = financials?.calcsheet.is_brrr
    ? financials?.costgroups.rentalBrrr
    : financials?.costgroups.rentalHold;

  let costGroupData = sortBy(costGroup, "_display_order");

  const budgetsCGNames = ["maintenance_hold", "maintenance_brrr", "vacancy_hold", "vacancy_brrr"];
  costGroupData =
    costGroupName === "Expenses"
      ? costGroupData.filter((cg) => !budgetsCGNames.includes(cg.name))
      : costGroupData.filter((cg) => budgetsCGNames.includes(cg.name));

  const setEditCostModal = useSetRecoilState(editCostModalAtom);

  const totalCost = costGroupData.reduce((acc, curr) => acc + curr.amount, 0);

  return (
    <div className="mb-8">
      <div className="text-md flex flex-row justify-items-stretch gap-2 pb-2 leading-9">
        <div className="w-3/5 whitespace-nowrap font-medium">{costGroupName}</div>
        <div className="text-md w-2/5 whitespace-nowrap text-right">
          <NumberFormat
            value={totalCost}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      {costGroupData?.map((cost) => (
        <div key={cost.name} className="flex flex-row justify-items-stretch gap-2 text-sm">
          <div className="w-full whitespace-nowrap">
            <div>{costNameDictionary[cost.name as keyof typeof costNameDictionary] || cost.name}</div>
            <div className="basis-3/5 text-xs text-st-lightest">{getCostDetail(cost)}</div>
          </div>
          <div className="w-full basis-2/5 text-right">
            <div
              className="w-full cursor-pointer items-center border border-[#E4C442] bg-stone-200 p-1 text-right text-sm text-st-darkest outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
              onClick={() => setEditCostModal(setupCosts(cost, financials))}
            >
              <NumberFormat
                className="cursor-pointer border-0 border-blue-700 pb-0.5 text-st-darkest placeholder:text-st-lightest focus:border-focus-border"
                value={cost.amount}
                displayType="text"
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
