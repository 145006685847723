import { stateIdToStateData } from "config";
import { cn, formatPropertyName, titleCase, TypeAheadItemType } from "helpers";
import { useEffect, useRef } from "react";

type formattedResultType = {
  line1: string;
  line2: string;
};

export const formatOption = (item: TypeAheadItemType): formattedResultType => {
  const output: formattedResultType = {
    line1: "",
    line2: "",
  };

  output.line1 = formatPropertyName(titleCase(item.street_address));
  output.line2 = item.city_name ? `${titleCase(item.city_name)}` : `${titleCase(item.county_name)} County`;
  output.line2 += item.state_id
    ? `, ${stateIdToStateData[item.state_id as keyof typeof stateIdToStateData].code}`
    : (output.line2 += `, ${titleCase(item.state_name)}`);
  output.line2 += ` ${item.zip_code}`;

  return output;
};

type Props = {
  item: TypeAheadItemType;
  onSelection: CallableFunction;
  selected: boolean;
};

export const TakePhotosDownResultItem = ({ item, onSelection, selected }: Props) => {
  const option = formatOption(item);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && selected) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selected]);

  const handleClickOnItem = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelection(item);
  };

  return (
    <>
      {/* {option.line1 !== "Unknown" && ( */}
      <div
        className={cn("group cursor-pointer pl-2 hover:bg-black", selected && "bg-black")}
        onClick={handleClickOnItem}
        ref={ref}
      >
        <div className="flex">
          <div className={cn("grow group-hover:text-white", selected && "text-white")}>
            <div className="pt-2">{option.line1}</div>
            <div className="pb-1">{option.line2}</div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
