import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { cn } from "helpers";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";
import { useFinancials } from "state/proformas";

type Props = {
  loan: any;
  handleCarryingTimeClick: CallableFunction;
};

export const LoanDetails = ({ loan, handleCarryingTimeClick }: Props) => {
  const { updateLoan } = useFinancials();
  const [showLoanData, setShowLoanData] = useState<boolean>(false);
  const toggleEnabledStatus = () => {
    updateLoan({
      ...loan,
      is_enabled: !loan.is_enabled,
    });
  };

  // const toggleFinancedInterest = () => {
  //   updateLoan({
  //     ...loan, is_financed_interest: !loan.is_financed_interest
  //   });
  // }

  // const toggleFinancedOrigination = () => {
  //   updateLoan({
  //     ...loan, is_financed_origination: !loan.is_financed_origination
  //   });
  // }

  const onDownPaymentChange = (values: any) => {
    if (values.floatValue || values.floatValue === 0) {
      updateLoan({
        ...loan,
        dpay: values.floatValue / 100,
      });
    }
  };

  const onInterestChange = (values: any) => {
    if (values.floatValue || values.floatValue === 0) {
      updateLoan({
        ...loan,
        interest: values.floatValue / 100,
      });
    }
  };

  const onOriginationChange = (values: any) => {
    if (values.floatValue || values.floatValue === 0) {
      updateLoan({
        ...loan,
        origination: values.floatValue / 100,
      });
    }
  };

  return (
    <div
      className={cn(
        isMobile ? "mb-8" : "mb-4",
        isMobile && !showLoanData && "mb-0",
        isMobile && !showLoanData && loan._display_name === "Rehab Loan" && "mb-2",
      )}
    >
      <div
        className={cn(
          "mt-2 flex flex-row items-center justify-items-stretch gap-2 pb-2 leading-9",
          isMobile && "mt-0",
        )}
      >
        <label className="flex w-3/5 cursor-pointer items-center whitespace-nowrap font-medium">
          <input
            className="mr-1 cursor-pointer border text-sl-lighter outline-0 indeterminate:bg-gray-300 focus:ring-0"
            type="checkbox"
            onChange={() => toggleEnabledStatus()}
            checked={loan.is_enabled}
          />
          {loan._display_name}
        </label>
        <div className="flex w-full basis-2/5 items-center justify-end pr-1 text-right">
          {isMobile && (
            <>
              {showLoanData ? (
                <ChevronUpIcon className="ml-1 inline-block size-4" onClick={() => setShowLoanData(false)} />
              ) : (
                <ChevronDownIcon className="ml-1 inline-block size-4" onClick={() => setShowLoanData(true)} />
              )}
            </>
          )}
        </div>
      </div>

      {(!isMobile || showLoanData) && (
        <div className={`${loan.is_enabled ? "opacity-100" : "cursor-not-allowed opacity-20"}`}>
          <div className="w-full pl-6 text-st-light">
            {isMobile && <div className="w-3/5 whitespace-nowrap text-sm leading-none">Interest-Only</div>}
            <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
              <div className="w-3/5 whitespace-nowrap">Carrying Time</div>
              <div
                className={`w-full text-right ${isMobile && "text-sl-normal"}`}
                onClick={() => (isMobile ? handleCarryingTimeClick() : null)}
              >
                {loan.time} months
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="w-full basis-3/5 whitespace-nowrap">Down Payment</div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                defaultValue={loan.dpay * 100}
                thousandSeparator={false}
                suffix=" %"
                decimalScale={0}
                onValueChange={onDownPaymentChange}
                disabled={!loan.is_enabled}
              />
            </div>
          </div>

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="w-full basis-3/5 whitespace-nowrap">Interest Rate</div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                defaultValue={loan.interest * 100}
                thousandSeparator={false}
                suffix=" %"
                decimalScale={2}
                onValueChange={onInterestChange}
                disabled={!loan.is_enabled}
              />
            </div>
          </div>

          {/*<div className="flex flex-row justify-items-stretch gap-2 text-xs mt-2 pb-4 items-center">
          <input
            className="indeterminate:bg-gray-300 border outline-0 focus:ring-0"
            type="checkbox"
            onChange={() => toggleFinancedInterest()}
            checked={loan.is_financed_interest}
            disabled={!loan.is_enabled}
          />
          Finance Interest
        </div> */}

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="w-full basis-3/5 whitespace-nowrap">Points</div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                defaultValue={loan.origination * 100}
                thousandSeparator={false}
                suffix=" %"
                decimalScale={2}
                onValueChange={onOriginationChange}
                disabled={!loan.is_enabled}
              />
            </div>
          </div>

          {/*<div className="flex flex-row justify-items-stretch gap-2 text-xs mt-2 pb-4 items-center">
          <input
            className="text-sl-lighter indeterminate:bg-gray-300 border outline-0 focus:ring-0"
            type="checkbox"
            onChange={() => toggleFinancedOrigination()}
            checked={loan.is_financed_origination}
            disabled={!loan.is_enabled}
          />
          Finance Points
      </div>*/}
        </div>
      )}
    </div>
  );
};
