import { ChevronDownIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { cn } from "helpers";
import { useEffect, useRef, useState } from "react";

export const ZoningSpec = ({ name, value, index }: { name: string; value: string; index: number }) => {
  const [showMore, setShowMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const valueRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkTruncation = () => {
      if (valueRef.current) {
        const isOverflowing = valueRef.current.scrollWidth > valueRef.current.clientWidth;
        setIsTruncated(isOverflowing);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);

    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [value]);

  return (
    <div
      className="flex w-full items-start border-b border-gray-200 px-1.5 py-1.5 text-sm last:border-b-0"
      key={`detail-${name}-${index}`}
    >
      <div className="flex w-1/3 items-center whitespace-nowrap pr-1 xl:w-2/5">
        {name}
        {name === "FAR" && (
          <div className="group relative">
            <InformationCircleIcon className="ml-1 size-4 cursor-pointer" />
            <div className="absolute -top-7 left-0 z-10 hidden size-fit rounded-md bg-black bg-opacity-80 px-2 py-1 text-xs text-white group-hover:inline">
              Floor Area Ratio
            </div>
          </div>
        )}
      </div>
      <div className="flex w-2/3 flex-row items-start font-light xl:w-3/5">
        <div className={cn(!showMore && "truncate", "pr-1")} ref={valueRef}>
          {value}
        </div>
        {isTruncated && (
          <div className="size-4 shrink-0" onClick={() => setShowMore((prev) => !prev)}>
            <ChevronDownIcon className={cn("inline size-4 cursor-pointer", showMore && "rotate-180")} />
          </div>
        )}
      </div>
    </div>
  );
};
