import React from "react";

type Props = {
  label: string;
  errMsg?: any;
  children?: React.ReactNode;
  disabled?: boolean;
};

export const LabeledSelect = React.forwardRef(
  ({ label, children, errMsg, ...rest }: Props, ref: React.ForwardedRef<HTMLSelectElement>) => {
    return (
      <label className="mt-3 block">
        <span className="text-st-normal pl-3 text-sm">{label}</span>

        <select
          ref={ref}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errMsg ? "border-red-500" : ""} `}
          {...rest}
        >
          {children}
        </select>
        {errMsg && <div className="text-red-500">{errMsg}</div>}
      </label>
    );
  },
);
