import React, { useEffect, useRef, useState } from "react";
import { LeafletLocationMapService } from "./LeafletLocationMapService";
import "leaflet/dist/leaflet.css";
import { Spinner } from "components";
import "state/useMultiPolygons";
import { fetchPolygons } from "state";


const locationsFromUrl = (url: string) => {
  if (url.includes("?")) {
    url = url.split("?")[1];
  }
  const urlParams = new URLSearchParams(url);

  const areas: { type: string; id: string }[] = [];

  ["county", "city", "zip_code", "neighborhood", "metro_area"].forEach((type) => {
    const paramIdValues = urlParams.getAll(type);
    paramIdValues.forEach((id) => {
      areas.push({ type: type, id });
    });
  });
  return areas;
};

const boundsDataFromUrl = (url: string) => {
  const urlParams = new URLSearchParams(url);
  const bounds = urlParams.get("mapBounds");
  let outLines = true;
  if (urlParams.get("outLines") === "false") {
    outLines = false;
  }
  return { outLines, bounds };
};

type Props = {
  url: string;
};

export const LocationMap = ({ url }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const mapService = useRef<LeafletLocationMapService | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const locations = locationsFromUrl(url);
    const boundsData = boundsDataFromUrl(url);

    if (locations.length > 0) {
      fetchPolygons(locations).then((polygons) => {
        if (ref.current && polygons && !mapService.current) {
          mapService.current = new LeafletLocationMapService();
          mapService.current.createMap(ref.current);
          if (boundsData.outLines) {
            mapService.current.setAreasOutlines(polygons);
          }
          if (boundsData.bounds) {
            mapService.current.zoomToBounds(boundsData.bounds);
          }
          setLoading(false);
        }
      });
    }

    return () => mapService.current?.clearMap();
  }, [url]);

  return (
    <div ref={ref} className="h-full w-full">
      {loading && (
        <div className="grid h-full w-full place-items-center">
          <span className="flex items-center rounded-3xl bg-[#61a28d] py-2 pl-8 pr-4 text-center text-white">
            <Spinner /> Loading map...
          </span>
        </div>
      )}
    </div>
  );
};