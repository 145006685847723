import { CheckIcon } from "@heroicons/react/solid";
import { Spinner } from "components";
import { cn } from "helpers";
import React from "react";

type Props = {
  variant?: "primary" | "secondary";
  isSubmitting: boolean;
  isSuccessful?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  buttonClassName?: string;
  spinnerClassName?: string;
  successClassName?: string;
};

export const SubmitButton = ({
  variant = "primary",
  isSubmitting,
  isSuccessful,
  children,
  disabled,
  onClick,
  buttonClassName,
  spinnerClassName,
  successClassName,
}: Props) => {
  const buttonClass = cn(
    "inline-flex items-center my-6 cursor-pointer px-8 py-2 font-normal shadow-sm rounded-sm text-white transition ease-in-out duration-150",
    variant === "primary" && "bg-sb-primary hover:bg-sb-primary-hover",
    variant === "secondary" && "bg-sb-secondary hover:bg-sb-secondary-hover",
    buttonClassName,
    isSubmitting && "pl-10",
  );

  return (
    <>
      <button
        disabled={isSubmitting || disabled}
        className={buttonClass}
        onClick={() => onClick && onClick()}
      >
        {isSubmitting && <Spinner className={spinnerClassName} />}
        {children}
        {isSuccessful && <CheckIcon className={successClassName ?? "-mr-5 ml-3 h-5 w-5 text-white"} />}
      </button>
    </>
  );
};
