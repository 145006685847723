import { PhotographIcon } from "@heroicons/react/outline";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import { ListedBy } from "components";
import { setShowPhotosModalIdxAtom } from "components/proformas";
import { StreetView } from "components/proformas/details";
import { formatPropertyName } from "helpers";
import { useSetRecoilState } from "recoil";
import { EQUIVALENT_STRUCT_TYPES } from "state/browse/useFilters";
import { CustomStructureType, useCustomStructure, useProforma } from "state/proformas";

type Props = {
  compStats: any[];
  isRentalCompsViewAdjustEmpty: boolean;
};

export const RentalCompSubjectPropertyCard = ({ compStats, isRentalCompsViewAdjustEmpty }: Props) => {
  const { proforma } = useProforma();
  const { customStructure } = useCustomStructure(proforma);
  const isPhotosEmpty = proforma.photos.length === 0;
  const showPhotosModalIdx = useSetRecoilState(setShowPhotosModalIdxAtom);
  const isMissingThumbnail =
    !proforma.thumbnail_url ||
    proforma.thumbnail_url === "https://cdn.davinci.pellego.com/static/images/core/missing.png";
  const dontShowAddress = proforma?.parcel?.most_recent_listing?.dont_show_address || false;

  return (
    <div className="flex flex-row bg-blue-50 sm:shadow-lg">
      <div className="w-44 min-w-44 whitespace-nowrap p-3 sm:w-64 sm:min-w-64 sm:p-4">
        <div className="flex h-[60px] flex-col items-start">
          <div className="flex items-center whitespace-nowrap text-sm font-bold">
            <LocationMarkerIcon className="mr-1 w-5 text-[#F7931E]" aria-hidden="true" /> Subject Property
          </div>
          {dontShowAddress ? (
            <div className="text-sl-normal ml-6 text-xs font-light">Address Hidden by Owner</div>
          ) : (
            <div className="text-sl-normal ml-6 max-w-32 truncate text-xs font-light sm:max-w-56">
              {formatPropertyName(proforma.parcel?.street_address)}
            </div>
          )}
          <div className="text-st-normal ml-6 text-xs font-light">
            <ListedBy propertyParcel={proforma.parcel} shortSale={true} />
          </div>
        </div>

        {isMissingThumbnail ? (
          <div>
            <StreetView
              otherClasses="flex cursor-pointer items-end rounded-sm bg-blue-300"
              sizeClasses="h-36 w-full"
              parcel={proforma.parcel}
              titles={false}
            />
          </div>
        ) : (
          <div
            className={`bg-[background-size: 100% 100%;] relative flex h-36 w-full items-end rounded-sm bg-cover bg-center bg-no-repeat ${!isPhotosEmpty && "cursor-pointer"}`}
            style={{ backgroundImage: `url('${proforma.thumbnail_url}')` }}
            onClick={() => !isPhotosEmpty && showPhotosModalIdx(0)}
          >
            {!isPhotosEmpty && (
              <div className="absolute bottom-2 right-2 z-10 flex items-center text-xs text-white">
                <PhotographIcon className="mr-1 w-5" aria-hidden="true" />
                {proforma.photo_count || proforma.photos?.length || 0}
              </div>
            )}
            <div className="absolute bottom-0 h-9 w-full bg-gradient-to-t from-gray-900/70 to-transparent" />
          </div>
        )}
        <div className="mt-14 text-xs leading-7">
          <div className="flex flex-row justify-items-stretch py-0">
            <div className="text-st-normal mt-2 w-1/2 text-left text-xs font-bold">Characteristics</div>
            <div className="text-st-normal mt-2 w-1/2 text-right text-xs font-bold">Subject</div>
          </div>

          {compStats.map((stat) =>
            (stat.key === "hoa_dues" &&
              (stat.subjectValue === "$0" || stat.subjectValue === 0 || stat.subjectValue === null)) ||
            (stat.key === "structure_style" &&
              !EQUIVALENT_STRUCT_TYPES.includes(proforma.parcel._characteristics.structure_type)) ||
            (stat.key === "view_type" && isRentalCompsViewAdjustEmpty) ? null : (
              <div
                key={stat.key}
                className="flex flex-row justify-items-stretch gap-2 border-b border-gray-200 py-0"
              >
                <div className="w-1/2 whitespace-nowrap font-bold">{stat.name}</div>
                <div className="w-1/2 overflow-hidden text-ellipsis text-right">
                  {stat.subjectValue}
                  {customStructure[stat.key as keyof CustomStructureType] && (
                    <>
                      {stat.key === "view_type" ? (
                        <>
                          {customStructure.view_type && customStructure.view_type?.length > 0 && (
                            <> → {customStructure.view_type?.join(", ")}</>
                          )}
                        </>
                      ) : (
                        <> → {customStructure[stat.key as keyof CustomStructureType]}</>
                      )}
                    </>
                  )}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};
