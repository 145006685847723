import { appConfig } from "config";
import L from "leaflet";
import "leaflet.markercluster";
import "@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";
import { MultiPolygon } from "geojson";
import { mapBoundsToApiParams } from "state/browse/useBrowseSearchApi";

export class LeafletLocationMapService {
  private map: L.Map;
  private boundariesJSONLayer: L.GeoJSON;
  public areasOutlinesVisible = true;

  constructor() {
    this.map = L.map(document.createElement("div")); // Temporary map reference
    this.boundariesJSONLayer = L.geoJSON();
  }

  createMap(domRef: HTMLDivElement) {
    const streetMap = L.maplibreGL({
      style: `https://api.maptiler.com/maps/2eadbf66-831a-4e49-9604-48d6515c3a10/style.json?key=${appConfig.mapTilerApiKey}`,
    });

    this.map = L.map(domRef, {
      zoom: 11,
      maxZoom: 18,
      zoomControl: false,
      layers: [streetMap],
      attributionControl: false,
    });

    L.control.zoom({ position: "bottomright" }).addTo(this.map);
    this.map.scrollWheelZoom.disable();
  }

  setAreasOutlines(locationsPolygons: MultiPolygon[]) {
    if (!locationsPolygons || locationsPolygons.length === 0) return;

    const featureCollection: GeoJSON.FeatureCollection = {
      type: "FeatureCollection",
      features: locationsPolygons.map((polygon) => ({
        type: "Feature",
        properties: {},
        geometry: polygon,
      })),
    };

    this.updateBoundaries(featureCollection);
  }

  private updateBoundaries(featureCollection: GeoJSON.FeatureCollection) {
    // Clear existing layer if present
    if (this.boundariesJSONLayer) {
      this.map.removeLayer(this.boundariesJSONLayer);
    }

    this.boundariesJSONLayer = L.geoJSON(featureCollection, {
      style: {
        weight: 2,
        color: "#888",
        fillOpacity: 0.1,
      },
    });

    this.boundariesJSONLayer.addTo(this.map);
    this.boundariesJSONLayer.bringToBack();
    this.map.fitBounds(this.boundariesJSONLayer.getBounds());
  }

  zoomToBounds(bounds: string) {
    const boundsParams = mapBoundsToApiParams(bounds);
    let mapBounds = null;
    if (boundsParams) {
      mapBounds = L.latLngBounds(
        L.latLng(boundsParams.minLat, boundsParams.minLng),
        L.latLng(boundsParams.maxLat, boundsParams.maxLng),
      );
      this.map.fitBounds(mapBounds);
    }
  }

  clearMap() {
    this.map.off();
    this.map.remove();
  }
}
