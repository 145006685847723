import { ChevronDownIcon } from "@heroicons/react/outline";
import { WithSignIn } from "components";
import { ZoningSpec } from "components/proformas";
import { cn } from "helpers";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useProforma } from "state/proformas";

type zoningType = {
  // state_id: number;
  // zoning_code: string;
  // city_id: number;
  // overview: string;
  // is_single_family: boolean;
  title: string;
  link: string;
  allowed_uses: string;
  minimum_lot_size: string;
  density_limits: string;
  parking_requirements: string;
  floor_area_ratio: string;
  setbacks: string;
  maximum_building_height: string;
  development_standards: string;
  other_restrictions: string;
};

const buildZoningData = (proforma: any) => {
  const zoning: zoningType = proforma.parcel?.zoning_description || {};
  const zoningSpecs: any = [];
  const zoningSpecsMore: any = [];

  zoning.title &&
    zoning.title !== "null" &&
    zoningSpecs.push({
      name: "Code",
      value: zoning?.title,
    });

  zoning.link &&
    zoning.link !== "null" &&
    zoningSpecs.push({
      name: "Source",
      value: (
        <WithSignIn text="Log in with free account">
          <a className="text-sl-normal" href={zoning?.link} target="_blank" rel="noreferrer">
            View zoning code
          </a>
        </WithSignIn>
      ),
    });

  zoning.allowed_uses &&
    zoning.allowed_uses !== "null" &&
    zoningSpecs.push({
      name: "Uses",
      value: zoning?.allowed_uses,
    });
  zoning.minimum_lot_size &&
    zoning.minimum_lot_size !== "null" &&
    zoningSpecs.push({
      name: "Min Lot",
      value: zoning?.minimum_lot_size,
    });
  zoning.density_limits &&
    zoning.density_limits !== "null" &&
    zoningSpecs.push({
      name: "Density",
      value: zoning?.density_limits,
    });
  zoning.parking_requirements &&
    zoning.parking_requirements !== "null" &&
    zoningSpecsMore.push({
      name: "Parking",
      value: zoning?.parking_requirements,
    });
  zoning.floor_area_ratio &&
    zoning.floor_area_ratio !== "null" &&
    zoningSpecsMore.push({
      name: "FAR",
      value: zoning?.floor_area_ratio,
    });
  zoning.setbacks &&
    zoning.setbacks !== "null" &&
    zoningSpecsMore.push({
      name: "Setbacks",
      value: zoning?.setbacks,
    });
  zoning.maximum_building_height &&
    zoning.maximum_building_height !== "null" &&
    zoningSpecsMore.push({
      name: "Height",
      value: zoning?.maximum_building_height,
    });
  zoning.development_standards &&
    zoning.development_standards !== "null" &&
    zoningSpecsMore.push({
      name: "Standards",
      value: zoning?.development_standards,
    });
  zoning.other_restrictions &&
    zoning.other_restrictions !== "null" &&
    zoningSpecsMore.push({
      name: "Others",
      value: zoning?.other_restrictions,
    });

  return {
    zoningSpecs: zoningSpecs,
    zoningSpecsMore: zoningSpecsMore,
  };
};

export const Zoning = () => {
  const { proforma } = useProforma();
  const { zoningSpecs: zoningSummary, zoningSpecsMore: zoningSummaryMore } = buildZoningData(proforma);
  const [showMore, setShowMore] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleShowMore = () => {
    if (isMobile) {
      if (!showMore) {
        ref.current?.scrollIntoView({ behavior: "smooth" });
      }
      setShowMore((prev) => !prev);
    }
  };

  return (
    <>
      {!(zoningSummary.length === 0 && zoningSummaryMore.length === 0) && (
        <div className="w-full max-w-7xl justify-center gap-4 px-4 sm:rounded-md xl:px-0" ref={ref}>
          <div className="mt-2 flex items-center text-xs font-bold uppercase text-green-900">Zoning</div>
          <div className="flex w-full flex-col pt-2">
            {zoningSummary.length > 0 && (
              <div className="flex w-full flex-col">
                <div className="flex w-full flex-col justify-start">
                  {zoningSummary.map(({ name, value }: any, index: number) => (
                    <ZoningSpec key={index} name={name} value={value} index={index} />
                  ))}
                </div>
              </div>
            )}
            {(zoningSummary.length === 0 || showMore || !isMobile) && (
              <div
                className={cn(
                  "flex w-full flex-col",
                  zoningSummary.length > 0 && zoningSummaryMore.length > 0 && "border-t border-gray-200",
                )}
              >
                {zoningSummaryMore.map(({ name, value }: any, index: number) => (
                  <ZoningSpec key={index} name={name} value={value} index={index} />
                ))}
              </div>
            )}
            {isMobile && zoningSummary.length > 0 && zoningSummaryMore.length > 0 && (
              <div
                className={cn("mt-1 flex items-center text-left text-sm text-st-lightest")}
                onClick={handleShowMore}
              >
                <ChevronDownIcon className={cn("mr-2 inline size-4", showMore && "rotate-180")} />
                {showMore ? "Show less" : "Show more"}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
