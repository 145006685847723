import {
  RentalCompFiltersForm,
  rentalCompsFormIsDirtyAtom,
  RentalFiltersModal,
} from "components/proformas/comparables/";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

export const RentalCompFilters = () => {
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const setFormIsDirty = useSetRecoilState(rentalCompsFormIsDirtyAtom);

  useEffect(() => {
    return () => setFormIsDirty(false);
  }, [setFormIsDirty]);

  return (
    <>
      <div className="mb-4 h-80 w-full rounded-lg border border-stone-200 bg-white px-4 py-3 pb-1">
        <div className="text-st-darker pb-4 text-sm font-bold">Modify Comp Filters</div>
        <RentalCompFiltersForm allFilters={false} setShowModal={setShowFiltersModal} />
      </div>

      {showFiltersModal && (
        <RentalFiltersModal isOpen={showFiltersModal} setShowModal={setShowFiltersModal} />
      )}
    </>
  );
};
