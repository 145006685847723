import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import omit from "lodash/omit";
import { useState } from "react";
import { CustomStructureType, useCustomStructure } from "state/proformas";
import {
  compFiltersMinMaxAttrs,
  processSalesComparables,
  SalesComparableType,
  salesCompsFiltersType,
  useSalesComps,
} from "state/proformas/comparables";

const buildMoreComparablesParams = (
  filters: salesCompsFiltersType,
  customStructure: CustomStructureType,
  proforma: any,
) => {
  const moreCompsParams = {} as any;

  moreCompsParams.filter = [];
  if (filters.flip) {
    moreCompsParams.filter.push("is_flip == true");
  }
  if (filters.only_sytyle) {
    moreCompsParams.filter.push(`structure_style == ${proforma.parcel._characteristics.style}`);
  }
  if (filters.months) {
    const date = new Date(new Date().getTime() - filters.months * 1000 * 3600 * 24 * 30);
    moreCompsParams.filter.push(`sale_sale_date >= ${date.toISOString()}`);
  }
  compFiltersMinMaxAttrs.forEach((attr) => {
    const minVal = filters[`min_${attr.key}` as keyof salesCompsFiltersType];
    if (minVal) {
      moreCompsParams.filter.push(`${attr.section}_${attr.key} >= ${minVal}`);
    }
    const maxVal = filters[`max_${attr.key}` as keyof salesCompsFiltersType];
    if (maxVal) {
      moreCompsParams.filter.push(`${attr.section}_${attr.key} <= ${maxVal}`);
    }
  });

  moreCompsParams.listing_statuses = filters.listing_statuses;
  moreCompsParams.sale_types = filters.sale_types;
  moreCompsParams.structure_types = filters.structure_types;
  moreCompsParams.max_distance = filters.distance;
  moreCompsParams.existing_comparables = proforma.comparables.map((comp: any) =>
    omit(comp, [
      "parcel",
      "constraints_missed",
      "constraints_passed",
      "distance",
      "is_flip",
      "is_rental",
      "condition_category",
    ]),
  );
  moreCompsParams.structure = customStructure;
  moreCompsParams.return_limit = 25;

  return moreCompsParams;
};

export const useMoreComparables = (proforma: any) => {
  const { customStructure } = useCustomStructure(proforma);
  const { setSalesComparables } = useSalesComps();
  const [loading, setLoading] = useState<boolean>(false);
  const [numMoreComps, setNumMoreComps] = useState<number | null>(null);

  const searchMoreComps = (newFilters: any) => {
    const params = buildMoreComparablesParams(newFilters, customStructure, proforma);
    setLoading(true);
    setNumMoreComps(null);
    fetcherWithCredentials(
      appConfig.apiEndpoint.moreComparables.replace(":parcel_id", proforma.parcel_id.toString()),
      "PUT",
      params,
    )
      .then((comparables: SalesComparableType[]) => {
        setNumMoreComps(comparables.length);
        if (comparables.length > 0) {
          const salesComps = processSalesComparables(comparables);
          setSalesComparables(salesComps);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    searchMoreComps,
    numMoreComps,
  };
};
