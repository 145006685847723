import { FilterHybridDropdownMenu } from "components/browse";
import { cn } from "helpers/util";
import { isMobile } from "react-device-detect";
import { Control, Controller, FieldValues, UseFormSetValue } from "react-hook-form";

type Props = {
  label: string;
  variable: string;
  unit?: string;
  withBadge?: boolean;
  withLabel?: boolean;
  filters: any;
  control: Control<FieldValues, unknown>;
  handleChange: (e: any) => void;
  setValue: UseFormSetValue<FieldValues>;
  options: number[];
  className?: string;
};

export const MinMaxHybridDropdowns = ({
  label,
  variable,
  unit = "",
  withBadge = false,
  withLabel = true,
  filters,
  control,
  handleChange,
  setValue,
  options = [],
  className,
}: Props) => {
  const min_value = filters[`min_${variable}`];
  const max_value = filters[`max_${variable}`];

  const commonClasses =
    "flex w-full h-9 text-right border-dotted border-gray-400 items-center border px-2 py-4";

  return (
    <div className={cn("mt-2 flex w-full flex-row items-center justify-between gap-1 sm:gap-4", className)}>
      {withLabel && (
        <div className="max-w-1/3 relative flex w-1/3 whitespace-nowrap">
          {variable === "equity" ? "Equity" : label}
          {withBadge && (
            <>
              <span className="sr-only">New filter badge</span>
              <span
                className={cn(
                  "ml-2 mt-1 inline-flex h-4 w-fit items-center justify-center rounded-sm border-red-500 bg-red-500 px-2 text-[10px] font-bold text-white",
                )}
              >
                {isMobile ? "NEW" : "NEW FILTER"}
              </span>
            </>
          )}
        </div>
      )}
      <div className={cn("flex flex-row gap-1 sm:gap-4", withLabel ? "w-2/3" : "w-full")}>
        <div className="flex w-full">
          <Controller
            control={control}
            name={`min_${variable}`}
            render={({ field: { onChange, name, value } }) => (
              <FilterHybridDropdownMenu
                className={commonClasses}
                placeholder={"Min"}
                label={label}
                value={value}
                unit={unit}
                filters={filters}
                variable={variable}
                options={max_value ? options.filter((o) => o < max_value) : options}
                onChange={(min_value: any) => {
                  onChange(min_value);
                  setValue(name, min_value);
                  handleChange(min_value);
                }}
              />
            )}
          />
        </div>
        <div className="py-1 text-st-lightest">-</div>
        <div className="flex w-full">
          <Controller
            control={control}
            name={`max_${variable}`}
            render={({ field: { onChange, name, value } }) => (
              <FilterHybridDropdownMenu
                className={commonClasses}
                placeholder={"Max"}
                label={label}
                value={value}
                unit={unit}
                filters={filters}
                variable={variable}
                options={min_value ? options.filter((o) => o > min_value) : options}
                onChange={(max_value: any) => {
                  onChange(max_value);
                  setValue(name, max_value);
                  handleChange(max_value);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
