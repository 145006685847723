import { TrashIcon, XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { formatOption } from "components/admin";
import { buildProformasAddressUrl } from "helpers";
import { TypeAheadItemType } from "helpers/typeAheadApi";
import { Link } from "react-router-dom";
import { useParcelPhotos } from "state/proformas";

type Props = {
  showTakePhotosDownModal: boolean;
  setShowTakePhotosDownModal: (show: boolean) => void;
  item: TypeAheadItemType;
  onConfirm: (parcelId: number) => void;
};

export const TakePhotosDownModal = ({
  showTakePhotosDownModal,
  setShowTakePhotosDownModal,
  item,
  onConfirm,
}: Props) => {
  const { photos } = useParcelPhotos(item?.parcel_id ?? 0);

  const handleClose = () => {
    setShowTakePhotosDownModal(false);
  };

  const handleSubmit = () => {
    onConfirm(item?.parcel_id ?? 0);
    setShowTakePhotosDownModal(false);
  };

  const option = item ? formatOption(item) : undefined;

  return (
    <ModalWrapper isOpen={showTakePhotosDownModal} closeModal={() => handleClose()}>
      <div className="inline-block h-full max-h-fit w-fit max-w-xl transform overflow-hidden rounded-md px-0 text-left align-middle shadow-xl transition-all">
        <div className="flex h-full w-full justify-center">
          <div className="w-full max-w-5xl overflow-y-scroll rounded-md bg-white pb-2 shadow-lg">
            <div className="flex h-full flex-col">
              <header className="fixed z-10 flex w-full flex-row items-center bg-white">
                <div className="text-md flex basis-4/5 items-center pl-8 font-medium text-st-normal">
                  <TrashIcon className="mr-2 mt-1 w-6" aria-hidden="true" /> Take Property Photos Down
                </div>

                <div className="justify-right basis-1/5 p-4 pr-4 text-right">
                  <button
                    type="button"
                    className="rounded-md pb-1 pl-1 text-sm font-medium text-st-lightest hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                    onClick={() => handleClose()}
                  >
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </header>
              <main className="flex flex-col bg-gray-50 p-8 pb-1 pt-24 text-sm">
                <div>Are you sure you want to take this property's photos down?</div>
                <div className="ml-2 mt-2 border-l-2 border-gray-300 pl-2">
                  <Link
                    className="text-sl-normal hover:text-sl-lighter"
                    to={buildProformasAddressUrl(item?.full_address)}
                    target="_blank"
                  >
                    <div className="pt-2">{option?.line1}</div>
                    <div className="pb-1">{option?.line2}</div>
                  </Link>
                </div>
                <div
                  className={`mt-4 max-h-[calc(4*6rem+4rem)] ${photos?.length > 16 && "cursor-ns-resize overflow-y-auto"}`}
                >
                  <div className="grid grid-cols-4 gap-4">
                    {photos
                      ?.sort((a: any, b: any) => a.display_order - b.display_order)
                      .map((photo: any) => (
                        <div key={photo._id} className="aspect-square h-24">
                          <img
                            src={photo.photo_url}
                            alt={photo._id}
                            className="h-full w-full rounded-md object-cover"
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="flex items-center justify-center gap-4">
                  <button
                    className="my-6 inline-flex cursor-pointer items-center whitespace-nowrap rounded-sm border border-gray-300 bg-white px-8 py-2 text-sm font-normal text-st-darkest shadow-sm transition duration-150 ease-in-out"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="my-6 inline-flex cursor-pointer items-center whitespace-nowrap rounded-sm bg-sb-primary px-8 py-2 text-sm font-normal text-white shadow-sm transition duration-150 ease-in-out hover:bg-sb-primary-hover"
                    onClick={handleSubmit}
                  >
                    Yes, take photos down
                  </button>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
