import React from "react";

type Props = {
  type: string;
  label: string;
  errMsg?: any;
  disabled?: boolean;
  value?: string;
};

export const LabeledInput = React.forwardRef(
  ({ type, label, errMsg, ...rest }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <label className="mt-3 block">
        <span className="text-st-normal pl-3 text-sm">{label}</span>
        <input
          type={type}
          ref={ref}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errMsg ? "border-red-500" : ""} `}
          {...rest}
        />
        {errMsg && <div className="mt-1 text-sm text-red-500">{errMsg}</div>}
      </label>
    );
  },
);
