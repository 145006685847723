import { ChartBarIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { GraphTypes } from "components/proformas/financials/rentaltab/";

const HelpText: Record<GraphTypes, { title: string; body: string }> = {
  equity: {
    title: "EQUITY GRAPH",
    body: "This graph shows the property value of the deal over time. The net equity is also shown over time, and is a combined total of the cash in, selling costs, and loan balance. Hovering over any point on the graph will display a tooltip showing the specific values for that particular month.",
  },
  monthlycashflow: {
    title: "MONTHLY CASHFLOWS GRAPH",
    body: "This graph shows a snapshot of the rent, costs, profit, and cash flow on cash rate for any month during the holding period of the property. Hovering over any point on the graph will display a tooltip showing the specific values for that particular month.",
  },
  netprofit: {
    title: "CUMULATIVE PROFIT GRAPH",
    body: "This graph shows the net equity value in relation to the cashflow profits(loss) for the subject property during the holding time. The net equity includes the cash in, selling costs, and loan balance for the property that year. The cash flows represent all rent revenue, less any holding or loan costs. Hovering over any point on the graph will display a tooltip showing the specific values for that particular year.",
  },
  rates: {
    title: "RATES OF RETURN GRAPH",
    body: "This graph shows various investment and rates of return ratios to review the subject property's investment potential. Hovering over any point on the graph will display a tooltip showing the specific values for that particular year.",
  },
};

type Props = {
  isOpen: boolean;
  closeModal: any;
  graphType: GraphTypes;
};

export const HelpModal = ({ isOpen, closeModal, graphType }: Props) => {
  return (
    <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
      <div className="mt-4 inline-block h-full max-h-fit w-full max-w-xl transform overflow-hidden rounded-md px-0 pt-14 text-left align-middle shadow-xl transition-all">
        <div className="flex h-full max-h-[90%] w-full justify-center">
          <div className="w-full max-w-5xl rounded-md bg-white shadow-lg">
            <div className="flex h-full flex-col">
              <header className="flex w-full flex-row items-center">
                <div className="text-md flex basis-4/5 items-center pl-8 font-medium text-st-normal">
                  <ChartBarIcon className="mr-2 mt-1 w-6" aria-hidden="true" />
                  {HelpText[graphType].title}
                </div>
                <div className="justify-right basis-1/5 p-4 pr-4 text-right">
                  <button
                    type="button"
                    className="rounded-md pb-1 pl-1 text-sm font-medium text-st-lightest hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </header>
              <main className="flex h-64 flex-grow flex-col bg-gray-50 p-8 pt-4">
                {HelpText[graphType].body}
              </main>
              <footer className="mb-2 bg-gray-50 p-4 text-center font-bold">
                <button
                  type="button"
                  className="items-center rounded-sm bg-[#61a28d] px-4 py-2 text-sm font-normal text-white shadow-lg transition duration-150 ease-in-out hover:bg-[#5bbc9c] sm:h-10 sm:px-8"
                  onClick={closeModal}
                >
                  CLOSE
                </button>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
