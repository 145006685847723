import { SearchBox } from "components/SearchBox";

export const NotFound = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-[url('https://cdn.davinci.pellego.com/static/images/home_bg.jpg')] bg-cover bg-no-repeat text-center">
      <div className="text-st-light mb-8 text-sm font-light uppercase">404 Error</div>
      <div className="text-st-light mb-4 max-w-lg pb-4 text-3xl font-extralight sm:text-5xl">
        Nothing to see here
      </div>
      <div className="text-st-light max-w-md text-lg font-extralight">
        Sorry but we couldn't find a page at the requested location. Try searching for a new location below:
      </div>
      <div className="text-md my-2 flex h-24 w-full max-w-3xl items-center justify-center text-left leading-10 sm:my-4">
        <div className="flex w-full max-w-md items-center px-4">
          <SearchBox />
        </div>
      </div>
    </div>
  );
};
