import { ChevronLeftIcon, ChevronRightIcon, StarIcon } from "@heroicons/react/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/solid";
import { Footer, WithSignIn } from "components";
import { BrowseInsights, NoAVMNotice, PropertiesGrid, PropertiesList } from "components/browse";
import { SearchPerformingMarkets } from "components/markets";
import { RegisterClientOrAgentModal } from "components/RegisterClientOrAgentModal";
import { appConfig } from "config";
import { cn } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import TimeAgo from "react-timeago";
import { useSearchParamsState } from "react-use-search-params-state";
import { useRecoilValue } from "recoil";
import { useCurrentStates, useRecentSearches, useUserSession } from "state";
import {
  filteredSearchResultsNoAVMCountSelector,
  paginationDefaults,
  searchResultCountsAtom,
  searchResultsFiltersAtom,
  SearchResultType,
} from "state/browse";
import { useMarketDataSearchPerformers } from "state/market";
import { useUpdateDate } from "state/proformas";
import { useSelectedProperty } from "state/ui";

type Props = {
  searchResults: SearchResultType[];
  onlyFavorites: boolean;
  setOnlyFavorites: (value: boolean) => void;
  sortedSearchResults: SearchResultType[];
};

export const ResultsPanelDesktop = ({
  searchResults,
  onlyFavorites,
  setOnlyFavorites,
  sortedSearchResults,
}: Props) => {
  const currentUser = useUserSession();
  const [currentPage, setCurrentPage] = useState(0);
  const searchResultCounts = useRecoilValue(searchResultCountsAtom);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { selectedProperty, setSelectedProperty } = useSelectedProperty();
  const [showMode, setShowMode] = useState<"grid" | "table">("grid");
  const data = useMarketDataSearchPerformers();
  const performersByMarketAvailable = data.bottom_performers.length > 0 && data.top_performers.length > 0;
  const { currentStates } = useCurrentStates();
  const stateId = currentStates[0];
  const [paginationParams, setPaginationParams] = useSearchParamsState(paginationDefaults);

  const [show, setShow] = useState(true);
  const filters = useRecoilValue(searchResultsFiltersAtom);
  const count = useRecoilValue(filteredSearchResultsNoAVMCountSelector);
  const showAVMNotice =
    !currentUser.isAgent &&
    !currentUser.isClient &&
    (filters.min_gross_gain !== null ||
      filters.max_gross_gain !== null ||
      filters.min_gross_income !== null ||
      filters.max_gross_income !== null) &&
    count !== 0;

  useRecentSearches({});

  useEffect(() => {
    const idx = currentPage * appConfig.browse.defaultPageSize;
    if (
      idx < paginationParams.offset ||
      idx >= paginationParams.offset + appConfig.browse.browseApiPageOffset
    ) {
      setCurrentPage(paginationParams.offset / appConfig.browse.defaultPageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults, paginationParams.offset]);

  const resultsPerPage =
    appConfig.browse.showAd && appConfig.browse.statesWithAds.includes(stateId)
      ? appConfig.browse.defaultPageSize - 1
      : appConfig.browse.defaultPageSize;
  const numPages = Math.ceil(searchResultCounts.total / resultsPerPage);

  const pagedResults = searchResults.slice(
    currentPage * resultsPerPage - paginationParams.offset,
    (currentPage + 1) * resultsPerPage - paginationParams.offset,
  );
  const updateDateText = useUpdateDate(stateId);

  useEffect(() => {
    if (selectedProperty) {
      // find selected property in search results and determine the page number
      const idx = searchResults.findIndex((property) => property.parcel_id === selectedProperty.parcel_id);
      const selectedPropertyPage = Math.floor(
        idx / resultsPerPage + paginationParams.offset / resultsPerPage,
      );

      if (selectedPropertyPage !== currentPage) {
        setCurrentPage(selectedPropertyPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty]);

  const handlePageClick = (page: { selected: number }) => {
    setCurrentPage(page.selected);
    setSelectedProperty(undefined);
    const browseOffset = page.selected * resultsPerPage;
    const browseOffsetPage = Math.floor(browseOffset / appConfig.browse.browseApiPageOffset);
    const expectedOffset = browseOffsetPage * appConfig.browse.browseApiPageOffset;
    if (expectedOffset !== paginationParams.offset) {
      setPaginationParams({ offset: expectedOffset });
    }
    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex size-full flex-col">
      <div className="relative flex size-full w-full flex-1 grow flex-col overflow-y-scroll" ref={scrollRef}>
        <div className="z-20 flex w-full flex-row items-center justify-between bg-white py-4">
          <div className="pl-4 text-sm sm:pl-6">
            <span className="font-bold">{searchResultCounts.total}</span> out of
            <span className="font-bold">&nbsp;{searchResultCounts.totalUnfiltered}</span> listings
            <span className="hidden xl:inline-block">&nbsp;match your filters</span>
            <span
              className="inline-block cursor-pointer"
              onClick={() => currentUser.isLogged && setOnlyFavorites(!onlyFavorites)}
            >
              <WithSignIn useChildren>
                {onlyFavorites ? (
                  <SolidStarIcon
                    className="ml-2 inline h-5 w-5 text-yellow-500 shadow-xl"
                    aria-hidden="true"
                  />
                ) : (
                  <StarIcon className="ml-2 inline h-5 w-5 shadow-xl" aria-hidden="true" />
                )}
              </WithSignIn>
            </span>
          </div>
          <div className="flex cursor-pointer flex-row items-center gap-1">
            <div
              className={cn(
                "mx-1 ml-4 whitespace-nowrap border-b-2 pb-1 text-xs text-st-lighter",
                { "border-b-transparent hover:border-b-gray-200": showMode === "grid" },
                { "border-b-[#7ba1bb]": showMode === "table" },
              )}
              onClick={() => setShowMode("table")}
            >
              Table
            </div>
            <div
              className={cn(
                "mx-1 mr-4 whitespace-nowrap border-b-2 pb-1 text-xs text-st-lighter sm:mr-6",
                { "border-b-transparent hover:border-b-gray-200": showMode === "table" },
                { "border-b-[#7ba1bb]": showMode === "grid" },
              )}
              onClick={() => setShowMode("grid")}
            >
              Grid
            </div>
          </div>
        </div>

        {showMode === "grid" && (
          <PropertiesGrid stateId={stateId} properties={pagedResults} selectedProperty={selectedProperty} />
        )}
        {showMode === "table" && (
          <PropertiesList properties={pagedResults} selectedProperty={selectedProperty} />
        )}
        {numPages > 0 && (
          <ReactPaginate
            pageCount={numPages}
            forcePage={currentPage}
            onPageChange={handlePageClick}
            pageRangeDisplayed={currentPage < 3 || currentPage > numPages - 3 ? 4 : 3}
            marginPagesDisplayed={1}
            previousLabel={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
            nextLabel={<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />}
            containerClassName="mt-4 flex justify-center text-sm text-st-lighter"
            previousClassName="mr-1"
            previousLinkClassName="py-1 hover:text-st-darker cursor-pointer"
            nextClassName="ml-1"
            nextLinkClassName="py-1 hover:text-st-darker cursor-pointer"
            pageClassName="mx-1"
            activeClassName="text-white "
            disabledClassName="text-st-lightest"
            pageLinkClassName="px-2.5 py-1 cursor-pointer border border-[#6389c4] rounded-md"
            activeLinkClassName="px-2.5 py-1 bg-[#6389c4] cursor-default border border-[#6389c4] rounded-md"
            disabledLinkClassName="cursor-default"
            renderOnZeroPageCount={null}
          />
        )}

        <div className="mb-10 mt-20 flex w-full flex-col items-center justify-center px-4">
          <h1 className="mb-10 text-left text-xl font-medium sm:text-3xl">Market Insights</h1>
          {performersByMarketAvailable && (
            <div className="mb-6 flex w-full flex-row flex-wrap justify-around">
              <SearchPerformingMarkets data={data} order="top" />
              <SearchPerformingMarkets data={data} order="bottom" />
            </div>
          )}
          <BrowseInsights stateId={stateId} />
        </div>

        <div className="my-6 flex w-full items-center justify-center text-sm">
          Listings last updated&nbsp;
          <TimeAgo className="inline" date={new Date(updateDateText)} live={false} />
        </div>
        <Footer browsePage={true} />
      </div>
      <NoAVMNotice showAVMNotice={showAVMNotice} show={show} setShow={setShow} />
      <RegisterClientOrAgentModal />
    </div>
  );
};
