import { ChatIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { AgentProperties, ContactForm, highlightAreaHandlerAtom, ServiceAreasMap } from "components/agents";
import { isPellego, WASHINGTON_STATE_ID } from "config";
import delay from "lodash/delay";
import { NotFound } from "pages";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ServiceAreaType, useAgent } from "state";

export const Agent = () => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const [selectedTab, setSelectedTab] = useState<"sold" | "listed">("sold");
  const highlightHandler = useRecoilValue(highlightAreaHandlerAtom);
  const params = useParams();
  let agentSlug = params.agentSlug;
  const { error, data } = useAgent(agentSlug);

  const onMouseHover = (serviceArea: ServiceAreaType | null) => {
    if (highlightHandler) {
      highlightHandler(serviceArea);
    }
  };

  if (error) return <NotFound />;

  return (
    <div className="flex w-full flex-col">
      {data && (
        <>
          <div className="flex w-full flex-col items-center bg-[#3F464A] px-4 pt-10">
            <div className="flex w-full max-w-5xl flex-col gap-8 pb-8 sm:flex-row sm:pb-14">
              <div className="order-last flex h-full w-full basis-2/3 flex-col px-4 sm:order-first sm:px-0">
                <div className="h-full text-4xl text-white sm:text-7xl">
                  {data.agent && <h1 className="font-serif tracking-tight">{data.agent.name}</h1>}
                </div>
                <div className="h-full pt-5 text-2xl font-extralight text-[#B9B9B9]">Real Estate Agent</div>
                <div className="text-md h-full font-thin text-[#B9B9B9]">
                  State License Number {data.agent.state_license_id}
                </div>
                <div className="text-md h-full max-w-md pt-5 font-extralight text-gray-100">
                  {data.agent_user.description}
                </div>

                {data.agent_user.service_areas && (
                  <h2 className="mb-2 mt-4 text-sm font-medium text-white">Specialties</h2>
                )}
                <div className="flex max-w-sm flex-wrap">
                  {data.agent_user?.skills?.map((skill) => (
                    <span
                      key={skill}
                      className="text-st-soft my-1 mr-2 whitespace-nowrap rounded-2xl border border-gray-200 px-2 py-1 text-xs font-medium capitalize"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
              <div className="basis-1/1 flex w-full flex-col sm:basis-1/2">
                <div className="text-st-light flex w-full justify-center text-xl sm:justify-end">
                  {data.agent_user.avatar && (
                    <div className="w-full items-center justify-center shadow-lg sm:max-w-sm">
                      {data.agent_user.avatar && (
                        <img className="w-full rounded-sm" src={data.agent_user.avatar} alt="avatar" />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 flex w-full flex-col items-center px-4">
            <div className="relative flex w-full max-w-5xl flex-col gap-6 sm:flex-row">
              <div className="basis-1/1 flex w-full flex-col md:basis-2/3">
                <div className="flex w-full flex-col gap-6 md:flex-row">
                  <div className="basis-1/1 w-full pb-2 md:pb-0">
                    <h2 className="pb-2 font-medium">Areas of service</h2>

                    <div className="h-80 w-full lg:h-[520px]">
                      <ServiceAreasMap
                        serviceAreas={data.agent_user.service_areas || []}
                        soldProperties={data.sold}
                        listedProperties={data.listed}
                        defaultRegion={data.agent_user.default_region || WASHINGTON_STATE_ID}
                      />
                    </div>

                    <div className="basis-1/1 w-full">
                      {data.agent_user.service_areas && <h2 className="mb-2 mt-4 font-medium">Locations</h2>}
                      <div className="flex flex-wrap">
                        {data.agent_user?.service_areas?.map((service_area) => (
                          <span
                            key={`${service_area.name}-${service_area.id}`}
                            className="text-sl-dark my-1 mr-2 whitespace-nowrap rounded-2xl border border-blue-500 px-2 py-1 text-xs font-medium capitalize hover:bg-blue-200"
                            onMouseEnter={() => onMouseHover(service_area)}
                            onMouseLeave={() => onMouseHover(null)}
                          >
                            {service_area.name} {service_area.type === "county" && "County"}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* propiedades */}
                {(data.listed.length > 0 || data.sold.length > 0) && (
                  <div className="mt-10 flex w-full flex-col items-center border-t border-gray-300">
                    <div className="pt-10 text-center text-xl font-bold">Experience</div>
                    <div className="text-md text-st-light mb-2 text-center leading-9">
                      See my current listings and past sales
                    </div>

                    {data.listed.length > 0 && (
                      <div className="flex w-full max-w-md flex-row pb-4">
                        <button
                          className={`w-full rounded-l-sm border border-[#38455b] py-2.5 text-sm font-medium uppercase leading-5 ${selectedTab === "sold" ? "bg-[#38455b] text-white" : "text-st-light"}`}
                          onClick={() => setSelectedTab("sold")}
                        >
                          Sold
                        </button>
                        <button
                          className={`w-full rounded-r-sm border border-[#38455b] py-2.5 text-sm font-medium uppercase leading-5 ${selectedTab === "listed" ? "bg-[#38455b] text-white" : "text-st-light"}`}
                          onClick={() => setSelectedTab("listed")}
                        >
                          Listed
                        </button>
                      </div>
                    )}

                    <AgentProperties properties={data[selectedTab]} />
                  </div>
                )}

                <div className="sticky bottom-0 z-[9990] flex w-full justify-center py-4 sm:hidden sm:justify-end">
                  <div className="flex w-full justify-end pr-3">
                    <div
                      className="text-md flex h-full cursor-pointer rounded-md bg-[#61a28d] p-4 text-center font-medium tracking-wide text-white shadow-xl hover:bg-[#5bbc9c]"
                      onClick={openModal}
                    >
                      <ChatIcon className="w-6" aria-hidden="true" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="basis-1/1 hidden w-full sm:inline sm:basis-1/3">
                <div className="sticky top-10 flex flex-col border bg-white p-6 shadow-lg" id="contact">
                  <h2 className="font-medium">How can I help?</h2>

                  <div className="w-full items-center justify-center pt-2">
                    <ContactForm toEmail={data.agent_user.email} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col items-center bg-[#3F464A] px-4 pt-10 sm:mt-10">
            <div className="flex w-full max-w-5xl flex-row items-center gap-8 pb-8 sm:pb-14">
              <div className="flex h-full w-full basis-2/3 flex-col px-4 sm:px-0">
                <div className="h-full text-4xl text-white sm:text-7xl">
                  <img src="/images/core/pgo-logo-trans.png" width="150" alt="" />
                </div>
              </div>

              <div className="basis-1/1 flex w-full flex-col justify-end sm:basis-1/2">
                <div className="text-st-lightest flex w-full justify-end text-right text-sm">
                  Copyright {isPellego ? "Pellego" : "Lotside"} 2022
                </div>
              </div>
            </div>
          </div>

          <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
            <div className="z-[999] inline-block w-full max-w-sm transform overflow-hidden rounded-lg border border-gray-200 bg-white p-2 text-left align-middle shadow-xl transition-all">
              <h3 className="text-st-darker flex w-full border-b p-2 text-lg font-medium leading-6">
                <div className="text-md text-st-normal flex basis-4/5 items-center font-medium">
                  How can I help?
                </div>
                <div className="justify-right basis-1/5 text-right">
                  <button
                    type="button"
                    className="text-st-lightest rounded-md pb-1 pl-1 text-sm font-medium hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </h3>

              <div className="top-10 flex flex-col bg-white p-2" id="contact">
                <div className="text-st-light text-sm">
                  Feel out this form and I will get in touch with you soon:
                  {/* add city and zip code no listing */}
                </div>

                <div className="w-full items-center justify-center pt-2">
                  <ContactForm
                    toEmail={data.agent_user.email}
                    onSubmit={() => {
                      delay(closeModal, 1000);
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalWrapper>
        </>
      )}
    </div>
  );
};
