import { Menu, Transition } from "@headlessui/react";
import {
  BookmarkIcon,
  DotsVerticalIcon,
  HomeIcon,
  PencilAltIcon,
  ShareIcon,
  XIcon,
} from "@heroicons/react/outline";
import { WithSignIn } from "components";
import { SaveProforma } from "components/proformas";
import { ShareModal, showStructureModalAtom, StructureModal } from "components/proformas/modals";
// import { ListsModal} from "components/proformas/modals";
import { buildProformasAddressUrl } from "helpers";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useUserFromShareToken, useUserSession } from "state";
import { useProforma } from "state/proformas";

type Props = {
  openDir?: "left" | "right";
};

export const OptionsMenu = ({ openDir = "right" }: Props) => {
  // const [showListsModal, setShowListsModal] = useState(false);
  const [showStructureModal, setShowStructureModal] = useRecoilState(showStructureModalAtom);
  const [showShareModal, setShowShareModal] = useState(false);
  const currentUser = useUserSession();
  const { proforma } = useProforma();
  const { sharingUser } = useUserFromShareToken(proforma);

  return (
    <Menu as="div" className="relative z-50 inline-block text-left">
      <div>
        <Menu.Button
          className="hover:transparent inline-flex justify-end rounded-3xl bg-transparent p-2 text-right text-sm font-normal transition duration-150 ease-in-out sm:ml-2 sm:pl-2"
          aria-label="Show Options"
        >
          <DotsVerticalIcon className="h-6 w-6 text-st-lightest" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${
            openDir === "left" ? "right" : "left"
          }-0 top-8 z-50 mt-4 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            <Menu.Item>
              <div className="flex cursor-pointer px-4 py-2 text-sm text-st-normal hover:bg-gray-100 hover:text-st-darker lg:hidden">
                <BookmarkIcon className="mr-4 h-6 w-6 text-st-lightest" />
                <SaveProforma text="Save" />
              </div>
            </Menu.Item>

            <Menu.Item>
              <div
                onClick={() => setShowStructureModal(true)}
                className="flex cursor-pointer px-4 py-2 text-sm text-st-normal hover:bg-gray-100 hover:text-st-darker"
              >
                <PencilAltIcon className="mr-4 h-6 w-6 text-st-lightest" />
                Edit Structure
              </div>
            </Menu.Item>

            <Menu.Item>
              <WithSignIn useChildren>
                <div
                  onClick={() => currentUser.isLogged && setShowShareModal(true)}
                  className="flex cursor-pointer px-4 py-2 text-sm text-st-normal hover:bg-gray-100 hover:text-st-darker"
                >
                  <ShareIcon className="mr-4 h-6 w-6 text-st-lightest" />
                  Share Analysis
                </div>
              </WithSignIn>
            </Menu.Item>

            {sharingUser && (
              <Menu.Item>
                <Link to={buildProformasAddressUrl(proforma.parcel.full_address)}>
                  <div className="flex cursor-pointer px-4 py-2 text-sm text-st-normal hover:bg-gray-100 hover:text-st-darker">
                    {" "}
                    <XIcon className="mr-4 h-6 w-6 text-st-lightest" />
                    Reset Analysis
                  </div>
                </Link>
              </Menu.Item>
            )}

            {proforma.proforma_type === "new_construction" && (
              <Menu.Item>
                <Link to={buildProformasAddressUrl(proforma.parcel.full_address)} target="_blank">
                  <div className="flex cursor-pointer px-4 py-2 text-sm text-st-normal hover:bg-gray-100 hover:text-st-darker">
                    {" "}
                    <HomeIcon className="mr-4 h-6 w-6 text-st-lightest" />
                    Flip / Rental
                  </div>
                </Link>
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>

      {/* {showListsModal && <ListsModal isOpen={showListsModal} closeModal={() => setShowListsModal(false)} />} */}
      {showStructureModal && (
        <StructureModal isOpen={showStructureModal} closeModal={() => setShowStructureModal(false)} />
      )}
      {showShareModal && <ShareModal isOpen={showShareModal} closeModal={() => setShowShareModal(false)} />}
    </Menu>
  );
};
