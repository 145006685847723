import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon, XIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { isMobile } from "react-device-detect";
import { cn } from "../../helpers";

type Props = {
  className?: string;
  placeholder?: string;
  label: string;
  value: number | null;
  options: number[];
  onChange: CallableFunction;
  hidePlaceholderWithValue?: boolean;
};

export const FilterDropdownMenu = ({
  className,
  placeholder,
  label,
  value,
  options,
  onChange,
  hidePlaceholderWithValue,
}: Props) => {
  const valueOptions = options;

  return (
    <Menu as="div" className="relative flex w-full">
      {({ open }) => (
        <>
          <Menu.Button className={cn(className, "flex w-full justify-end py-2 pl-2 pr-1 sm:pl-3 sm:pr-2")}>
            <span className="flex items-center whitespace-nowrap text-st-light">
              {(isMobile || hidePlaceholderWithValue) && value !== null ? (
                ` ${value}%`
              ) : value !== null ? (
                ` ${placeholder} ${value}%`
              ) : (
                <span className="text-st-lightest">{placeholder}</span>
              )}
            </span>
            {open ? (
              <ChevronUpIcon className="ml-2 size-4" />
            ) : (
              <>
                {value !== null ? (
                  <XIcon
                    className="ml-2 size-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange(null);
                    }}
                  />
                ) : (
                  <ChevronDownIcon className="ml-2 size-4" />
                )}
              </>
            )}
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute end-0 top-7 z-30 mt-2 flex w-full origin-top-left justify-end rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="m-0 w-full p-0">
                {valueOptions.map((val, idx) => (
                  <Menu.Item key={idx}>
                    <div className="flex w-full text-sm" key={`ggo-${val}`}>
                      <button
                        className={`m-1 w-full p-1 text-right ${
                          value === val
                            ? "bg-[#6389c4] text-white"
                            : "bg-white text-st-light hover:bg-[#6389c4] hover:text-white"
                        }`}
                        onClick={() => onChange(val === value ? null : val)}
                      >
                        {val === 0 ? `0%` : `${val}%`}
                      </button>
                    </div>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
