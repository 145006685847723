import NumberFormat from "react-number-format";
import { useFinancials } from "state/proformas";

type Props = {
  loan: any;
};

export const LoanDetails = ({ loan }: Props) => {
  const { financials, updateLoan, updateCalcSheet } = useFinancials();
  const dpayPct = loan.name === "brrr_mortgage" ? (1 - loan.dpay) * 100 : loan.dpay * 100;

  const toggleEnabledStatus = () => {
    updateLoan({
      ...loan,
      is_enabled: !loan.is_enabled,
    });
  };

  const onMortgageLengthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateCalcSheet({ mortgage_length: Number(e.target.value) });
  };

  const toggleFinancedOrigination = () => {
    updateLoan({
      ...loan,
      is_financed_origination: !loan.is_financed_origination,
    });
  };

  const onDownPaymentChange = (values: any) => {
    if (values.floatValue || values.floatValue === 0) {
      let dpay = values.floatValue / 100;
      if (loan.name === "brrr_mortgage") {
        dpay = 1 - dpay;
      }
      updateLoan({ ...loan, dpay });
    }
  };

  const onInterestChange = (values: any) => {
    if (values.floatValue || values.floatValue === 0) {
      updateLoan({
        ...loan,
        interest: values.floatValue / 100,
      });
    }
  };

  const onOriginationChange = (values: any) => {
    if (values.floatValue || values.floatValue === 0) {
      updateLoan({
        ...loan,
        origination: values.floatValue / 100,
      });
    }
  };

  return (
    <>
      <div className="mt-4 flex flex-row items-center justify-items-stretch gap-2 pb-4">
        <label className="flex w-full cursor-pointer whitespace-nowrap font-medium">
          <div className="w-full basis-3/5">
            <input
              className="mr-1 cursor-pointer border text-sl-lighter outline-0 indeterminate:bg-gray-300 focus:ring-0"
              type="checkbox"
              onChange={() => toggleEnabledStatus()}
              checked={loan.is_enabled}
            />
            {loan._display_name}
          </div>
          <div className="w-full basis-2/5 text-right">
            <NumberFormat
              className="w-full"
              value={loan._monthly_pmt}
              displayType="text"
              thousandSeparator={true}
              prefix="$ "
              decimalScale={0}
            />
          </div>
        </label>
      </div>

      <div className={`${loan.is_enabled ? "opacity-100" : "cursor-not-allowed opacity-20"}`}>
        <div className="w-full pl-6 text-st-light">
          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="basis-3/5 whitespace-nowrap">Mortgage Period</div>
            <div className="flex w-full basis-2/5">
              <div className="w-full text-center">
                <select
                  className="h-7 w-full border border-[#E4C442] bg-stone-200 py-0 text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                  value={financials?.calcsheet.mortgage_length}
                  onChange={onMortgageLengthChange}
                  disabled={!loan.is_enabled}
                >
                  <option value="15">15 yrs</option>
                  <option value="30">30 yrs</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="basis-3/5 whitespace-nowrap">
              {loan.name === "brrr_mortgage" ? "Loan-to-Value" : "Down Payment"}
            </div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                defaultValue={dpayPct}
                thousandSeparator={false}
                suffix=" %"
                decimalScale={0}
                onValueChange={onDownPaymentChange}
                disabled={!loan.is_enabled}
              />
            </div>
          </div>

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="basis-3/5 whitespace-nowrap">Interest Rate</div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                defaultValue={loan.interest * 100}
                thousandSeparator={false}
                suffix=" %"
                decimalScale={2}
                onValueChange={onInterestChange}
                disabled={!loan.is_enabled}
              />
            </div>
          </div>

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="basis-3/5 whitespace-nowrap">Origination Fee</div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                defaultValue={loan.origination * 100}
                thousandSeparator={false}
                suffix=" %"
                decimalScale={2}
                onValueChange={onOriginationChange}
                disabled={!loan.is_enabled}
              />
            </div>
          </div>
          <div className="mt-2 flex flex-row items-center justify-items-stretch gap-2 pb-4 text-xs">
            <input
              className="border text-sl-lighter outline-0 indeterminate:bg-gray-300 focus:ring-0"
              type="checkbox"
              onChange={() => toggleFinancedOrigination()}
              checked={loan.is_financed_origination}
              disabled={!loan.is_enabled}
            />
            Finance Origination Fee
          </div>
        </div>
      </div>
    </>
  );
};
