import { ARIZONA_STATE_ID, isPellego, isStage, listingsData } from "config";
import { formatPropertyName } from "helpers";

export const daysfromdate = (then: string, now: string | null) => {
  const dateThen = new Date(then);
  let dateNow = new Date();
  if (now) dateNow = new Date(now);
  return Math.floor((dateNow.getTime() - dateThen.getTime()) / 1000 / 60 / 60 / 24);
};

export const loanCosts = (property: any): Number => {
  return 0.8 * (property.listingPrice + property.rehab_costs_flip) * (0.02 + 0.01 * property.carrying_time);
};

export const buildProformasAddressUrl = (fullAddress: any) => {
  return `/proformas/address/${fullAddress?.replace(/ /g, "-")}`;
};

export const buildProformasListingNumberUrl = (listingNumber: any) => {
  return `/proformas/listing_number/${listingNumber}`;
};

export const buildProformasAddressUrlNewConstruction = (fullAddress: any) => {
  return `/proformas/address/new_construction/${fullAddress?.replace(/ /g, "-")}`;
};

export const buildCompUrl = (comp: any) => {
  return `/proformas/${comp.parcel?.full_address
    ? "address/" + comp.parcel?.full_address?.replace(/ /g, "-")
    : "property/" + comp.parcel?._id
    }`;
};

export const prettifyListing = (parcel: any) => {
  const mrl = parcel.most_recent_listing;

  parcel._characteristics.listing_company = mrl.listing_company;
  parcel._characteristics.selling_company = mrl.selling_company;
  parcel._characteristics.listing_status = mrl.listing_status;
  if (mrl.listing_prices) {
    parcel._characteristics.list_price = mrl.listing_prices[mrl.listing_prices.length - 1];
  }

  // If we have a listing price, make an abbreviated version of it.
  if (parcel._characteristics.list_price)
    parcel._characteristics.list_price_k = mrl.listing_prices[mrl.listing_prices.length - 1]
      .toString()
      .substr(0, 3);
  else parcel._characteristics.list_price_k = 0;

  parcel._characteristics.listing_remarks = mrl.listing_remarks;
  parcel._characteristics.listing_number = mrl.listing_number;
  parcel._characteristics.sale_type = mrl.sale_type;

  // Find the last relevant date
  var alldates = [];
  if (mrl.listing_prices_dates) {
    alldates = mrl.listing_prices_dates.concat(
      mrl.active_dates || [],
      mrl.pending_dates || [],
      mrl.sale_date || [],
    );
  }

  // Sort to find the newest and return it
  parcel._characteristics.last_date = alldates.sort().reverse()[0];
  if (!parcel._characteristics.last_date && mrl.sale_date) {
    parcel._characteristics.last_date = mrl.sale_date;
  }

  // Find the last relevant price
  parcel._characteristics.last_price = mrl.sale_price || parcel._characteristics.list_price;
  parcel._characteristics.last_list_price = parcel._characteristics.list_price;

  // Are we currently on market?
  if (!mrl.sale_date && mrl.listing_status !== "Off Market") {
    parcel._characteristics.on_market = mrl.listing_status;
  }

  if (mrl.sale_type === "Standard") {
    parcel._characteristics.sale_type =
      "Standard " + (parcel._characteristics.on_market ? "Listing" : "Sale");
  }

  // Are we a flip?
  if (parcel.flip_listing) {
    parcel._characteristics.preflip_date = parcel.flip_listing.sale_date;
    parcel._characteristics.preflip_price = parcel.flip_listing.sale_price;
    parcel._characteristics.preflip_company = parcel.flip_listing.listing_company;
  }

  if (mrl.listing_status === "Active" && mrl.listing_prices_dates) {
    parcel._characteristics.dom = daysfromdate(mrl.listing_prices_dates[0], null);
    // Make the status "Active Listing" rather than just 'Active'
    parcel._characteristics.listing_status = "Active Listing";
  } else if (mrl.listing_status === "Pending" || mrl.listing_status === "Sold") {
    let date_ = mrl.sale_date;
    if (mrl.pending_dates) {
      date_ = mrl.pending_dates[mrl.pending_dates.length - 1];
    }
    if (mrl.listing_prices_dates) {
      parcel._characteristics.dom = daysfromdate(mrl.listing_prices_dates[0], date_);
    } else {
      parcel._characteristics.dom = null;
    }
  } else {
    parcel._characteristics.dom = null;
  }
  parcel._characteristics.adom = parcel._characteristics.dom;
  parcel._characteristics.lpdom = parcel._characteristics.dom;

  if (mrl.active_dates) {
    parcel._characteristics.adom = daysfromdate(mrl.active_dates[mrl.active_dates.length - 1], mrl.sale_date);
  }

  if (mrl.listing_prices_dates && mrl.listing_prices_dates.length > 1) {
    parcel._characteristics.lpdom = daysfromdate(
      mrl.listing_prices_dates[mrl.listing_prices_dates.length - 1],
      mrl.sale_date,
    );
  }

  // Find out when the most recent "event" was
  if (parcel._characteristics.adom < parcel._characteristics.lpdom) {
    parcel._characteristics.event = "Back on Market";
    parcel._characteristics.edom = parcel._characteristics.adom;
  } else if (parcel._characteristics.lpdom < parcel._characteristics.dom) {
    parcel._characteristics.event = "Days at Price";
    if (parcel._characteristics.lpdom >= 0) {
      parcel._characteristics.edom = parcel._characteristics.lpdom;
    } else {
      parcel._characteristics.edom = 0;
    }
  }

  // Set a nice dom
  if (typeof parcel._characteristics.adom !== "undefined") {
    if (parcel._characteristics.adom === 0) {
      parcel._characteristics.days = "Today";
    } else if (parcel._characteristics.adom === 1) {
      parcel._characteristics.days = "Yesterday";
    } else {
      parcel._characteristics.days = parcel._characteristics.adom + " days";
    }
  }

  parcel._characteristics.ppsf =
    parcel._characteristics.last_price / parcel._characteristics.square_feet_finished;
};

export const getCityToShow = (parcel: any) => {
  if (!parcel.city && parcel.most_recent_listing && parcel.most_recent_listing?.sale_city) {
    return parcel.most_recent_listing?.sale_city;
  }
  if (parcel.city && parcel.most_recent_listing && parcel.most_recent_listing?.sale_city && parcel.city !== parcel.most_recent_listing?.sale_city) {
    // if sale was less than two years ago
    if (parcel.most_recent_listing?.sale_date && parcel.most_recent_listing?.sale_date > new Date(new Date().setFullYear(new Date().getFullYear() - 2)).toISOString()) {
      return parcel.most_recent_listing?.sale_city;
    }
    if (parcel.most_recent_listing?.listing_prices_dates && parcel.most_recent_listing?.listing_prices_dates[0] > new Date(new Date().setFullYear(new Date().getFullYear() - 2)).toISOString()) {
      return parcel.most_recent_listing?.sale_city;
    }
  }
  return parcel.city;
};

export const prettifyParcel = (originalParcel: any) => {
  const parcel = { ...originalParcel };

  parcel._characteristics = {
    address: formatPropertyName(parcel.street_address),
    assessed_value: parcel.most_recent_value ? parcel.most_recent_value.assessed_tax_value : null,
    bathrooms: parcel.main_structure?.bathrooms,
    bedrooms: parcel.main_structure?.bedrooms,
    city: parcel.city ? parcel.city.toLowerCase() : null,
    city_for_address: getCityToShow(parcel),
    county: parcel.county,
    fireplaces: parcel.main_structure?.fireplaces,
    floors: parcel.main_structure?.floors,
    foundation: parcel.main_structure?.foundation_type,
    hoa_dues: parcel.hoa_dues,
    hvac: parcel.main_structure?.hvac,
    lot_square_feet: parcel.lot_square_feet,
    neighborhood: parcel.neighborhood || parcel.city,
    parcel: parcel.parcel_number,
    parking_count: parcel.parking_count,
    quality: parcel.main_structure?.quality,
    restrictions: parcel.environmental_restrictions,
    roof: parcel.main_structure?.roof_cover,
    sewage_type: parcel.sewage_type,
    siding: parcel.main_structure?.siding_cover,
    square_feet_finished: parcel.main_structure?.square_feet_finished,
    square_feet_garage: parcel.main_structure?.square_feet_garage,
    square_feet_basement_unfinished: parcel.main_structure?.square_feet_basement_unfinished,
    structure_type: parcel.main_structure?.structure_type,
    style: parcel.main_structure?.style || parcel.main_structure?.structure_type,
    structure_style: parcel.main_structure?.style,
    number_of_units_in_building: parcel.main_structure?.number_of_units_in_building,
    unfinished_sqft: parcel.main_structure?.square_feet_basement_unfinished,
    view_type: parcel.view_type && parcel.view_type.map((vt: string) => vt.replace(/[{}]/g, "")).join(", "),
    water_type: parcel.water_type,
    waterfront_type: parcel.waterfront_type,
    waterfront_footage: parcel.waterfront_footage || null,
    year_built: parcel.main_structure?.year_built,
    year_improved: parcel.main_structure?.year_last_improved,
  };

  if (parcel.most_recent_listing) {
    prettifyListing(parcel);
    // But watch out for historical sales
    if (parcel.most_recent_listing?.listing_status === "Sold") {
      parcel._characteristics.on_market = false;
    }
  } else {
    parcel._characteristics.on_market = false;
    parcel._characteristics.sale_type = "Off Market";
  }
  if (parcel.auction) {
    parcel._characteristics.on_auction = true;
  } else {
    parcel._characteristics.on_auction = false;
  }
  // A third geographic entity, depending on what's available
  if (parcel.school_district) {
    parcel._characteristics.thirdgeo = parcel.school_district + " School District";
  } else if (parcel.neighborhood !== parcel.city) {
    parcel._characteristics.thirdgeo = parcel.neighborhood;
  } else {
    parcel._characteristics.thirdgeo = parcel.county + "County";
  }

  // Make the thumbnail url easy so we can escape it
  parcel._thumbUrl = "url('" + parcel.thumbnail_url + "')";

  // Store a link to the county parcel
  const counties = {
    "washington.king": "http://info.kingcounty.gov/Assessor/eRealProperty/Dashboard.aspx?ParcelNbr=%s",
    "washington.pierce": "https://atip.piercecountywa.gov/#/app/propertyDetail/%s/summary",
    "washington.snohomish":
      "https://www.snoco.org/proptax/(mbmr0t45r3zniu3e5hf5pu55)/search.aspx?parcel_number=%s",
    "washington.kitsap": "https://psearch.kitsapgov.com/details.asp?RPID=%s",
  };

  if (parcel.state_obj?.name) {
    const ckey = (parcel.state_obj?.name?.toLowerCase() + "." + parcel.county).toLowerCase();
    if (counties[ckey as keyof typeof counties]) {
      parcel._characteristics.county_link = counties[ckey as keyof typeof counties].replace(
        "%s",
        parcel.parcel_number.replace("-", ""),
      );
    }
  }
  return parcel;
};

export const logosFromState = (currentStates: number[]) => {
  if (currentStates.includes(ARIZONA_STATE_ID)) {
    return {
      logoUrl:
        "https://cdn.davinci.pellego.com/static/images/west_usa_realty_logo.png",
      smallLogoUrl: "/images/core/westusarealty-small.jpeg",
    };
  }
  if (isPellego && !isStage) {
    return {
      logoUrl: "/images/core/pellego-logo.png",
      smallLogoUrl: "/images/core/pellego-logo-small.png",
    };
  }

  return {
    logoUrl: "/images/core/lotside-logo.png",
    smallLogoUrl: "/images/core/lotside-logo-small.png",
  };
};

export const getListingDataByName = (name: string) => {
  return listingsData.find(state => state.name === name);
};


export const getImageMeta = (url: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = url;
  });
};