import { Label } from "components/SearchBox";
import { cn, titleCase, TypeAheadItemType } from "helpers";
import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useWindowWidth } from "state";

type Props = {
  locations: TypeAheadItemType[];
  compact?: boolean;
  onRemoveItem: CallableFunction;
};

export const Labels = ({ locations, compact, onRemoveItem }: Props) => {
  const labels = [];
  const locationIds: Array<string | number | undefined> = [];
  var windowWidth = useWindowWidth(20);

  // Labels breakpoints
  var numberOfLabelsToShow = useRef(0);
  useEffect(() => {
    var breakPoints: { width: number; labels: number }[] = [
      { width: 1910, labels: 6 },
      { width: 1800, labels: 5 },
      { width: 1680, labels: 4 },
      { width: 1560, labels: 3 },
      { width: 1440, labels: 2 },
    ];
    var breakPoint = breakPoints.find((bp) => windowWidth >= bp.width);
    if (breakPoint) {
      if (locations.length > breakPoint.labels) {
        numberOfLabelsToShow.current = Math.max(Math.floor(locations.length / 2), breakPoint.labels - 1);
        numberOfLabelsToShow.current = Math.min(numberOfLabelsToShow.current, breakPoint.labels);
      } else {
        numberOfLabelsToShow.current = locations.length;
      }
    } else {
      if (locations.length <= 2) {
        numberOfLabelsToShow.current = 2;
      } else numberOfLabelsToShow.current = 1;
    }
  }, [windowWidth, locations]);

  if (isMobile) {
    if (compact && locations.length > 1) {
      labels.push(`${locations.length - 1} more`);
      locationIds.push(undefined);
    }
  } else {
    if (compact && locations.length - numberOfLabelsToShow.current > 1) {
      labels.push(`${locations.length - numberOfLabelsToShow.current} more`);
      locationIds.push(undefined);
    }
  }

  locations.forEach((location, idx) => {
    if (
      !compact ||
      (!isMobile && idx >= locations.length - numberOfLabelsToShow.current) ||
      (isMobile && idx === locations.length - 1)
    ) {
      if (location.type === "zip_code") {
        labels.push(`${location.id} Zip Code`);
        locationIds.push(location.id);
      } else if (location.name.includes("-")) {
        const words = location.name.split("-");
        labels.push(words.map((word) => titleCase(word)).join("-"));
        locationIds.push(location.id);
      } else {
        labels.push(titleCase(location.name));
        locationIds.push(location.id);
      }
    }
  });

  return (
    <div className={`flex cursor-pointer items-center ${compact ? "" : "max-w-[200px] flex-wrap"}`}>
      {labels.map((label, idx) => (
        <Label
          key={idx}
          label={label}
          locationId={locationIds[idx]}
          onRemove={onRemoveItem}
          compact={compact}
          allowRemove={locations.length > 1}
        />
      ))}
      {locations.length === 0 && (
        <span className={cn("text-st-lighter whitespace-nowrap pl-4", isMobile && "text-st-lightest")}>
          Search an address or region
        </span>
      )}
    </div>
  );
};
