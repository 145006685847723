import { Map } from "components/browse";
import { useSelectedProperty, useUserPropertiesData } from "state";

export const BrowseMapDesktop = () => {
  const { selectedProperty } = useSelectedProperty();
  const { userPropertiesData, saveUserPropertiesData } = useUserPropertiesData();

  return (
    <div className="flex h-full w-full flex-col overflow-y-auto bg-[#aadaff]">
      <div className="z-10 h-full w-full overflow-y-auto bg-[#aadaff]">
        <Map emptyResult={false} />
        {selectedProperty &&
          saveUserPropertiesData({
            ...userPropertiesData,
            viewed_properties: {
              ...userPropertiesData?.viewed_properties,
              [selectedProperty.parcel_id]: true,
            },
          })}
      </div>
    </div>
  );
};
