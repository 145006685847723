import { AdjustmentsIcon, XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { RentalCompFiltersForm } from "components/proformas/comparables/";

type Props = {
  isOpen: boolean;
  setShowModal: CallableFunction;
};

export const RentalFiltersModal = ({ isOpen, setShowModal }: Props) => {
  return (
    <ModalWrapper isOpen={isOpen} closeModal={() => setShowModal(false)}>
      <div className="inline-block h-full max-h-fit w-full max-w-xl transform overflow-hidden rounded-md px-0 text-left align-middle shadow-xl transition-all">
        <div className="flex h-full w-full justify-center">
          <div className="w-full max-w-5xl overflow-y-scroll rounded-md bg-white pb-2 shadow-lg">
            <div className="flex h-full flex-col">
              <header className="fixed z-10 flex w-full flex-row items-center bg-white">
                <div className="text-md flex basis-4/5 items-center pl-8 font-medium text-st-normal">
                  <AdjustmentsIcon className="mr-2 mt-1 w-6" aria-hidden="true" /> Advanced Search Filters
                </div>

                <div className="justify-right basis-1/5 p-4 pr-4 text-right">
                  <button
                    type="button"
                    className="rounded-md pb-1 pl-1 text-sm font-medium text-st-lightest hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                    onClick={() => setShowModal(false)}
                  >
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </header>
              <main className="flex flex-grow flex-col bg-gray-50 p-8 pb-1 pt-20 text-sm">
                <div className="flex items-center text-xs font-medium text-st-lightest">
                  Comparables search includes all MLS-listed standard sales
                </div>

                <RentalCompFiltersForm allFilters={true} setShowModal={setShowModal} />
              </main>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
