import NumberFormat from "react-number-format";
import { useFinancials } from "state/proformas";

export const RentalCard = () => {
  const { financials, updateCalcSheet } = useFinancials();

  if (!financials) return null;

  const expenses =
    financials.rental.monthlyCosts - financials.rental.monthlyLoanPmt - financials.rental.budget;

  const operatingIncome = financials.rental.monthlyRent * (1 - financials.rental.vacancyRate);

  const operatingCosts =
    financials.strategy_rental === "financed_hold"
      ? financials.costgroupTotals.operatingHold
      : financials.costgroupTotals.operatingBrrr;

  const onUpdateMonthlyRent = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ""));
    const fieldsToUpdate = financials.calcsheet.is_brrr
      ? { monthly_rent_brrr: val }
      : { monthly_rent_hold: val };
    updateCalcSheet(fieldsToUpdate);
  };

  return (
    <div className="rounded-lg border border-gray-300 bg-gray-50 p-6 shadow-lg">
      {/* card content */}
      <div className="flex flex-row justify-items-stretch gap-2 pb-1 text-sm leading-9">
        <div className="basis-3/5 whitespace-nowrap font-bold">Monthly Rent</div>
        <div className="w-full basis-2/5">
          <NumberFormat
            className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
            value={financials.rental.monthlyRent}
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
            onBlur={onUpdateMonthlyRent}
            onKeyUp={(e: React.KeyboardEvent) => {
              if (e.key === "Enter") {
                onUpdateMonthlyRent(e);
              }
            }}
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 pt-1 text-sm leading-9">
        <div className="basis-3/5 whitespace-nowrap">Expenses</div>
        <div className="w-full basis-2/5 border-t border-gray-600 pr-1 text-right">
          <NumberFormat
            value={expenses}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-4/6 whitespace-nowrap">Budget</div>
        <div className="w-full pr-1 text-right">
          <NumberFormat
            value={financials.rental.budget}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 pb-4 text-sm leading-9">
        <div className="w-4/6 whitespace-nowrap">Mortgage</div>
        <div className="w-full basis-2/5 border-b border-gray-600 pb-1 pr-1 text-right">
          <NumberFormat
            value={financials.rental.monthlyLoanPmt}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 pb-2 text-sm leading-3">
        <div className="basis-3/5 whitespace-nowrap font-bold">Cash Flow:</div>
        <div className="w-full basis-2/5 pr-1 text-right font-bold">
          <NumberFormat
            value={financials.rental.monthlyProfit}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="mb-3 border-b pb-4"></div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-4/6 whitespace-nowrap">Operating Income</div>
        <div className="w-full pr-1 text-right">
          <NumberFormat
            value={12 * operatingIncome}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-4/6 whitespace-nowrap">Operating Costs</div>
        <div className="w-full basis-2/5 border-b border-gray-600 pb-2 pr-1 text-right">
          <NumberFormat
            value={operatingCosts * 12}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 pt-2 text-sm leading-9">
        <div className="w-4/6 whitespace-nowrap font-bold">Net Operating Income</div>
        <div className="w-full pr-1 text-right font-bold">
          <NumberFormat
            value={(operatingIncome - operatingCosts) * 12}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 pb-2 text-sm leading-6">
        <div className="w-4/6 whitespace-nowrap">Purchase Price:</div>
        <div className="w-full pr-1 text-right">
          <NumberFormat
            value={financials.calcsheet.purchase_price}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm">
        <div className="w-4/6 whitespace-nowrap">
          <div className="font-bold">Cap rate:</div>
        </div>
        <div className="items-centerpr-1 w-full text-right font-bold">
          <NumberFormat
            value={financials.rental.capRate}
            displayType="text"
            thousandSeparator={true}
            decimalScale={2}
            suffix="%"
          />
        </div>
      </div>
    </div>
  );
};
