import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

type Props = {
  photos: any[];
  isOpen: boolean;
  closeModal: any;
  showIdx: number | null;
};

export const PhotosModalMobile = ({ photos, isOpen, closeModal, showIdx }: Props) => {
  return (
    <ModalWrapper className="z-[2147483001]" isOpen={isOpen} closeModal={closeModal} overlayOpacity={90}>
      <div className="inline-block h-screen max-h-fit w-full transform overflow-hidden bg-white px-0 text-left align-middle shadow-xl transition-all">
        <div className="text-st-darkest mb-3 mt-1 h-6 align-middle text-sm font-medium leading-6">
          <button className="float-right outline-none" onClick={closeModal}>
            <XIcon className="mr-1 w-7" aria-hidden="true" />
          </button>
        </div>

        <div className="flex h-screen">
          <Swiper
            direction="vertical"
            slidesPerView={3}
            initialSlide={showIdx || 0}
            cssMode={true}
            keyboard={true}
            modules={[Keyboard]}
            className="w-full bg-white"
          >
            {photos.map((photo, index) => (
              <SwiperSlide key={photo._id} className="mb-1">
                <div
                  className="h-full bg-cover bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${photo.photo_url})` }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </ModalWrapper>
  );
};
