import { jsonParse } from "helpers";

export type UserPropertiesData = {
  hidden_properties: { [key: number]: boolean }; // parcel ids
  viewed_properties: { [key: number]: boolean }; // parcel ids
  stared_properties: { [key: number]: boolean }; // parcel ids
};

type UseUserPropertiesData = () => {
  userPropertiesData: UserPropertiesData;
  saveUserPropertiesData: (data: UserPropertiesData) => void;
  resetUserPropertiesData: () => void;
};

export const useUserPropertiesData: UseUserPropertiesData = () => {
  const userPropertiesData = jsonParse(localStorage.getItem("user_properties_data") || "");

  const saveUserPropertiesData = (data: Partial<UserPropertiesData>) => {
    localStorage.setItem("user_properties_data", JSON.stringify({ ...userPropertiesData, ...data }));
  };

  const resetUserPropertiesData = () => {
    localStorage.removeItem("user_properties_data");
  };

  return { userPropertiesData, saveUserPropertiesData, resetUserPropertiesData };
};
