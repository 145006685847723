import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { AgentPropertyType } from "state/useAgent";

type Props = {
  properties: AgentPropertyType[];
};

export const AgentProperties = ({ properties }: Props) => {
  const [page, setPage] = useState(0);
  const PAGE_SIZE = 6;
  const totalPages = Math.ceil(properties.length / PAGE_SIZE);

  const sortedSales = properties.slice().sort((a, b) => {
    if (a.sale_date > b.sale_date) return -1;
    if (a.sale_date < b.sale_date) return 1;
    return 0;
  });

  useEffect(() => {
    setPage(0);
  }, [properties]);

  return (
    <div className="w-full">
      {properties.length === 0 && (
        <div className="flex w-full flex-col items-center text-center">
          <div className="text-st-lighter w-full max-w-md border p-4 text-center text-xs">
            There are no active listings at this moment to display.
          </div>
        </div>
      )}

      <ul className="xs:grid-cols-1 grid w-full gap-4 sm:p-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        {sortedSales.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((property) => (
          <li
            key={`${property.sale_id}-${property.side}`}
            className="flex w-full flex-col rounded-sm border border-gray-100 bg-gray-100 shadow-md hover:border hover:border-blue-500"
          >
            {property.photos && property.photos.length > 0 ? (
              <div className="relative h-52 w-full flex-shrink-0">
                {property.sale_date && (
                  <div className="absolute top-0 z-10 m-2 flex rounded-md bg-black px-2 py-1 text-white opacity-70">
                    <div className="text-[10px]">{property.sale_date}</div>
                  </div>
                )}

                <div className="absolute right-0 top-0 z-10 m-2 flex rounded-md bg-black px-2 py-1 text-white opacity-70">
                  <div className="text-[10px]">{property.listing_status}</div>
                </div>

                <div className="absolute bottom-0 w-full bg-gradient-to-t from-gray-900/80 to-transparent p-2">
                  <div className="text-st-dark flex w-full flex-row">
                    <div className="flex w-full basis-2/3 items-center text-sm font-bold text-white">
                      {property?.city && <>{property.city},</>}
                      {property?.county && <>&nbsp;{property.county},</>}
                      &nbsp;{property.state_abbreviation}
                    </div>
                    <div className="basis-1/3 items-center text-right text-sm font-bold text-white">
                      <NumberFormat
                        value={property.listing_price}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$ "
                        decimalScale={0}
                      />
                    </div>
                  </div>

                  <div className="text-st-soft overflow-hidden text-ellipsis whitespace-nowrap text-[8px] tracking-tighter">
                    Listing provided by {property.listing_source}
                    {property.listing_company && " and " + property.listing_company}
                  </div>
                </div>

                <div
                  className="bg-[background-size: 100% 100%;] flex h-full w-full items-end rounded-t-sm bg-cover bg-center bg-no-repeat"
                  style={{ backgroundImage: `url('${property.photos[0]}')` }}
                ></div>
              </div>
            ) : (
              <div className="relative h-52 w-full flex-shrink-0 bg-gradient-to-t from-gray-900/80 to-transparent">
                <div className="absolute bottom-0 w-full bg-gradient-to-t from-gray-900/80 to-transparent p-2">
                  <div className="text-st-dark flex w-full flex-row">
                    <div className="flex w-full basis-2/3 items-center text-sm font-bold text-white">
                      {property?.city && <>{property.city},</>}
                      {property?.county && <>&nbsp;{property.county},</>}
                      &nbsp;{property.state_abbreviation}
                    </div>
                    <div className="basis-1/3 items-center text-right text-sm font-bold text-white">
                      <NumberFormat
                        value={property.listing_price}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$ "
                        decimalScale={0}
                      />
                    </div>
                  </div>

                  <div className="text-st-soft overflow-hidden text-ellipsis whitespace-nowrap text-[8px] tracking-tighter">
                    Listing provided by {property.listing_source}
                    {property.listing_company && " and " + property.listing_company}
                  </div>
                </div>

                <div
                  className="bg-[background-size: 100% 100%;] flex h-full w-full items-end rounded-t-sm border border-gray-200 bg-black bg-cover bg-center bg-no-repeat opacity-10"
                  style={{
                    backgroundImage: `url('https://cdn.davinci.pellego.com/static/images/core/missing.png')`,
                  }}
                ></div>
              </div>
            )}
          </li>
        ))}
      </ul>

      {/* Pagination */}
      {properties.length > 0 && totalPages > 1 && (
        <div className="mx-4 flex items-center justify-center rounded py-1">
          <button onClick={() => setPage(Math.max(0, page - 1))}>
            <ChevronLeftIcon className="text-sl-lighter mr-2 inline h-6 w-6 rounded-xl hover:bg-gray-300" />
          </button>

          <span className="text-sl-dark px-8">
            {page + 1} of {totalPages}
          </span>

          <button onClick={() => setPage(Math.min(page + 1, PAGE_SIZE - 1))}>
            <ChevronRightIcon className="text-sl-lighter mr-2 inline h-6 w-6 rounded-xl hover:bg-gray-300" />
          </button>
        </div>
      )}
    </div>
  );
};
