import { isPellego } from "config";
import ReactGA from "react-ga4";
import { useUserSession } from "state";

export const BrowseAd = () => {
  const currentUser = useUserSession();

  return (
    <div>
      {currentUser.isAgent ? (
        <a
          href={`https://www.${isPellego ? "pellego" : "lotside"}.com/plans/agents`}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            ReactGA.event({
              category: "Browse",
              action: "Click Ad",
              label: "isAgent",
            })
          }
        >
          <div className="text-st-darkest flex h-full w-full flex-col items-center justify-center rounded-md border border-[#61a28d] bg-white py-4 text-center shadow-lg">
            <div className="flex flex-col items-center justify-center">
              <div className="px-4 pt-4 text-2xl font-bold">
                Hang your license with {isPellego ? "Pellego" : "Lotside"}
              </div>
              <div className="text-md px-12 py-4 pb-8 font-extralight">
                Leverage your time.
                <br />
                Keep your commission.
              </div>

              <div className="flex w-full flex-col items-center justify-center text-center">
                <div className="flex flex-col items-center justify-center rounded-sm bg-[#61a28d] px-4 py-2 text-center text-sm font-normal text-white shadow-lg transition duration-150 ease-in-out hover:bg-[#5bbc9c] hover:text-white sm:px-8">
                  Learn More
                </div>
              </div>
            </div>
          </div>
        </a>
      ) : (
        <a
          href={`https://www.${isPellego ? "pellego" : "lotside"}.com/buyers`}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            ReactGA.event({
              category: "Browse",
              action: "Click Ad",
              label: "NotAgent",
            })
          }
        >
          <div className="text-st-darkest flex h-full w-full flex-col items-center justify-center rounded-md border border-gray-200 bg-white py-4 text-center shadow-lg">
            <div className="flex flex-col items-center justify-center">
              <div className="px-8 pt-4 text-2xl font-bold">Work with an investor agent</div>
              <div className="text-md px-12 py-4 pb-8 font-extralight">
                Interview the top agents for your location and strategy.
              </div>

              <div className="flex w-full flex-col items-center justify-center text-center">
                <div className="flex flex-col items-center justify-center rounded-sm bg-[#61a28d] px-4 py-2 text-center text-sm font-normal text-white shadow-lg transition duration-150 ease-in-out hover:bg-[#5bbc9c] hover:text-white sm:px-8">
                  Learn More
                </div>
              </div>
            </div>
          </div>
        </a>
      )}
    </div>
  );
};
