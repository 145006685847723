export const FILTER_OPTIONS: { [key: string]: number[] } = {
  price: [
    0,
    100_000,
    200_000,
    300_000,
    400_000,
    500_000,
    600_000,
    700_000,
    800_000,
    900_000,
    1_000_000,
    1_250_000,
    1_500_000,
    1_750_000,
    2_000_000,
    2_250_000,
    2_500_000,
    2_750_000,
    3_000_000,
    3_250_000,
    3_500_000,
    3_750_000,
    4_000_000,
    4_250_000,
    4_500_000,
    4_750_000,
    5_000_000,
    6_000_000,
    7_000_000,
    8_000_000,
    9_000_000,
    10_000_000,
    Infinity,
  ],
  gross_gain: [0, 10, 20, 30, 40],
  gross_income: [-10, -5, 0, 5, 10],
  equity: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  square_feet_finished: [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, Infinity],
  unfinished_basement: [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, Infinity],
  bedrooms: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, Infinity],
  bathrooms: [0, 1, 1.5, 2, 2.5, 3, 3.5, 4, Infinity],
  lot_square_feet: [0, 5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, Infinity],
};
