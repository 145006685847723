import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router";

type Props = {
  label: string;
  locationId: string | number | undefined;
  onRemove: CallableFunction;
  compact?: boolean;
  allowRemove?: boolean;
};

export const Label = ({ label, locationId, onRemove, compact, allowRemove }: Props) => {
  const location = useLocation();
  const isBrowseMobilePage = isMobile && location.pathname.includes("/browse");

  return (
    <div
      className={`ml-1 flex ${isBrowseMobilePage ? "h-6 px-2 text-xs" : "h-8 px-3 text-sm"} cursor-pointer items-center whitespace-nowrap rounded-full bg-black text-white ${
        compact ? "max-w-[120px]" : "mb-2 sm:max-w-[250px]"
      }`}
    >
      <span className="truncate">{label}</span>
      {locationId && allowRemove && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            onRemove(locationId);
          }}
          className="flex items-center"
        >
          <Icon
            className="ml-1 inline h-4 w-4 rounded-lg text-[#dbf0e9] hover:bg-gray-50 hover:text-st-darkest"
            path={mdiClose}
          />
        </span>
      )}
    </div>
  );
};
