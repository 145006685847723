import { CustomizeArvModal, ShowCustomizeArvModalAtom } from "components/proformas/financials/rentaltab/";
import NumberFormat from "react-number-format";
import { useSetRecoilState } from "recoil";
import { useFinancials } from "state/proformas";

export const LongTermAssumption = () => {
  const { financials, updateCalcSheet } = useFinancials();
  const setShowCustomizeArvModal = useSetRecoilState(ShowCustomizeArvModalAtom);

  if (!financials) return null;

  const propValue =
    financials?.strategy_rental === "financed_brrr"
      ? financials.calcsheet.resale_value
      : financials.calcsheet.purchase_price + financials.calcsheet.rehab_costs_hold;

  const propAppr = financials.calcsheet.property_appreciation * 100;

  const onUpdatePropAppr = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ""));
    updateCalcSheet({ property_appreciation: val / 100 });
  };

  const rentAppr = financials.calcsheet.rent_appreciation * 100;

  const onUpdateRentAppr = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ""));
    updateCalcSheet({ rent_appreciation: val / 100 });
  };

  const onUpdateRentPeriod = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ""));
    updateCalcSheet({ rent_period: val });
  };

  const onUpdateHoldingTime = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ""));
    updateCalcSheet({ holding_time: val });
  };

  return (
    <div className="flex w-full flex-col gap-2 pt-4 sm:flex-row sm:gap-8">
      <div className="w-full sm:pr-4">
        <div className="mb-2 flex w-full flex-row justify-items-stretch gap-2 text-sm">
          <div className="w-full basis-3/5 whitespace-nowrap">
            <div className="">Initial Property Value</div>

            <div className="text-xs text-st-lightest">
              {financials?.strategy_rental === "financed_brrr" && <>after repair value</>}
              {financials?.strategy_rental === "financed_hold" && <>purchase price + initial rehab</>}
            </div>
          </div>
          <div
            className="mb-1 w-full basis-2/5 cursor-pointer items-center border border-[#E4C442] bg-stone-200 p-1 text-right text-sm text-sl-normal outline-0"
            onClick={() => setShowCustomizeArvModal(true)}
          >
            <NumberFormat
              className="cursor-pointer text-sl-normal"
              value={propValue}
              thousandSeparator={true}
              decimalScale={0}
              prefix="$ "
              displayType="text"
            />
          </div>
        </div>

        <div className="mb-2 flex flex-row justify-items-stretch gap-2 text-sm">
          <div className="basis-3/5 whitespace-nowrap">
            <div>Property Appreciation</div>
            <div className="text-xs text-st-lightest">annual rate</div>
          </div>
          <div className="w-full basis-2/5 leading-9">
            <NumberFormat
              className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
              value={propAppr}
              thousandSeparator={true}
              decimalScale={1}
              suffix=" %"
              onBlur={onUpdatePropAppr}
              onKeyUp={(e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  onUpdatePropAppr(e);
                }
              }}
            />
          </div>
        </div>

        <div className="mb-2 flex flex-row justify-items-stretch gap-2 text-sm">
          <div className="basis-3/5 whitespace-nowrap">
            <div>Rent/Cost Increase</div>
            <div className="text-xs text-st-lightest">effective annual rate</div>
          </div>
          <div className="w-full basis-2/5 leading-9">
            <NumberFormat
              className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
              value={rentAppr}
              thousandSeparator={true}
              decimalScale={1}
              suffix=" %"
              onBlur={onUpdateRentAppr}
              onKeyUp={(e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  onUpdateRentAppr(e);
                }
              }}
            />
          </div>
        </div>

        <div className="mb-2 flex flex-row justify-items-stretch gap-2 text-sm">
          <div className="basis-3/5 whitespace-nowrap">
            <div>Rent/Cost Period</div>
            <div className="text-xs text-st-lightest">increase every x years</div>
          </div>
          <div className="flex w-full basis-2/5">
            <div className="w-full text-center">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                value={financials.calcsheet.rent_period}
                thousandSeparator={true}
                decimalScale={1}
                onBlur={onUpdateRentPeriod}
                onKeyUp={(e: React.KeyboardEvent) => {
                  if (e.key === "Enter") {
                    onUpdateRentPeriod(e);
                  }
                }}
              />
            </div>
            <div className="w-1/2 pl-2 text-left">years</div>
          </div>
        </div>

        <div className="flex flex-row justify-items-stretch gap-2 text-sm">
          <div className="w-3/5 whitespace-nowrap">
            <div>Holding Time</div>
            <div className="text-xs text-st-lightest">sell after x years</div>
          </div>
          <div className="flex w-full basis-2/5">
            <div className="w-full text-center">
              <NumberFormat
                className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-st-lightest focus:border-focus-border focus:ring-0"
                value={financials.calcsheet.holding_time}
                thousandSeparator={true}
                decimalScale={0}
                onBlur={onUpdateHoldingTime}
                onKeyUp={(e: React.KeyboardEvent) => {
                  if (e.key === "Enter") {
                    onUpdateHoldingTime(e);
                  }
                }}
              />
            </div>
            <div className="w-1/2 pl-2 text-left">years</div>
          </div>
        </div>
      </div>

      <CustomizeArvModal />
    </div>
  );
};
