import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { isPellego } from "config";
import { cn } from "helpers";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";

const ResearchMenuItems = [
  {
    name: `About ${isPellego ? "Pellego" : "Lotside"}`,
    href: `https://www.${isPellego ? "pellego" : "lotside"}.com/about`,
  },
  { name: "How it Works", href: `https://www.${isPellego ? "pellego" : "lotside"}.com/how-it-works` },
];

export const NavBarAbout = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  return (
    <Menu
      as="div"
      className={cn(!isHome && "hidden lg:block", "relative inline-block items-center text-left")}
    >
      <Menu.Button className="inline-flex w-full basis-1/3 items-center whitespace-nowrap px-2 py-1.5 pr-3 text-left font-medium uppercase focus:outline-none focus:ring-0 focus:ring-offset-0 sm:space-x-2 sm:px-4">
        Company
        <ChevronDownIcon className="-mr-1 ml-2 h-6 w-5" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {ResearchMenuItems.map(({ name, href }, index) => (
              <Menu.Item key={`htmi-${index}`}>
                {({ active }) => (
                  <a
                    href={href}
                    rel="noreferrer"
                    className={`${
                      active ? "text-st-darker bg-gray-100" : "text-st-normal"
                    } block px-4 py-2 text-sm`}
                  >
                    {name}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
