type Props = {
  title?: string;
  data: any[];
};

export const DetailsList = ({ title, data }: Props) => {
  return (
    <div className="flex w-full flex-col justify-start">
      {title && (
        <div className="text-md text-st-darker border-0 border-gray-400 font-bold leading-7">{title}</div>
      )}
      {data.map(({ name, value, url, customValue }, index) => (
        <div
          className="flex w-full items-center border-b border-gray-200 px-1.5 py-1 text-sm last:border-b-0"
          key={`detail-${title}-${index}`}
        >
          <div className="basis-2/4 font-normal">{name}</div>
          <div className="basis-2/4 text-right font-light">
            {url ? (
              <a href={url} className="text-sl-normal" target="_new">
                {typeof value === "string" && value.toUpperCase().slice(-14) === "PUBLIC SCHOOLS"
                  ? value.slice(0, value.length - 15)
                  : value}
              </a>
            ) : customValue ? (
              <>
                {typeof value === "string" && value.toUpperCase().slice(-14) === "PUBLIC SCHOOLS"
                  ? value.slice(0, value.length - 15)
                  : value}{" "}
                → {customValue}
              </>
            ) : (
              <>
                {typeof value === "string" && value.toUpperCase().slice(-14) === "PUBLIC SCHOOLS"
                  ? value.slice(0, value.length - 15)
                  : value}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
