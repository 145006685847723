import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";

type Props = {
  label: string;
  onRemove: CallableFunction;
};

export const Label = ({ label, onRemove }: Props) => {
  const onClickOnRemove = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <div
      className={`mt-2" text-st-light mb-1.5 ml-1 flex h-8 cursor-pointer items-center whitespace-nowrap rounded-md border border-gray-300 bg-gray-100 px-3 text-sm`}
    >
      <span className="truncate">{label}</span>
      <span onClick={onClickOnRemove} className="flex items-center">
        <Icon className="text-st-lightest ml-1 inline h-4 w-4 rounded-lg hover:bg-gray-300" path={mdiClose} />
      </span>
    </div>
  );
};
