import { marketStructureTypes } from "components/markets";
import { cn } from "helpers";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";
import { MarketSearchPerformanceType } from "state/market";

export const newSearchParams = (browsePagePath: string) => {
  const currentUrl = new URL(window.location.href);
  const currentParams = new URLSearchParams(currentUrl.searchParams);
  const newParams = new URLSearchParams(browsePagePath.split("?")[1]);

  ["city", "neighborhood"].forEach((locType) => {
    const mergedLocations = [...currentParams.getAll(locType), ...newParams.getAll(locType)];
    currentUrl.searchParams.delete(locType);
    mergedLocations.forEach((value) => currentUrl.searchParams.append(locType, value));
  });

  return currentUrl.toString();
};

type Props = {
  data: MarketSearchPerformanceType;
  order: "top" | "bottom";
};

export const SearchPerformingMarkets = ({ data, order }: Props) => {
  const maxNumberOfPerformers = 10;
  let topPerformers = data.top_performers;
  let bottomPerformers = data.bottom_performers;

  const repeatedData = topPerformers.filter((topPerformer) =>
    bottomPerformers.some(
      (bottomPerformer) =>
        bottomPerformer.market + bottomPerformer.pct_growth.toString ===
        topPerformer.market + topPerformer.pct_growth.toString,
    ),
  );

  if (repeatedData.length > 0) {
    const numberOfRepeated = repeatedData.length;
    topPerformers = topPerformers.slice(0, topPerformers.length - Math.floor(numberOfRepeated / 2));
    bottomPerformers = bottomPerformers.slice(0, bottomPerformers.length - Math.ceil(numberOfRepeated / 2));
  }

  const performers =
    order === "top"
      ? topPerformers.slice(0, maxNumberOfPerformers)
      : bottomPerformers.slice(0, maxNumberOfPerformers);

  return (
    <div
      className={cn(
        isMobile ? "w-full" : "w-1/2 min-w-80 shrink-0",
        "gap-4 px-0 py-4",
        order === "top" ? "xl:pr-4" : "xl:pl-4",
      )}
    >
      <div>
        <div className="text-md text-left font-bold">
          {order === "top" ? "Best" : "Worst"} Performing Markets
        </div>
        <div className="text-st-normal pb-3 pt-0 text-sm font-light">In the last 12 months</div>
        <table className="w-full table-auto text-left text-sm">
          <thead>
            <tr>
              <th className="py-1 text-left">Market</th>
              <th className="py-1 text-right">Growth</th>
            </tr>
          </thead>
          <tbody>
            {performers.map((performance, idx) => (
              <tr key={idx} className="border-b">
                <td className="py-2 align-top">
                  <a
                    href={performance.browse_page_path}
                    className="hover:text-sl-lighter py-2 pb-0.5 align-top font-light"
                  >
                    {performance.market}&nbsp;
                    {marketStructureTypes[
                      performance.structure_type.toLowerCase() as keyof typeof marketStructureTypes
                    ] !== "All" && (
                      <>
                        {
                          marketStructureTypes[
                            performance.structure_type.toLowerCase() as keyof typeof marketStructureTypes
                          ]
                        }
                      </>
                    )}
                  </a>
                </td>
                <td className="py-2 text-right align-top font-light">
                  <NumberFormat
                    value={performance.pct_growth}
                    displayType="text"
                    suffix="%"
                    decimalScale={1}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
